<template>
  <v-data-table
    :loading="cargando"
    :show-select="cambio"
    :loading-text="textoCargando"
    v-model="selectedFiebre"
    id="tableInquitudes"
    item-key="id"
    :fixed-header="fixed"
    :height="height"
    :headers="headers"
    :items="itemsIndex"
    :items-per-page="10"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{nameTitle}}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-row>
          <v-col>
            <v-text-field
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              hide-details
              dense
            ></v-text-field>
          </v-col>
          <!--  <v-col>
            <v-btn
              :loading="isSelecting"
              color="warning"
              @click="onButtonClick(1)"
              light
            >Cargar info Comité</v-btn>
          </v-col>-->
        </v-row>
      </v-toolbar>
      <v-toolbar>
        <v-row>
          <v-col v-if="oculto && cambio">
            <v-btn
              color="warning"
              @click="updateCuarenteUser()"
              v-if="oculto"
              light
            >Cambiar estado de Cuarentena</v-btn>
          </v-col>
          <v-col v-if="!oculto">
            <v-btn color="warning" @click="change(1)" v-if="!oculto" light>Ver ingresos con Fiebre</v-btn>
          </v-col>
          <v-col v-if="!oculto">
            <v-btn color="warning" @click="change(2)" v-if="!oculto" light>Ver ingresos sin Fiebre</v-btn>
          </v-col>

          <v-col v-if="oculto">
            <v-btn color="warning" @click="changeData()" v-if="oculto" light>Ver ingresos con Fiebre</v-btn>
          </v-col>

          <v-col v-if="oculto">
            <v-btn color="warning" @click="sendData()" light v-if="oculto">Todos los ingresos</v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>

    <template v-slot:item.Temperatura="{ item }">
      <v-chip :color="getColor(item.Temperatura)" dark>{{ item.Temperatura }}</v-chip>
    </template>

    <template v-slot:item.Cuarentena="{ item }">
      <p>{{item.Cuarentena}}</p>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    textoCargando: String,
    cargando: Boolean,
    headers: Array,
    items: Array,
    nameTitle: String,
    oculto: Boolean,
    cambio: Boolean
  },
  data() {
    return {
      selectedFiebre: [],
      isSelecting: false,
      isSelecting2: false,
      option: "",
      zonas: [],
      panel: [1],
      tipo: "",
      selected: [],
      search: "",
      height: 400,
      fixed: true,
      temperatura: 0,
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      }
    };
  },

  /* watch: {
    selectedFiebre() {
      console.log(this.selectedFiebre);
    }
  }, */

  computed: {
    itemsIndex() {
      return this.items.map((item, index) => ({
        id: index,
        ...item
      }));
    }
  },

  created() {
    this.obtenerTemperaturaActual();
  },

  methods: {
    getColor(calories) {
      if (calories >= this.temperatura) return "red";
      else return "green";
    },

    obtenerTemperaturaActual() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL2 + "/temperatura/obtenerTemperatura",
          this.auth
        )
        .then(response => {
          this.temperatura = response.data.umbral;
          this.dialog = true;
        })
        .catch(error => {
          alert(error);
          this.cargando=false;
        });
    },

    updateCuarenteUser() {
      if (this.selectedFiebre.length != 0) {
        this.axios
          .post(
            process.env.VUE_APP_BASE_URL + "/usuario/actualizarCuarentenaTotem",
            this.selectedFiebre,
            this.auth
          )
          .then(response => {
            //console.log("Se Actualizó");
            alert("Cambio realizado con exito");
            this.changeData();
          })
          .catch(error => {
            alert("No se pudo realizar el cambio");
          });
      } else {
        alert("No hay funcionarios seleccionados");
      }
    },

    sendData() {
      this.$emit("send-data", "OK");
    },

    changeData() {
      this.$emit("change-data", "OK");
    },

    change(attr) {
      this.$emit("change", attr);
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    }
  }
};
</script>