<template>
  <v-data-table
    :loading="cargando"
    id="tablePersonalExtra"
    item-key="rut"
    :fixed-header="fixed"
    :height="height"
    :headers="headers"
    :items="items"
    :items-per-page="10"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{ nameTitle }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-row>
          <v-col>
            <v-text-field
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              hide-details
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-dialog v-model="dialog3" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2"
              >Generar Salvoconducto</v-card-title
            >

            <v-card-text class="text-justify"
              >¿Está seguro que desea generar un Salvoconducto para
              {{ editedItem.nombres }} {{ editedItem.apellidoP }}
              {{ editedItem.apellidoM }} con RUN
              {{ editedItem.rut }}?</v-card-text
            >

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn color="red" text @click="dialog3 = false">Cerrar</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="green" text @click="generarSalvoconducto()"
                >Aceptar y Enviar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="deleteDialog" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2"
              >Eliminar Persona</v-card-title
            >

            <v-card-text class="text-justify"
              >¿Está seguro que desea eliminar a la persona
              {{ editedItem.nombres }} {{ editedItem.apellidoP }}
              {{ editedItem.apellidoM }} con RUN
              {{ editedItem.rut }}?</v-card-text
            >

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn color="red" text @click="deleteDialog = false"
                >Cerrar</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="green" text @click="deleteUsuario(editedItem.id)"
                >Aceptar y Enviar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>

      <v-toolbar>
        <v-col>
          <v-btn
            :loading="isSelecting3"
            color="warning"
            @click="onButtonClick(4)"
            light
            >Cargar Personal</v-btn
          >
        </v-col>
        <v-row>
          <input
            ref="uploader3"
            class="d-none"
            type="file"
            @change="submitFile"
          />
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.correo="{ item }">{{ sinInfo(item) }}</template>
    <template v-slot:item.salvoActivo="{ item }">{{
      estadoSalvo(item)
    }}</template>
    <template v-slot:item.acciones="{ item }">
      <v-btn
        color="#071D49"
        :disabled="item.salvoActivo"
        @click="showDetailItem3(item)"
        text
        >Generar</v-btn
      >
      <v-icon
        color="#071D49"
        :disabled="!item.salvoActivo"
        class="mr-2"
        title="Descargar salvoconducto activo"
        @click="descargarSalvo(item)"
        >mdi-download</v-icon
      >
      <v-icon
        color="#071D49"
        class="mr-2"
        title="Eliminar Usuario"
        @click="showDetailItemDelete(item)"
        >mdi-delete</v-icon
      >
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    cargando: Boolean,
    headers: Array,
    items: Array,
    nameTitle: String,
  },

  data() {
    return {
      dialog3: false,
      deleteDialog: false,
      isSelecting3: false,
      option: "",
      search: "",
      height: 400,
      fixed: true,
      editedItem: {},
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
    };
  },

  computed: {},

  methods: {
    sendData() {
      this.$emit("send-data", "OK");
    },
    estadoSalvo(item) {
      if (item.salvoActivo == null || item.salvoActivo == false) {
        return "No";
      } else {
        return "Sí";
      }
    },

    sinInfo(item) {
      if (item.correo == null || item.correo == " ") {
        return "Sin registro";
      } else {
        return item.correo;
      }
    },
    forceFileDownload(response, nameFile) {
      //console.log("Aqui va el header");
      //console.log(response.headers);
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nameFile); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    onButtonClick(option) {
      if (option == 4) {
        this.option = 4;
        this.isSelecting3 = true;
      }
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting3 = true;
        },
        { once: true }
      );
      if (option == 4) {
        this.$refs.uploader3.click();
      }
    },
    submitFile(e) {
      let formData = new FormData();
      var fileToUpload = e.target.files[0];
      var fileName = e.target.files[0].name;
      formData.append("file", fileToUpload);
      var endpoint = "";
      var name = "";
      var fecha = new Date();
      if (this.option == 4) {
        //TODO: CAMBIAR ENDPOINT
        endpoint = "/personalExtra/crearDesdeExcel?columnas=7&indiceHoja=0";
        name =
          "Usuarios no creados - Personal Extra- " +
          this.formatDate(fecha.toISOString().substr(0, 10)) +
          ".xlsx";
      }
      this.axios
        .post(process.env.VUE_APP_BASE_URL + endpoint, formData, {
          responseType: "arraybuffer",
          headers: { Authorization: "" + this.$session.get("jwt") },
        })
        .then((response) => {
          alert("Archivo: " + fileName + " fue cargado exitosamente.");
          if (this.option == 4) {
            this.isSelecting3 = false;
          }
          this.forceFileDownload(response, name);
          this.sendData();
        })
        .catch((error) => {
          alert(error.response);
          if (this.option == 4) {
            this.isSelecting3 = false;
          }
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    deleteUsuario(id) {
      //TODO: VER SI SE MANTIENE SI NO SACARLO
      this.axios
        .delete(
          process.env.VUE_APP_BASE_URL + "/personalExtra/delete?id=" + id,
          this.auth
        )
        .then((response) => {
          alert(response.data);
          this.deleteDialog = false;
          this.sendData();
        })
        .catch((error) => {
          alert(error);
        });
    },

    generarSalvoconducto() {
      //TODO: VER SI SE MANTIENE SI NO SACARLO
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL + "/salvoconductoManual/create",
          this.editedItem,
          this.auth
        )
        .then((response) => {
          var fecha = new Date();
          var a = document.createElement("a"); //Create <a>
          a.href = "data:application/pdf;base64," + response.data; //Image Base64 Goes here
          a.download =
            this.editedItem.rut +
            "_" +
            "salvoconducto_manual_" +
            this.formatDate(fecha.toISOString().substr(0, 10)) +
            ".pdf"; //File name Here
          a.click(); //Downloaded file
          this.dialog3 = false;
          this.sendData();
        })
        .catch((error) => {
          alert(error);
        });
    },

    descargarSalvo(item) {
      //TODO: VER SI SE MANTIENE SI NO SACARLO
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/salvoconductoManual/obtenerSalvoActivo?userId=" +
            item.id,
          this.auth
        )
        .then((response) => {
          var fecha = new Date();
          var a = document.createElement("a"); //Create <a>
          a.href = "data:application/pdf;base64," + response.data; //Image Base64 Goes here
          a.download =
            item.rut +
            "_" +
            "salvoconducto_manual_" +
            this.formatDate(fecha.toISOString().substr(0, 10)) +
            ".pdf"; //File name Here
          a.click(); //Downloaded file
        })
        .catch((error) => {
          alert(error);
        });
    },

    showDetailItem3(item) {
      //TODO: Cambiar busqueda por algo unico
      this.editedItem = item;
      this.dialog3 = true;
    },
    showDetailItemDelete(item) {
      //TODO: Cambiar busqueda por algo unico
      this.editedItem = item;
      this.deleteDialog = true;
    },
  },
};
</script>