var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('div',[_vm._v(" Mis Reservas ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 buttons",attrs:{"color":"primary","dark":"","x-large":""},on:{"click":function($event){return _vm.openReserveDialog(true)}}},[_vm._v(" Reservar Sala - Individual ")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reservas,"elevation":"0"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"actionsIcons"},[(item.reserveType === 'GRUPAL' && item.reserveStatus !== 'CANCELADO')?_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.getGrupo(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-group ")])]}}],null,true),model:{value:(_vm.gruposReservas[index]),callback:function ($$v) {_vm.$set(_vm.gruposReservas, index, $$v)},expression:"gruposReservas[index]"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_vm._v(" "+_vm._s(_vm.currentGroup)+" ")])],1)],1)],1)],1):_vm._e(),(item.reserveStatus === 'COMPLETADO')?_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"center":""},on:{"click":function($event){return _vm.openHealthSituationDialog(true, item)}}},[_vm._v(" mdi-car-brake-alert ")])],1):_vm._e(),(item.reserveStatus === 'CREADO')?_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"center":""},on:{"click":function($event){return _vm.handleDeleteReserva(item)}}},[_vm._v(" mdi-delete ")])],1):_vm._e(),(item.reserveStatus === 'CANCELADO')?_c('div',[_vm._v(" - ")]):_vm._e()])]}},{key:"item.acceso",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-map-marker ")])]}}],null,true),model:{value:(_vm.locationLabel[index]),callback:function ($$v) {_vm.$set(_vm.locationLabel, index, $$v)},expression:"locationLabel[index]"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_vm._v(" "+_vm._s(item.access)+" ")])],1)],1)],1)]}},{key:"item.declarationStatus",fn:function(ref){
var item = ref.item;
return [(item.healthSurvey === 1 && item.reserveStatus === 'CREADO')?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"center":""},on:{"click":function($event){return _vm.handleDeclaracionSalud(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-book-plus-outline ")])]}}],null,true)},[_c('span',[_vm._v("La declaración de salud está disponible, tiene hasta las 18:00 del día de hoy para completarla")])])],1):_vm._e(),(item.healthSurvey === 0 && item.reserveStatus === 'CREADO')?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"center":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-clock-outline ")])]}}],null,true)},[_c('span',[_vm._v("La declaración de salud se realiza entre las 12:00 y las 17:59 del día anterior a la reserva")])])],1):_vm._e(),(item.reserveStatus === 'VALIDADO' || item.reserveStatus === 'COMPLETADO')?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"center":"","color":"green"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-bold ")])]}}],null,true)},[_c('span',[_vm._v("Su declaración de salud fue completada")])])],1):_vm._e(),(item.reserveStatus === 'CANCELADO')?_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"center":"","color":"red"}},[_vm._v(" mdi-close-circle ")])],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }