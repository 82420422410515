<template>
  <v-container>
    <h1>Reserva de espacios de estudio</h1>
    <Reserve/>
  </v-container>
</template>

<script>
import Reserve from '../components/Reserve/Reserve'
export default {
  name: "ReserveView",
  components: {
    'Reserve':Reserve
  },
  data(){
    return {

    }
  }

}
</script>

<style scoped>

</style>
