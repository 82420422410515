<template>
  <LoginReserva
  />
</template>

<script>
import img from "../assets/USACH_2.png";
import img1 from "../assets/LOGO USACH.png";
import img2 from "../assets/CampusSeguro Icon-2.png";
import logo_usach2 from "../assets/logo_usach2.jpg";
import LoginReserva from '../components/Login/LoginReserva';

import jwtDecode from "jwt-decode";

export default {
  name: 'LoginReservaView',
  components: {
    LoginReserva,
  },
  data() {
    return {

    };
  },
};
</script>
<style scoped>
.text-center {
  margin-left: 3px !important;
}

.bannerLogo {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.topCard {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 3%;
  width: auto;
  max-width: 40%;
  height: 20%;
  margin-top: 7%;
  margin-bottom: 5%;
}

@media (max-width: 768px) {
  .topCard {
    padding: 0;
    margin-bottom: 15%;
  }
}

@media only screen and (max-width: 600px) {
  .topCard {
    margin-bottom: 15%;
    margin-top: 20%;
    font-size: 10px;
    max-width: 100%;
  }
}

.bodyLogin {
  flex-direction: column;
  justify-content: space-evenly;
}

.parallax {
  position: fixed;
  z-index: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  filter: blur(0.3rem);
}
</style>
