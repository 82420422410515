<template>
    <v-dialog
      v-model="groupDialog"
      persistent
      :v-height="height"
      @click:outside="closeDialog"
      @keydown.esc="closeDialog"
    >
    <v-card>
      <v-card-title
        class="text-h5"
      >
        Disponibilidad de Reserva grupal para el día hábil siguiente
      </v-card-title>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  label="Seleccionar fecha de "
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="menu = false"
                @update:picker-date="pickerUpdate($event)"
                :allowed-dates="allowedDates"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
      <v-card-text>
        Seleccione por Sector o Edificio
      </v-card-text>

      <v-container fluid>
        <v-row
          align="center"
        >
          <v-col
            class="d-flex"
            cols="12"
            sm="6"
          >
            <v-select
              v-model="zonaSelected"
              :items="zonas"
              label="Seleccione sector a buscar"
              name="zonaSelected"
              return-object
              item-text="descripcion"
              dense
              outlined
            ></v-select>
          </v-col>

          <v-col
            class="d-flex"
            cols="12"
            sm="6"
          >
            <v-select
              v-model="edificioSelected"
              :items="edificios"
              label="Seleccione edificio a buscar"
              item-text="nombre"
              item-value="id"
              return-object
              dense
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-container>


      <v-card-menu>
        <v-col cols="12" sm="12" md="12">
          <v-data-table
            :headers="headers"
            :items="itemsFiltered"
            class="elevation-0"
            :items-per-page="-1"
            :search="search"
          >
            <!-- <template v-slot:item.tipo="{ item }">
              {{item.tipo + ' ('+ item.tamanoGrupo+')'}}
            </template>
            <template v-slot:item.tipo="{ item }">
              {{item.tipo + ' ('+ item.tamanoGrupo+')'}}
            </template> -->
            <template v-slot:item.acceso="{ item, index }">
              <v-menu
                v-model="locationLabel[index]"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-map-marker
                  </v-icon>
                </template>
                <v-card>
                  <v-list>
                    <v-list-item>
                      {{item.acceso}}
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-map-marker
                  </v-icon>
                </template>
                <span>{{item.acceso}}</span>
              </v-tooltip> -->
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                color="primary"
                elevation="2"
                @click="handleReservarSala(item)"
              >
                Reservar
              </v-btn>
              <!-- <v-icon center class="mr-2"
              >
                mdi-calendar-plus
              </v-icon> -->
            </template>
          </v-data-table>
          <div class="text--secondary mr-5">
            <card>
              <v-card-text>
                Horario de uso de las salas: De lunes a viernes de 7:30 a 19:30 hrs.
              </v-card-text>
            </card>
          </div>
        </v-col>
      </v-card-menu>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" outlined @click="closeDialog">Cerrar</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import alertModal from '../../utils/alertModal';
import {changeFont, } from '../../utils/resizeFontFunctions';

import moment from 'moment';

export default {
  name: "ReserveIndividualDialog",
  props:{
    groupDialog: Boolean,
    openReserveGroupDialog: Function,
    getMyReservas: Function,
    zonas: Array,
    firstMountReserveGroup: Boolean,
    changeFirstMountReserveGroup: Function,
  },
  data(){
    return {
      items: [],
      itemsFiltered: [],
      headers: [
        { text: "Sector", value: "sector" },
        { text: "Edificio", value: "edificio" },
        { text: "Sala", value: "sala" },
        { text: "Piso", value: "piso" },
        { text: "Cupos disponibles", value: "cuposDisponibles" },
        { text: "Aforo máximo", value: "aforo" },
        { text: "Tipo de Sala", value: "tipoSala" },
        { text: "Acceso", value: "acceso" },
        { text: "", value: "actions", sortable: false },
      ],
      menu: false,
      locationLabel: [],
      date: moment(),
      search: '',
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
      edificios: [{
        id: -1,
        nombre: 'Ver todos',
      }],
      zonaSelected: {
        id: -1,
        descripcion: 'Ver todos',
        edificios: []
      },
      edificioSelected: {
        id: -1,
        nombre: 'Ver todos',
      },
    }
  },
  created() {
    const availableDate = this.checkTomorrow();
  },
  mounted() {

  },
  updated() {
    if (this.firstMountReserveGroup) {
      const resize = localStorage.getItem('resize') || 0;
      changeFont(document.getElementsByTagName("body")[0], resize);
      this.changeFirstMountReserveGroup();
    }
  },
  watch: {
    groupDialog() {
      if (this.groupDialog) {
        this.getSalasDesocupadas();
      }
    },
    date() {
      this.getSalasDesocupadas();
    },
    zonaSelected() {
      const defaultEdificio = [{
        id: -1,
        nombre: 'Ver todos',
      }];
      if (this.zonaSelected.id < 0) {
        this.itemsFiltered = this.items;
        this.edificios = [...defaultEdificio];
      }
      else {
        this.edificios = [...defaultEdificio, ...this.zonaSelected.edificios];
        const itemsCopy = [...this.items].filter(item => item.sectorId === this.zonaSelected.id);
        this.itemsFiltered = itemsCopy;
      }
    },
    edificioSelected() {
      if (this.edificioSelected.id < 0) {
        if (this.zonaSelected.id < 0) {
          this.edificios = this.items;
        }
        else {
          this.itemsFiltered = [...this.items].filter(item => item.sectorId === this.zonaSelected.id);
        }
      }
      else {
        const itemsCopy = [...this.items].filter(item => item.edificioId === this.edificioSelected.id);
        this.itemsFiltered = itemsCopy;
      }
    }
  },
  computed: {
    height () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 220
        case 'sm': return 400
        case 'md': return 500
        case 'lg': return 600
        case 'xl': return 800
      }
    },
    computedDateFormatted () {
      return this.formatDate(this.date);
    },
  },
  methods: {
    checkTomorrow() {
      const today = moment().add(1, 'days').subtract(12, 'hours');
      const day = today.day();
      do {
        today.add(1, 'days');
      } while (today.day() === 6 || today.day() === 0);
      this.date = today.format("YYYY-MM-DD");
      return today._d;
    },
    allowedDates(a) {
      return this.availableDates.includes(a);
    },
    pickerUpdate(val) {
      let totalDay = moment(val, "YYYY-MM").daysInMonth();

      let availableDates = []

      let monthNow = moment().format('M');
      let monthSelected = moment(val).format('M');
      let day;

      const startTomorrow = moment().hours() >= 12 ? 1 : 0;

      if(monthNow == monthSelected) {
        day = moment().add(startTomorrow + 1, 'days').format('D');
      }
      else {
        day = 1;
      }

      for (let i = day; i <= totalDay ; i++) {
        let date = moment().month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD");
        if (moment(date).day() !== 0 && moment(date).day() !== 6) {
          availableDates.push(date);
        }
      }
      this.availableDates = availableDates;
      this.allowedDates();
    },
    async getSalasDesocupadas() {
      try {
        const {data} = await this.axios.get(`${process.env.VUE_APP_BASE_URL}/cupos/salas/grupal?fecha=${this.date.replaceAll('-', '/')}`,
          this.auth);
        // console.log(data);
        const dataCopy = [...data];
        // .map(item => ({
        //   ...item,
        //   edificioId: item.edificio.id,
        //   sectorId: item.edificio.sector.id
        // }));
        this.items = dataCopy;
        this.itemsFiltered = dataCopy;
        this.locationLabel = new Array(dataCopy.length).fill(false);
        // const itemsCopy = [...this.items];
        // itemsCopy.push({
        //   zona: `zona ${itemsCopy.length}`,
        //   edificio: `edificio ${itemsCopy.length}`,
        //   sala: `sala 40${itemsCopy.length}`
        // })
        // this.items = itemsCopy;

      } catch (e) {
        console.log(e);
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`
    },
    handleReservarSala(sala) {
      const reservar = () => this.reservarSala(sala.salaId);
      const alertData = {
        title: `¿Esta seguro/a de querer reservar la ${sala.sala} para la fecha ${this.computedDateFormatted}?`,
        text: 'Se agregará esta reserva a su listado',
        acceptFunction: reservar,
        showCancelButton: true
      }
      alertModal(alertData);
    },
    async reservarSala(salaId) {
      try {
        const body = {
          salaId: salaId,
          date: this.date,
        }
        const response = await this.axios.post(`${process.env.VUE_APP_BASE_URL}/reserva/sala/grupal`, body, this.auth);
        this.closeDialog();
      } catch (e) {
        console.log(e);
        const alertData = {
          title: `Reserva de Sala Individual`,
          text: e.response.data.message,
          icon: 'error',
          showCancelButton: false
        }
        alertModal(alertData);
      }
    },
    closeDialog() {
      this.getMyReservas();
      this.openReserveGroupDialog(false);
    }
  }
}
</script>

<style scoped>

.textNotes {
  margin-right: 20px;
}

</style>
