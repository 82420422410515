<template>
  <v-card>
    <v-card-title>
      <span class="headline">Primer acceso</span>
    </v-card-title>
    <v-card-text>Es primera vez que accedes a campus seguro, necesitamos que nos ayudes completando tus datos</v-card-text>
    <v-card-text>Al terminar, presiona Guardar para continuar con la siguiente etapa. Si presionas Salir no podrás solicitar un espacio de estudio</v-card-text>
    <v-card-menu>
      <v-form id="formRegAlumno" v-model="valid" :lazy-validation="lazy">
        <v-card-text>Los campos marcados con asterisco (*) son de carácter obligatorio</v-card-text>
        <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              persistent-hint
              label="Nombres*"
              outlined
              dense
              readonly
              v-model="usuario.name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              persistent-hint
              label="Primer Apellido*"
              outlined
              dense
              readonly
              v-model="usuario.firstSurname"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              persistent-hint
              label="Segundo Apellido*"
              outlined
              dense
              readonly
              v-model="usuario.secondSurname"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="desde"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      dense
                      v-model="usuario.datebirth"
                      label="Fecha nacimiento*"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="desde"
                    min="1960-01-01"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(desde)"
                      >Seleccionar</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field readonly persistent-hint label="Rut*" outlined dense v-model="usuario.rut"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field readonly persistent-hint label="Carrera" outlined dense v-model="usuario.career"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field persistent-hint label="Telefono*" type="number" outlined dense v-model="usuario.phone"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              readonly
              persistent-hint
              label="Correo Institucional*"
              outlined
              dense
              v-model="usuario.email"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              persistent-hint
              label="Correo Alternativo"
              outlined
              dense
              v-model="usuario.alternateEmail"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field persistent-hint label="Direccion" outlined dense v-model="usuario.address"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-select
              v-model="usuario.region"
              :items="regiones"
              item-value="value"
              label="Seleccione region"
              item-text="name"
              dense
              outlined
              persistent-hint
              return-object
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-select
              v-model="usuario.commune"
              :items="comunasSelect"
              item-text="name"
              item-value="value"
              label="Seleccione comuna"
              dense
              outlined
              persistent-hint
              return-object
            ></v-select>
          </v-col>
        </v-row>
        </v-card-text>
      </v-form>
    </v-card-menu>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :loading="loading"
        color="success"
        outlined
        @click="actualizarAlumno()"
      >
        <v-icon left>
          mdi-content-save
        </v-icon>
        Guardar datos
      </v-btn>
      <v-btn
        :loading="loading"
        color="error"
        outlined
        @click="salir()"
      >
        <v-icon left>
          mdi-logout
        </v-icon>
        Salir
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { PDF_QR_JS }  from 'pdf-qr';

import {saveUserToken, } from '../../utils/saveUserToken';
import alertModal from '../../utils/alertModal';

export default {
  props: {
    changeStep: Function,
    alumno: Object,
    salir: Function,
    changeAuth: Function,
    auth: Object,
  },
  data() {

    return {
      desde: "",
      lazy: true,
      usuario: {},
      requiredRules: [v => !!v || "Este campo es requerido"],
      modal: false,
      valid: false,
      loading: false,
      e1: 1,
      dialog: false,
      regiones : [],
      comunas : [],
      comunasSelect : [],
      disableButton: true,
    };
  },

  computed: {
    checkForm() {
      if (this.usuario.name && this.usuario.firstSurname && this.usuario.secondSurname && this.usuario.email
        && this.usuario.rut) {
        return false;
      }
      else {
        return true;
      }
    }
  },

  created() {
    this.getComboRegiones();
    // this.usuario = this.alumno;
  },
  watch: {
    desde() {
      this.usuario.datebirth = this.formatDate(this.desde);
    },
    'usuario.region'() {
      const {region} = this.usuario;
      this.getComboComunas(region?.id || 1);
    },
    alumno() {
      this.usuario = this.alumno;
    },
  },
  methods: {

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    actualizarAlumno(){
      this.loading = true;
      const data = {
        ...this.usuario,
        datebirth: this.desde
      }
      this.axios
          .post(
            process.env.VUE_APP_BASE_URL +
              "/alumno/registro",
            data,
            this.auth
          )
          .then(response => {
            const role = saveUserToken(this, response.data);
            // this.changeAuth(response.data);
            if (role === 'Alumno-NoAutorizado') {
              const alertData = {
                title: `Hubo un error con el registro del alumno`,
                showCancelButton: false,
                icon: 'error',
              }
            }
            else {
              if (role === 'Alumno-Autorizado') {
                this.$router.push({ name: "studentPermission" });
              }
            }
            // alert("Datos registrados con éxito");
            // this.changeStep();
            this.loading = false;
          })
          .catch(error => {
            const alertData = {
              title: `Hubo un error al registrar la información`,
              text: 'Por favor revise que todos los campos estén completos',
              showCancelButton: false,
              icon: 'error',
            }
            alertModal(alertData);
            this.loading = false;
          });
    },
    getComboRegiones(){
        this.axios
          .get(
            process.env.VUE_APP_BASE_URL +
              "/regiones",
            this.auth
          )
          .then(response => {
            this.regiones = response.data;
          })
          .catch(error => {
            alert(error);

          });
    },
    getComboComunas(id){
        this.axios
          .get(`${process.env.VUE_APP_BASE_URL}/regiones/${id}/comunas`, this.auth)
          .then(response => {
            this.comunas = response.data;
            this.comunasSelect = response.data;
          })
          .catch(error => {
            // alert(error);
            console.log(error);

          });
    }
  }
};
</script>
