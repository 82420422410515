export const changeFont = (element, value) => {
  // const style = window
  //   .getComputedStyle(element, null)
  //   .getPropertyValue("font-size");
  // const fontSize = parseFloat(style);
  // console.log(value);
  const sizeValue = parseFloat(1 + parseFloat(value)/100);
  // console.log(sizeValue);
  // console.log(element.children);
  // console.log(sizeValue + "em");
  if (element.nodeName === 'I') {
    element.style.cssText = (sizeValue + 0.3) + "em";
  }
  else if (element.nodeName === 'H1') {
    // console.log(`font-size: ${(sizeValue + 1)} em !important`);
    // element.style.cssText = `font-size: ${(sizeValue + 1)} em !important`;
    element.style.setProperty('font-size', (sizeValue + 1) + "em", "important");
  }
  else if (element.nodeName === 'H2') {
    element.style.cssText = (sizeValue + 0.5) + "em";
  }
  else if (element.nodeName === 'H3') {
    element.style.cssText = (sizeValue + 0.17) + "em";
  }
  else {
    element.style.fontSize = sizeValue + "em";
  }
  if (element.children.length > 0) {
    for (var i = 0; i < element.children.length; i++) {
      changeFont(element.children[i], value);
    }
  }
  // else {
  // }

  // var currentSize = window.getComputedStyle(element, null).getPropertyValue('font-size');
  // if (currentSize) {
  //   currentSize = parseFloat(currentSize.replace("px",""));
  //   let size = (parseFloat(currentSize) * parseFloat(1 + parseFloat(value)/10));
  //   element.style.fontSize = size + "px";
  //   for(var i=0; i < element.children.length; i++){
  //     changeFont(element.children[i], value);
  //   }
  // }

  // var originalSize = element.getAttribute("data-orgFontSize");
  // const currentSize = window.getComputedStyle(element, null).getPropertyValue('font-size');
  // if (!originalSize) {
  //   originalSize = currentSize;
  //   element.setAttribute("data-orgFontSize", currentSize);
  // }
  //
  // if (originalSize) {
  //   const size = parseFloat(originalSize.replace("px",""));
  //   let newSize = (parseFloat(currentSize) * parseFloat(1 + parseFloat(value)/10));
  //   element.style.fontSize = newSize  + "px";
  //   for(var i=0; i < element.children.length; i++){
  //     changeFont(element.children[i], value);
  //   }
  // }
}

const getAllChilds = (element) => {
  if (element.children.length > 0) {
    let myChilds = [];
    for (var i = 0; i < element.children.length; i++) {
      const childs = getAllChilds(element.children[i]);
      if (Array.isArray(childs)) {
        myChilds = [].concat(...getAllChilds(element.children[i]));
      }
      else {
        myChilds.push(childs);
      }
    }
    return myChilds;
  }
  else {
    return element;
  }
}

export const resizeUp = () => {
  const resize = localStorage.getItem('resize') || 0;
  const newSize = parseInt(resize) + 1;
  localStorage.setItem('resize', newSize);
  changeFont(document.getElementsByTagName("body")[0], newSize);
  // const allChilds = getAllChilds(document.getElementsByTagName("body")[0]);
  // console.log(allChilds);
}

export const resizeDown = () => {
  const resize = localStorage.getItem('resize') || 0;
  const newSize = parseInt(resize) - 1;
  localStorage.setItem('resize', newSize);
  changeFont(document.getElementsByTagName("body")[0], newSize);
}
