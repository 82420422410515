<template>
  <v-data-table
    :loading="cargando"
    id="tableInquitudes"
    item-key="id"
    :fixed-header="fixed"
    :height="height"
    :headers="headers"
    :items="itemsIndex"
    :items-per-page="10"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-dialog v-model="dialog2" max-width="700px">
        <v-card>
          <v-card-title>
            <span class="headline">Rechazo de salvoconducto</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    hint="Motivo por el cúal rechaza este salvoconducto"
                    persistent-hint
                    outlined
                    counter
                    no-resize
                    label="Motivo de rechazo*"
                    v-model="motivoEstado"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              color="blue darken-1"
              text
              @click="dialog2 = false"
              >Cerrar</v-btn
            >
            <v-btn
              :loading="loading"
              color="blue darken-1"
              text
              @click="rechazaSolicitud()"
              >rechazar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title
              >Aprobación de salvoconducto excepcional</v-toolbar-title
            >
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-title>
            <span class="headline"
              >Editar campos de salvoconducto excepcional</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    readonly
                    outlined
                    dense
                    persistent-hint
                    label="Nombre del funcionario"
                    :value="
                      editedItem.nombres +
                      ' ' +
                      editedItem.apellidoP +
                      ' ' +
                      editedItem.apellidoM
                    "
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    readonly
                    outlined
                    dense
                    persistent-hint
                    label="Rut"
                    :value="editedItem.rut"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    readonly
                    outlined
                    dense
                    persistent-hint
                    label="Fecha de ingreso al Campus"
                    :value="editedItem.fechaSolicitud"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    return-object
                    outlined
                    dense
                    :items="horarios"
                    label="Ingrese horario"
                    @change="updateHorario()"
                    v-model="editedItem.bloqueHorario"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    readonly
                    outlined
                    dense
                    persistent-hint
                    label="Sector solicitado para ingreso"
                    :value="
                      editedItem.zona +
                      ' -- ' +
                      editedItem.edificio +
                      ' -- ' +
                      editedItem.dependencia
                    "
                  ></v-text-field>
                </v-col>

                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    readonly
                    outlined
                    dense
                    persistent-hint
                    label="Aforo Maximo en Sector"
                    :value="maximo"
                  ></v-text-field>
                </v-col>

                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    readonly
                    outlined
                    dense
                    persistent-hint
                    label="Cantidad actual en Sector"
                    :value="actualTotal"
                  ></v-text-field>
                </v-col>

                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    readonly
                    outlined
                    dense
                    persistent-hint
                    label="Cupos disponibles en Sector"
                    :value="maximo - actualTotal"
                  ></v-text-field>
                </v-col>

                <v-col cols="3" sm="3" md="3">
                  <v-text-field
                    readonly
                    outlined
                    dense
                    persistent-hint
                    label="Solicitudes pendientes en Sector"
                    :value="pendientes"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    readonly
                    hint="Motivo por el cúal necesita ingresar a la universidad"
                    persistent-hint
                    outlined
                    counter
                    no-resize
                    label="Motivo de ingreso*"
                    :value="editedItem.motivo"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-container>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="blue darken-1"
                :disabled="disabled"
                text
                @click="enviaTipoAprobacion(1)"
                >Aprobar</v-btn
              >
              <v-btn
                :loading="loading"
                :disabled="!disabled"
                color="blue darken-1"
                text
                @click="enviaTipoAprobacion(3)"
                >Aprobar con Observación</v-btn
              >
              <v-btn
                :loading="loading"
                color="blue darken-1"
                text
                @click="dialog2 = true"
                >Rechazar</v-btn
              >
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialog3"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog3 = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Información de sectores</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-title>
            <span class="headline"
              >Estadistica por sector (del presente día)</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <p>Elije un sector para su ingreso:</p>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        return-object
                        outlined
                        dense
                        item-text="name"
                        :items="opcionesTipo"
                        label="Sectores del Campus"
                        v-model="sector"
                        @change="requestChart"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4" sm="12" md="4">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        readonly
                        outlined
                        dense
                        persistent-hint
                        label="Aforo Maximo en Sector"
                        :value="maximo"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        readonly
                        outlined
                        dense
                        persistent-hint
                        label="Cantidad actual en Sector"
                        :value="actualTotal"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        readonly
                        outlined
                        dense
                        persistent-hint
                        label="Cupos disponibles en Sector"
                        :value="maximo - actualTotal"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        readonly
                        outlined
                        dense
                        persistent-hint
                        label="Solicitudes pendientes en Sector"
                        :value="pendientes"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="8" sm="12" md="8">
                  <div id="chartdiv"></div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{ nameTitle }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-row>
          <v-col>
            <v-text-field
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              hide-details
              dense
            ></v-text-field>
          </v-col>
          <v-col v-if="!activos">
            <v-btn color="blue darken-1" text @click="openDialog3()"
              >Estadistica</v-btn
            >
          </v-col>
          <v-col v-if="!activos">
            <v-btn color="blue darken-1" text @click="sendData(2)"
              >Ver Aceptados</v-btn
            >
          </v-col>
          <v-col v-if="activos">
            <v-btn color="blue darken-1" text @click="sendData(1)"
              >Ver Peticiones</v-btn
            >
          </v-col>
          <v-col v-if="activos">
            <v-btn
              dense
              color="warning"
              text
              title="Descargar Info"
              @click="downloadExcel()"
              :loading="waitDownload"
              >Descargar
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.acciones="{ item }">
      <v-icon
        color="#071D49"
        class="mr-2"
        title="Editar salvoconducto"
        @click="showDetailItem(item)"
        >mdi-eye</v-icon
      >
    </template>
  </v-data-table>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
export default {
  props: {
    cargando: Boolean,
    headers: Array,
    items: Array,
    nameTitle: String,
    activos: Boolean,
  },

  mounted() {
    // Create chart instance
    let chart = am4core.create("chartdiv", am4charts.PieChart);

    // Add data
    chart.data = this.chart;

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";
  },
  data() {
    return {
      waitDownload: false,
      sector: "",
      chart: [
        {
          country: "Sin Datos",
          litres: 0,
        },
      ],
      date: new Date().toISOString().substr(0, 10),
      opcionesTipo: [
        {
          name: "Sector 1 - S1",
          nameValores: "Sector 1",
          value: "S-1",
        },
        {
          name: "Sector 2 - S2",
          nameValores: "Sector 2",
          value: "S-2",
        },
        {
          name: "Sector 3 - S3",
          nameValores: "Sector 3",
          value: "S-3",
        },
        {
          name: "Sector 4 - S4",
          nameValores: "Sector 4",
          value: "S-4",
        },
        {
          name: "Sector 5 - S5",
          nameValores: "Sector 5",
          value: "S-5",
        },
        {
          name: "Sector 6 - S6",
          nameValores: "Sector 6",
          value: "S-6",
        },
        {
          name: "Sector 7 - S7",
          nameValores: "Sector 7",
          value: "S-7",
        },
        {
          name: "Sector 8 - S8",
          nameValores: "Sector 8",
          value: "S-8",
        },
      ],
      dialog: false,
      dialog3: false,
      maximo: 0,
      actualEx: 0,
      actualRegular: 0,
      pendientes: 0,
      loading: false,
      dialog2: false,
      disabled: false,
      motivoEstado: "",
      horarios: [
        "8:00 hrs a 11:00 hrs",
        "9:00 hrs a 12:00 hrs",
        "10:00 hrs a 13:00 hrs",
        "11:00 hrs a 14:00 hrs",
        "12:00 hrs a 15:00 hrs",
        "13:00 hrs a 16:00 hrs",
        "14:00 hrs a 17:00 hrs",
        "15:00 hrs a 18:00 hrs",
      ],
      horario: "",
      editedItem: "",
      dialog: false,
      isSelecting: false,
      isSelecting2: false,
      option: "",
      zonas: [],
      panel: [1],
      tipo: "",
      selected: [],
      search: "",
      height: 400,
      fixed: true,
      temperatura: 0,
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
    };
  },

  /* watch: {
    selectedFiebre() {
      console.log(this.selectedFiebre);
    }
  }, */

  computed: {
    itemsIndex() {
      return this.items.map((item, index) => ({
        id: index,
        ...item,
      }));
    },
    actualTotal() {
      return this.actualEx + this.actualRegular;
    },
  },

  created() {},

  methods: {
    forceFileDownload(response, nameFile) {
      //console.log("Aqui va el header");
      //console.log(response.headers);
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nameFile); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    downloadExcel() {
      //console.log(this.data);
      this.waitDownload = true;
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/salvoconductoexcepcional/exportExcelSE",
          {
            responseType: "arraybuffer",
            headers: { Authorization: "" + this.$session.get("jwt") },
          }
        )
        .then((response) => {
          var fecha = new Date();
          this.forceFileDownload(
            response,
            "SalvoExcepcionales Activos " +
              this.formatDate(fecha.toISOString().substr(0, 10)) +
              ".xlsx"
          );
          this.waitDownload = false;
        })
        .catch((error) => {
          alert(error.response);
          this.waitDownload = false;
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getMaximo() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/zona/obtenerAfueroMaximo?sector=" +
            this.editedItem.zona,
          this.auth
        )
        .then((response) => {
          this.maximo = response.data.cantidad;
        })
        .catch((error) => {
          alert(error);
        });
    },

    getActualExcepcional() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/salvoconductoexcepcional/contarSalvosActivosPorDiaSector?sector=" +
            this.editedItem.zona +
            "&fecha=" +
            this.editedItem.fechaSolicitud,
          this.auth
        )
        .then((response) => {
          this.actualEx = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },

    openDialog3() {
      this.maximo = 0;
      this.actualEx = 0;
      this.actualRegular = 0;
      this.pendientes = 0;
      this.sector = {};
      this.dialog3 = true;
      this.createChart([
        {
          country: "Sin Datos",
          litres: 0,
        },
      ]);
    },

    getActualRegular() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/salvoconducto/contarSalvosPorSector?sector=" +
            this.editedItem.zona,
          this.auth
        )
        .then((response) => {
          this.actualRegular = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },

    getPendientes() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/salvoconductoexcepcional/contarSalvosPendientesPorDiaSector?sector=" +
            this.editedItem.zona +
            "&fecha=" +
            this.editedItem.fechaSolicitud,
          this.auth
        )
        .then((response) => {
          this.pendientes = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },

    requestChart() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/zona/obtenerAfueroMaximo?sector=" +
            this.sector.nameValores,
          this.auth
        )
        .then((response) => {
          this.maximo = response.data.cantidad;
        })
        .then((response) => {
          this.axios
            .get(
              process.env.VUE_APP_BASE_URL +
                "/salvoconducto/contarSalvosPorSector?sector=" +
                this.sector.nameValores,
              this.auth
            )
            .then((response) => {
              this.actualRegular = response.data;
            })
            .then((response) => {
              this.axios
                .get(
                  process.env.VUE_APP_BASE_URL +
                    "/salvoconductoexcepcional/contarSalvosActivosPorDiaSector?sector=" +
                    this.sector.nameValores +
                    "&fecha=" +
                    this.formatDate(this.date),
                  this.auth
                )
                .then((response) => {
                  this.actualEx = response.data;
                })
                .then((reponse) => {
                  this.axios
                    .get(
                      process.env.VUE_APP_BASE_URL +
                        "/salvoconductoexcepcional/contarSalvosPendientesPorDiaSector?sector=" +
                        this.sector.nameValores +
                        "&fecha=" +
                        this.formatDate(this.date),
                      this.auth
                    )
                    .then((response) => {
                      this.pendientes = response.data;
                      this.chart = [
                        {
                          country: "S. REGULAR",
                          litres: this.actualRegular,
                        },
                        {
                          country: "S. EXCEPCIONAL",
                          litres: this.actualEx,
                        },
                        ,
                        {
                          country: "CUPOS DISPONIBLES ",
                          litres: this.maximo - this.actualTotal,
                        },
                      ];

                      this.createChart(this.chart);
                    })
                    .catch((error) => {
                      alert(error);
                    });
                })
                .catch((error) => {
                  alert(error);
                });
            })
            .catch((error) => {
              alert(error);
            });
        })
        .catch((error) => {
          alert(error);
        });
    },
    createChart(data) {
      am4core.disposeAllCharts();

      let chart = am4core.create("chartdiv", am4charts.PieChart);
      // Add data
      chart.data = data;
      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "litres";
      pieSeries.dataFields.category = "country";
    },
    sendData(char) {
      this.$emit("send-data", char);
    },
    showDetailItem(item) {
      //TODO: Cambiar busqueda por algo unico
      this.editedItem = item;
      this.getMaximo();
      this.getActualExcepcional();
      this.getActualRegular();
      this.getPendientes();
      this.dialog = true;
    },

    updateHorario() {
      this.axios
        .put(
          process.env.VUE_APP_BASE_URL +
            "/salvoconductoexcepcional/updateSalvoconductoExcepcional",
          this.editedItem,
          this.auth
        )
        .then((response) => {
          this.editedItem = response.data;
          this.disabled = true;

          alert("Haz actualizado el bloque horario");
        })
        .catch((error) => {
          alert(error);
        });
    },

    enviaTipoAprobacion(estado) {
      this.loading = true;
      if (estado == 3)
        this.motivoEstado =
          "Su salvoconducto ha sido aprobado, pero en el siguiente bloque horario " +
          this.editedItem.bloqueHorario;
      this.axios
        .put(
          process.env.VUE_APP_BASE_URL +
            "/salvoconductoexcepcional/cambiarEstado?estado=" +
            estado +
            "&motivo=" +
            this.motivoEstado,
          this.editedItem,
          this.auth
        )
        .then((response) => {
          this.editedItem = response.data;
          this.motivoEstado = "";
          this.dialog2 = false;
          this.dialog = false;
          this.disabled = false;
          this.loading = false;
          this.sendData(1);
          alert("Acción realizada");
        })
        .catch((error) => {
          this.loading = false;
          alert(error);
        });
    },

    rechazaSolicitud() {
      this.enviaTipoAprobacion(2);
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
  },
};
</script>

<style scoped>
#chartdiv {
  width: 100%;
  height: 400px;
}
</style>
