<template>
  <v-card>
    <v-card-title>
      <span class="headline">Términos y condiciones</span>
    </v-card-title>
    <v-card-text>
      Necesitamos que te informes acerca de las reglas que debes respetar para poder ingresar al campus y utilizar esta plataforma.
    </v-card-text>
    <v-card-text>
      Despues de leer, marca la casilla y presiona Aceptar para continuar con la siguiente etapa.
      Si presionas Salir no podrás solicitar el salvoconducto.
    </v-card-text>
    <v-card-menu>
      <v-form id="formTerminos" v-model="valid" :lazy-validation="lazy">
        <v-container>
          <TerminosYCondicionesText />
        </v-container>
        <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-checkbox v-model="checkTerms">
              <template v-slot:label>
                <div>
                  He leído y acepto los términos y condiciones
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        </v-card-text>
      </v-form>
    </v-card-menu>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :loading="loading"
        color="success"
        :disabled="!checkTerms"
        outlined
        @click="aceptarTerminos()"
      >
        <v-icon left>
          mdi-check-bold
        </v-icon>
        Continuar
      </v-btn>
      <v-btn
        :loading="loading"
        color="error"
        outlined
        @click="salir()"
      >
        <v-icon left>
          mdi-logout
        </v-icon>
        Salir
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { PDF_QR_JS }  from 'pdf-qr';

import TerminosYCondicionesText from './TerminosYCondicionesText';

import alertModal from '../../../utils/alertModal';

export default {
  components: {
    TerminosYCondicionesText
  },
  props: {
    changeStep: Function,
    alumno: Object,
    salir: Function,
    auth: Object,
  },
  data() {

    return {
      valid: false,
      checkTerms: false,
      lazy: true,
      requiredRules: [v => !!v || "Este campo es requerido"],
      loading: false,
    };
  },

  computed: {},

  created() {

  },
  watch: {

  },
  methods: {

    aceptarTerminos(){
      this.loading = true;
      this.axios
          .post(
            process.env.VUE_APP_BASE_URL +
              "/terminos-condiciones",
              {
                checked: this.checkTerms ? 1 : 0
              },
              this.auth
          )
          .then(response => {
            // alert("Se ha registrado su aceptación de los términos y condiciones");
            this.changeStep();
            this.loading = false;
          })
          .catch(error => {
            const alertData = {
              title: `Hubo un error al registrar los términos y condiciones`,
              text: 'Por favor vuelva a intentarlo',
              showCancelButton: false,
              icon: 'error',
            }
            alertModal(alertData);
            this.loading = false;
          });
    },
  }
};
</script>
