<template>
  <v-container>
    <ReserveTable
      :openHealthDialog="openHealthDialog"
      :openHistoryDialog="openHistoryDialog"
      :reservas="reservas"
      :getAllReservas="getAllReservas"
    />
    <HealthDeclarationDialog
      :openHealthDialog="openHealthDialog"
      :healthDialog="healthDialog"
      :getAllReservas="getAllReservas"
      :currentItem="currentItem"
      :edit="false"
    />
    <ReserveHistory
      :openHistoryDialog="openHistoryDialog"
      :historyDialog="historyDialog"
      :currentItem="currentItem"
    />

  </v-container>

</template>

<script>

import ReserveTable from './ReserveTable'
import HealthDeclarationDialog from '../../Reserve/HealthDeclarationDialog';
import ReserveHistory from './ReserveHistory';

export default {
  name: "ReserveListAdmin",
  components: {
    ReserveTable,
    HealthDeclarationDialog,
    ReserveHistory,
  },
  data() {
    return {
      healthDialog: false,
      historyDialog: false,
      reservas: [],
      zonas: [],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
      currentItem: {},
    };
  },
  created(){
    this.getAllReservas();
  },
  computed: {

  },
  watch: {

  },
  methods: {

    async getAllReservas() {
      try {

        //Descomentar cuando este lista la api
        const response = await this.axios.get(`${process.env.VUE_APP_BASE_URL}/reservas/historial/estado/completado`, this.auth);
        this.reservas = response.data;


      } catch (e) {
        console.log(e);
      }
    },
    openHealthDialog(state, item) {
      this.healthDialog = state;
      this.currentItem = item;
    },
    openHistoryDialog(state, item) {
      this.historyDialog = state;
      this.currentItem = item;
    },

  },
};
</script>
<style scoped>

</style>
