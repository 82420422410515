<template>
  <v-container>
    <h1>Formulario de ingreso a Universidad</h1>

    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Mapa de Zonas Campus</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-list three-line subheader>
          <img :src="img" class="hidden1 parallax" />
          <img :src="img" class="hidden2 parallax" />
        </v-list>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Salvoconducto activo</span>
        </v-card-title>
        <v-card-text>Se ha detectado que tienes un salvoconducto activo, por ende no puedes solicitar uno nuevamente</v-card-text>
        <v-card-text>Presiona "Descargar" para obtener el salvoconducto del día de hoy, de lo contrario presiona "Salir"</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="loading" color="blue darken-1" text @click="salir()">Salir</v-btn>
          <v-btn :loading="loading" color="blue darken-1" text @click="downloadSalvo()">Descargar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-form id="formVrae" v-model="valid" :lazy-validation="lazy">
      <p>Los campos marcados con asterisco (*) son de carácter obligatorio</p>

      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            readonly
            persistent-hint
            label="Nombres*"
            outlined
            dense
            v-model="usuario.nombres"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
            readonly
            persistent-hint
            label="Apellido Paterno*"
            outlined
            dense
            v-model="usuario.apellidoP"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
            readonly
            persistent-hint
            label="Apellido Materno*"
            outlined
            dense
            v-model="usuario.apellidoM"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-text-field readonly persistent-hint label="Rut*" outlined dense v-model="usuario.rut"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            readonly
            persistent-hint
            label="Correo Institucional*"
            outlined
            dense
            v-model="usuario.correoLdap"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-btn color="primary" dark v-bind="attrs" @click="dialog = true">Ver Zonas</v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="12">
          <!-- <v-data-table
            id="tableZonas"
            item-key="oficina"
            dense
            fixed-header
            height="155px"
            :headers="headers"
            :items="zonas"
            :items-per-page="5"
            class="elevation-1"
          ></v-data-table>-->
          <p>Zonas de ingreso permitidas:</p>
          <v-simple-table dense fixed-header height="155px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Sector</th>
                  <th class="text-left">Edificio</th>
                  <th class="text-left">Dependencia/Laboratorio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in zonas" :key="index">
                  <td>{{ item.sector }}</td>
                  <td>{{ item.edificio }}</td>
                  <td>{{ item.oficina }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            hint="Motivo por el cúal necesita ingresar a la universidad"
            persistent-hint
            outlined
            counter
            no-resize
            :rules="requiredRules"
            label="Motivo de ingreso*"
            v-model="motivo"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row justify="end">
            <v-btn
              class="botton-final"
              :loading="loading"
              :disabled="!valid"
              color="warning"
              @click="enviarSalvo()"
              light
            >Enviar</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>

import alertModal from '../utils/alertModal';

import img from "../assets/campus.png";
export default {
  data() {
    return {
      dialog2: false,
      countSalvo: "",
      headers: [
        { text: "Sector", value: "sector" },
        { text: "Edificio", value: "edificio" },
        { text: "Oficina", value: "oficina" }
      ],
      usuario: "",
      attrs: "",
      zonas: [],
      zona: "",
      motivo: "",
      img: img,
      inquietudes: [
        "Problema de conectividad - Computador",
        "Problema de conectividad - Internet",
        "Problemas Económicos"
      ],
      panel: false,
      requiredRules: [v => !!v || "Este campo es requerido"],
      files: [],
      alumno: {
        carrera: {}
      },
      inquietud: "",
      detalle: "",
      deshabilitado: true,
      carrera: {},
      lazy: false,
      valid: false,
      loading: false,
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      },
      dialog: false,
    };
  },

  computed: {},

  created() {
    this.axios
      .get(
        process.env.VUE_APP_BASE_URL + "/usuario/getUsuario?id="+
        this.$session.get("id"),
        this.auth
      )
      .then(response => {
        this.usuario = response.data;
        this.getZonas();
        if (
          !this.usuario.habilitadoComite ||
          //!this.usuario.habilitadoCapacitacion ||
          !this.usuario.cuarentena
        ) {
          alert(
            "A continuación, usted será redirigido(a) a un nuevo formulario para solicitar un salvoconducto excepcional, ya que actualmente no está habilitado(a) para solicitar un salvoconducto regular debido a los siguientes motivos: \n\n" +
              " - Habilitado(a) por Comité: " +
              this.habilitacion(this.usuario.habilitadoComite) +
              /*
              "\n - Habilitado(a) por Capacitaciones: " +
              this.habilitacion(this.usuario.habilitadoCapacitacion) +
              */
              "\n"
          );
          //this.$session.destroy();
          this.$router.push("/formularioex");
        }
        this.validarSalvosActivos();
      })
      .catch(error => {
        alert(error);
      });
  },

  methods: {
    getZonas() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/usuario/getNombreZonas?id=" +
            this.usuario.id,
          this.auth
        )
        .then(response => {
          this.zonas = response.data;
        })
        .catch(error => {
          alert(error);
        });
    },

    habilitacion(item) {
      if (item) {
        return "Sí";
      } else {
        return "No";
      }
    },
    validarSalvosActivos() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/salvoconducto/countSalvoconductos?usuarioId=" +
            this.usuario.id,
          this.auth
        )
        .then(response => {
          this.countSalvo = response.data;
          if (this.countSalvo > 0) {
            this.dialog2 = true;
          }
        })
        .catch(error => {
          alert(error);
        });
    },

    salir() {
      this.$session.destroy();
      this.$router.push("/");
    },

    downloadSalvo() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/salvoconducto/devuelveSalvoconducto64?usuarioId=" +
            this.usuario.id,
          this.auth
        )
        .then(response => {
          var fecha = new Date();
          var a = document.createElement("a"); //Create <a>
          a.href = "data:application/pdf;base64," + response.data; //Image Base64 Goes here
          a.download =
            "salvoconducto_regular_" +
            this.formatDate(fecha.toISOString().substr(0, 10)) +
            ".pdf"; //File name Here
          a.click(); //Downloaded file
        })
        .catch(error => {
          this.loading = false;
           const alertData = {
            title: error.response?.data?.message,
            showCancelButton: false,
            icon: 'error',
          }
          alertModal(alertData);
        });
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    enviarSalvo() {
      console.log('enviando pdf');
      if (this.countSalvo > 0) {
        alert(
          "Usted ya cuenta con un salvoconducto activo, no puede solicitar uno nuevo."
        );
        this.$session.destroy();
        this.$router.push("/");
      } else {
        this.loading = true;
        const salvoconducto = {
          motivo: this.motivo,
        };
        this.axios
          .post(
            process.env.VUE_APP_BASE_URL +
              "/salvoconducto",
            salvoconducto,
            this.auth
          )
          .then(response => {
            alert("Salvoconducto emitido con éxito");
            //abre el PDF en nueva ventana, ver como hacer esto bonito
            this.loading = false;
            this.downloadSalvo();
            this.$session.destroy();
            this.$router.push("/");
          })
          .catch(error => {
            alert(error);
            this.loading = false;
          });
      }
    }
  }
};
</script>

<style scoped>
.parallax {
  z-index: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.botton-final {
  margin-bottom: 50px;
}

@media only screen and (min-width: 900px) {
  .hidden1 {
    display: none !important;
  }
}
@media only screen and (max-width: 899px) {
  .hidden2 {
    display: none !important;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}
</style>
