<template>
  <v-container>
    <h1>Notificación de Síntomas</h1>
    <NotificarSintomas/>
  </v-container>
</template>

<script>
import NotificarSintomas from '../components/NotificarSintomas';

export default {
  name: "NotificarSintomasView",
  components: {
    NotificarSintomas
  },
  data() {
    return {

    }
  }

}
</script>

<style scoped>

</style>
