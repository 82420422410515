<template>
  <v-container>
    <h1>Administración de alumnos</h1>
    <AlumnosAdmin/>
  </v-container>
</template>

<script>
import AlumnosAdmin from '../components/AlumnosAdmin/AlumnosAdmin'
export default {
  name: "AlumnosAdminView",
  components: {
    AlumnosAdmin
  },
  data(){
    return {

    }
  }

}
</script>

<style scoped>

</style>
