<template>
  <v-content class="content">
    <v-row justify="center">
      <v-col
        v-for="(n, index) in items"
        :key="index"
        cols="12"
        :sm="n.class"
        :md="n.class"
        :lg="n.class"
      >
        <v-card flat tile class="d-flex">
          <div class="contenedor">
            <v-img :src="n.src" aspect-ratio="1" class="image">
              <template v-slot:placeholder>
                <v-row justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card style="background-color: #071D49;" class="image fondo">
              <p style>{{n.name}}</p>
            </v-card>

            <div class="middle">
              <v-col cols="12">
                <v-row justify="center">
                  <v-btn :aria-label="n.name" :to="{path: n.path}" dark color="#071D49">Ingresar</v-btn>
                </v-row>
              </v-col>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        v-for="(n, index) in items2"
        :key="index"
        cols="12"
        :sm="n.class"
        :md="n.class"
        :lg="n.class"
      >
        <v-card flat tile class="d-flex">
          <div class="contenedor">
            <v-img :src="n.src" aspect-ratio="1" class="image">
              <template v-slot:placeholder>
                <v-row justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card style="background-color: #071D49;" class="image fondo">
              <p style>{{n.name}}</p>
            </v-card>

            <div class="middle">
              <v-col cols="12">
                <v-row justify="center">
                  <v-btn :aria-label="n.name" :to="{path: n.path}" dark color="#071D49">Ingresar</v-btn>
                </v-row>
              </v-col>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-content>
</template>

<script>
import img1 from "../assets/DTI-01.png";
import img2 from "../assets/DTI-03.png";
import img3 from "../assets/DTI-02.png";
import img4 from "../assets/excepcional.png";
import img5 from "../assets/DTI-04.png";
import iconoqr from "../assets/icono_QR.png";
import alumnos from "../assets/admalumnos.png";

export default {
    data() {
    return {
    //TODO: cambiar en base tipo de usuario
    pro: "",
    items: [],
    itemsEstudiante: [
      {
        name: "Administración de Funcionarios",
        src: img1,
        path: "/lista-usuarios",
        pro: false,
        class: 4
      },
      {
        name: "Listado de Ingresos (Hoy)",
        src: img2,
        path: "/lista-ingreso",
        pro: false,
        class: 4
      },
      {
        name: "Listado de Ingresos (Información Histórica)",
        src: img3,
        path: "/lista-ingreso-historico",
        pro: false,
        class: 4
      },
    ],
    items2:
      [
        {
          name: "Administrar salvoconductos excepcionales",
          src: img4,
          path: "/lista-salvoconducto",
          pro: false,
          class: 4
        },
        {
          name: "Salvoconducto Semanal",
          src: img5,
          path: "/lista-personal-extra",
          pro: false,
          class: 4
        },
        {
          name: "Administración alumnos",
          src: alumnos,
          path: "/alumnos",
          pro: false,
          class: 4
        },
      ]
    };
  },
  created() {
    //TODO
    this.items = this.itemsEstudiante;

  }
};
</script>
<style scoped>
.contenedor:hover .middle {
  opacity: 1;
}

.contenedor:focus-within .middle{
  opacity: 1;
}
.image {
  display: block;
  width: 70%  ;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.middle {
  width: 50% !important;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.contenedor {
  width: 100%;
  height: auto;
  padding: 0;
}

.fondo {
  margin-top: 5px;
  text-align: center;
  color: white;
  justify-content: center;
  align-content: center;
}

.contenedor:hover .image {
  opacity: 0.3;
}
.content {
  margin-top: 15vh;
  margin-bottom: 15vh;
}
</style>
