<template>
  <v-container>
    <v-row>
      <v-col lg="12" md="12" xs="12" sm="12">
        <StudentDetails
          :alumno="alumno"
          :asignaturas="asignaturas"
          :loading="loadingAsignaturas"
        />
      </v-col>
      <v-col>
        <PermissionsTable
        />
      </v-col>
    </v-row>

  </v-container>
</template>


<script>

import StudentDetails from './StudentDetails';
import PermissionsTable from './PermissionsTable';

export default {
  name: "StudentPermission",
  components: {
    StudentDetails,
    PermissionsTable,
  },
  data() {
    return {
      asignaturas: [],
      alumno: {},
      loadingAsignaturas: false,
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      },
    };
  },
  created() {
    this.getDataAlumno();
    this.getAsignaturasAlumno();
  },
  computed: {

  },
  watch: {

  },
  updated() {

  },
  mounted() {

  },
  methods: {
    async getDataAlumno() {
      try {
        const {data} = await this.axios.get(`${process.env.VUE_APP_BASE_URL}/alumno/${this.$session.get("id")}`, this.auth);
        console.log(data);
        this.alumno = {
          ...data,
          name: `${data.name} ${data.firstSurname} ${data.secondSurname}`
        }
      } catch (e) {

      }
    },
    async getAsignaturasAlumno() {
      try {
        this.loadingAsignaturas = true;
        const {data} = await this.axios.get(`${process.env.VUE_APP_BASE_URL}/alumno/asignaturas`, this.auth);
        this.asignaturas = [...data];
        this.loadingAsignaturas = false;
      } catch (e) {
        console.log(e);
        this.loadingAsignaturas = false;
      }
    }
  }
}
</script>

<style scoped>
.columnsList {
  padding-top: 0px;
  padding-bottom: 0px;
}

.column_wrapper {
  column-count: 2;
}

@media only screen and (max-width: 600px) {
  .column_wrapper {
    column-count: 1;
  }
}


</style>
