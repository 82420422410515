import jwtDecode from "jwt-decode";

export const saveUserToken = (self, token) => {

  self.$session.set("jwt", "Bearer " + token);
  var Decode = jwtDecode(token);
  self.$session.set("id", Decode.identificador);
  self.$session.set("typeUser", Decode.authorities[0]);
  self.$session.set("email", Decode.email);
  return Decode.authorities[0];
}
