<template>
  <v-app >
    <div class="pageBody">
      <router-view></router-view>
    </div>
  </v-app>
</template>

<script>
import Layout from './components/layout component/Layout'
export default {
  name: 'App',

  components: {
    Layout
  },

  created(){

  },

  data() {
    return {
    //
    };
  },
};
</script>

<style scoped>

.pageBody {
  font-size: 1em !important;
}

</style>
