<template>
  <div>
    <v-app-bar fixed color="#071D49" class="nav mx-btn">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="white" class="md-hidden"></v-app-bar-nav-icon>
      <v-navigation-drawer v-model="drawer" temporary app absolute>
        <v-list flat>
          <v-list-item-group v-model="group" color="deep-orange">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title">Hola Usuario</v-list-item-title>
                <v-list-item-subtitle>perfil</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>


          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <img
        class="img-fix"
        alt="Logo universidad de Santiago de Chile"
        :src="img1"
        width="100"
        height="50"
        style="margin-right:20px"
      />

      <v-toolbar-title class="text-white"></v-toolbar-title>

      <img
        class="img-fix"
        alt="Logo Campus Seguro"
        :src="img2"
        height="50"
        style="margin-right:20px"
      />

      <v-spacer></v-spacer>

      <div class="text-center">
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="indigo" dark v-bind="attrs" v-on="on">Contacto</v-btn>
            <v-btn @click="resizeDown()" dark icon title="Disminuir tamaño de letra" class="sm-hidden mr-1">
              <v-icon>mdi-format-font-size-decrease</v-icon>
            </v-btn>

            <v-btn @click="resizeUp()" dark icon title="Aumentar tamaño de letra" class="sm-hidden mr-1">
              <v-icon>mdi-format-font-size-increase</v-icon>
            </v-btn>

          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <img width="100" :src="logo_usach2" alt="John" />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>Prorrectoría</v-list-item-title>
                  <v-list-item-subtitle>Contacto de Plataforma</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-icon right>mdi-email</v-icon>
                </v-list-item-action>
                <v-list-item-title>covid19@usach.cl</v-list-item-title>
              </v-list-item>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text @click="menu = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>

<!--         <div class="text-center">
        <v-btn color="white" :to="{ name: 'loginCert' }">Certificado</v-btn>
      </div> -->
    </v-app-bar>
    <img :src="require(`../../../assets/${this.backgroundImage}.png`)" class="parallax" />
  </div>
</template>

<script>
import img from "../../../assets/USACH_2.png";
import img1 from "../../../assets/LOGO USACH.png";
import img2 from "../../../assets/CampusSeguro Icon-2.png";
import logo_usach2 from "../../../assets/logo_usach2.jpg";

import {resizeUp, resizeDown, changeFont, } from '../../../utils/resizeFontFunctions';

export default {
  name: 'TopBar',
  props: {
    backgroundImage: String,
  },
  data() {
    return {
      logo_usach2: logo_usach2,
      img1: img1,
      img2: img2,
      img: img,
      menu: false,
      drawer: false,
      group: false,
    };
  },

  mounted() {
    const resize = localStorage.getItem('resize') || 0;
    changeFont(document.getElementsByTagName("body")[0], resize);
  },

  methods: {
    resizeUp,
    resizeDown,
    // changeFont(element, value) {
    //   var style = window
    //     .getComputedStyle(element, null)
    //     .getPropertyValue("font-size");
    //   var fontSize = parseFloat(style);
    //   const sizeValue = parseFloat(parseFloat(fontSize) + parseFloat(value));
    //   element.style.fontSize = sizeValue + "px";
    //   for (var i = 0; i < element.children.length; i++) {
    //     this.changeFont(element.children[i], value);
    //   }
    // },
    // resizeUp() {
    //   const resize = localStorage.getItem('resize') || 0;
    //   const newSize = parseInt(resize) + 1;
    //   localStorage.setItem('resize', newSize);
    //   this.changeFont(document.getElementsByTagName("body")[0], 1);
    // },
    // resizeDown() {
    //   var resize = localStorage.getItem('resize') || 0;
    //   const newSize = parseInt(resize) - 1;
    //   localStorage.setItem('resize', newSize);
    //   this.changeFont(document.getElementsByTagName("body")[0], -1);
    // },
  }
};
</script>
<style scoped>
.text-center {
  margin-left: 3px !important;
}

.parallax {
  position: fixed;
  z-index: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  filter: blur(0.3rem);
}

</style>
