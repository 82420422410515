<template>
  <v-card>
    <v-card-title>
      <div>
        Mis Reservas
      </div>
      <v-divider
          class="mx-4"
          inset
          vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-btn
          color="primary"
          dark
          class="mb-2 buttons"
          @click="openReserveDialog(true)"
          x-large
        >
        Reservar Sala - Individual
      </v-btn>
<!--      <v-btn-->
<!--          color="primary"-->
<!--          dark-->
<!--          class="mb-2 buttons"-->
<!--          @click="openReserveGroupDialog(true)"-->
<!--          x-large-->
<!--        >-->
<!--        Reservar Sala - Grupal-->
<!--      </v-btn>-->
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="reservas"
        elevation="0"
      >
        <template v-slot:item.actions="{ item, index }">
          <div class="actionsIcons">

            <div
              v-if="item.reserveType === 'GRUPAL' && item.reserveStatus !== 'CANCELADO'"
            >
              <v-menu
                v-model="gruposReservas[index]"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click="getGrupo(item)"
                  >
                    mdi-account-group
                  </v-icon>
                </template>
                <v-card>
                  <v-list>
                    <v-list-item>
                      {{currentGroup}}
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </div>

            <div
              v-if="item.reserveStatus === 'COMPLETADO'"
            >
              <v-icon
                center
                class="mr-2"
                @click="openHealthSituationDialog(true, item)"
              >
                mdi-car-brake-alert
              </v-icon>
            </div>

            <div
              v-if="item.reserveStatus === 'CREADO'"
            >
              <v-icon
                center
                class="mr-2"
                @click="handleDeleteReserva(item)"
              >
                mdi-delete
              </v-icon>
            </div>

            <div
              v-if="item.reserveStatus === 'CANCELADO'"
            >
              -
            </div>



          </div>
        </template>
        <template v-slot:item.acceso="{ item, index }">
          <v-menu
            v-model="locationLabel[index]"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >
                mdi-map-marker
              </v-icon>
            </template>
            <v-card>
              <v-list>
                <v-list-item>
                  {{item.access}}
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
        <template v-slot:item.declarationStatus="{ item }">
          <div
            v-if="item.healthSurvey === 1 && item.reserveStatus === 'CREADO'"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  center
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="handleDeclaracionSalud(item)"
                >
                  mdi-book-plus-outline
                </v-icon>
              </template>
              <span>La declaración de salud está disponible, tiene hasta las 18:00 del día de hoy para completarla</span>
            </v-tooltip>
          </div>

          <div
            v-if="item.healthSurvey === 0 && item.reserveStatus === 'CREADO'"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  center
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-clock-outline
                </v-icon>
              </template>
              <span>La declaración de salud se realiza entre las 12:00 y las 17:59 del día anterior a la reserva</span>
            </v-tooltip>
          </div>

          <div
            v-if="item.reserveStatus === 'VALIDADO' || item.reserveStatus === 'COMPLETADO'"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  center
                  class="mr-2"
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-check-bold
                </v-icon>
              </template>
              <span>Su declaración de salud fue completada</span>
            </v-tooltip>
          </div>

          <div
            v-if="item.reserveStatus === 'CANCELADO'"
          >
            <v-icon
              center
              class="mr-2"
              color="red"
            >
              mdi-close-circle
            </v-icon>
          </div>



        </template>

      </v-data-table>
    </v-card-text>
  </v-card>

</template>

<script>
import alertModal from '../../utils/alertModal';

export default {
  name: "ReserveTable",
  props:{
    openReserveDialog: Function,
    getMyReservas: Function,
    openHealthDialog: Function,
    reservas: Array,
    openHealthSituationDialog: Function,
    openReserveGroupDialog: Function,
  },
  data() {
    return {
      headers: [
        {
          text: "Sector",
          align: "start",
          value: "sector",
        },
        { text: "Edificio", value: "building" },
        { text: "Sala/Laboratorio", value: "room" },
        { text: "Piso Sala", value: "floor" },
        { text: "Tipo de sala", value: "roomType" },
        { text: "Fecha reserva", value: "reserveDate" },
        { text: "Tipo reserva", value: "reserveType" },
        { text: "Estado reserva", value: "reserveStatus" },
        { text: "Acceso", value: "acceso", sortable: false },
        { text: "Declaración de salud", value: "declarationStatus", sortable: false },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      reserveLabel: [],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
      labels: {
        'pendient': 'El '
      },
      locationLabel: [],
      openSeeGroup: false,
      currentGroup: [],
      gruposReservas: []
    }
  },
  watch: {
    reservas() {
      this.reserveLabel = new Array(this.reservas.length).fill(false);
      this.locationLabel = new Array(this.reservas.length).fill(false);
      this.gruposReservas = new Array(this.reservas.length).fill(false);
    }
  },
  methods: {
    handleDeclaracionSalud(item) {
      this.openHealthDialog(true, item);
    },
    handleDeleteReserva(item) {
      const eliminarReserva = () => this.deleteReserva(item.id);
      const alertData = {
        title: `¿Esta seguro/a de querer eliminar la reserva de la ${item.room} para la fecha ${item.reserveDate}?`,
        text: 'Se eliminará esta reserva a su listado',
        acceptFunction: eliminarReserva,
        showCancelButton: true
      }
      alertModal(alertData);
    },
    async deleteReserva(itemId) {
      try {
        const data = {
          ...this.auth,
        }
        const response = await this.axios.delete(`${process.env.VUE_APP_BASE_URL}/reserva/${itemId}`, data);
        this.getMyReservas();
      } catch (e) {
        console.log(e);
      }
    },
    async getGrupo(item) {
      this.currentGroup = [
        {
          id: 1,
          name: `${item.id} asd`
        }
      ];
      // try {
      //   const data = {
      //     ...this.auth,
      //   }
      //   const response = await this.axios.delete(`${process.env.VUE_APP_BASE_URL}/reserva/${itemId}`, data);
      //   console.log(itemId);
      //   this.getMyReservas();
      // } catch (e) {
      //   console.log(e);
      // }
    }
  }
}
</script>

<style scoped>
.actionsIcons {
  display: flex;
}

.buttons {
  margin-right: 10px;
}


</style>
