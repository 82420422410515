<template>
  <v-app-bar fixed color="#071D49" class="nav mx-btn">
    <v-app-bar-nav-icon
      @click.stop="drawer = !drawer"
      color="white"
      class="md-hidden"
    >
    </v-app-bar-nav-icon>
    <v-navigation-drawer v-model="drawer" temporary app absolute>
      <v-list flat>
        <v-list-item-group v-model="group" color="deep-orange">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                {{$session.getAll().typeUser === 'Alumno-Autorizado' ? 'Salvoconducto alumnos' : 'Salvoconducto'}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-title @click="openContacto()" >Contacto</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="resizeUp()" >Aumentar fuente</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="resizeDown()" >Disminuir fuente</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="logout()" >Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <img
      class="img-fix"
      alt="Logo universidad de Santiago de Chile"
      :src="img"
      width="100"
      height="50"
      style="margin-right:20px"
    />
    <img class="img-fix" alt="Logo Campus Seguro" :src="img2" height="50" style="margin-right:20px" />
    <v-spacer></v-spacer>

    <div class="text-center">
      <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
        <template v-slot:activator="{ on, attrs }">
          <div class="buttonsNavbar">
            <v-btn color="indigo" dark v-bind="attrs" v-on="on" class="sm-hidden mr-1">Contacto</v-btn>

            <v-btn @click="resizeDown()" dark icon title="Disminuir tamaño de letra" class="sm-hidden mr-1">
              <v-icon>mdi-format-font-size-decrease</v-icon>
            </v-btn>

            <v-btn @click="resizeUp()" dark icon title="Aumentar tamaño de letra" class="sm-hidden mr-1">
              <v-icon>mdi-format-font-size-increase</v-icon>
            </v-btn>
          </div>

        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <img width="100" :src="logo_usach2" alt="John" />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Prorrectoría</v-list-item-title>
                <v-list-item-subtitle>Contacto de Plataforma</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-icon right>mdi-email</v-icon>
              </v-list-item-action>
              <v-list-item-title>covid19@usach.cl</v-list-item-title>
            </v-list-item>


          </v-list>


          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text @click="menu = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>
    <v-btn
      aria-label="Cambiar temperatura"
      @click="obtenerTemperaturaActual()"
      text
      color="white"
      class="sm-hidden"
      v-if="this.$session.get('typeUser')=='Admin'"
    >
      Actualizar Temperatura
    </v-btn>
    <v-btn
      aria-label="Volver al menú principal"
      depressed
      :to="{name: 'menu'}"
      text
      color="white"
      class="sm-hidden"
      v-if="this.$session.get('typeUser')=='Admin'"
    >
      Menu Inicio
    </v-btn>

    <!--    <v-btn @click="resizeDown()" dark icon title="Disminuir tamaño de letra" class="sm-hidden mr-1">
      <v-icon>mdi-format-font-size-decrease</v-icon>
    </v-btn>

    <v-btn @click="resizeUp()" dark icon title="Aumentar tamaño de letra" class="sm-hidden mr-1">
      <v-icon>mdi-format-font-size-increase</v-icon>
    </v-btn>-->

    <v-menu bottom left :offset-y="offset">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" title="Opciones de Usuario" class="sm-hidden mr-1">
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-btn depressed @click="logout()" text color="black" light>Cerrar sesión</v-btn>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Actualizar temperatura sobre la cual se considerará fiebre</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  hint="Temperatura en grados Celsius, escribir con punto (Ejemplo: 36.7)"
                  outlined
                  dense
                  persistent-hint
                  v-model="temperatura.umbral"
                  label="Valor"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog=false">Cerrar</v-btn>
          <v-btn color="warning" @click="actualizarTemperatura()">enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import img from "../../assets/LOGO USACH.png";
import img2 from "../../assets/CampusSeguro Icon-2.png";
import logo_usach2 from "../../assets/logo_usach2.jpg";

import {resizeUp, resizeDown, changeFont, } from '../../utils/resizeFontFunctions';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: "Navbar",
  data() {
    return {
      logo_usach2: logo_usach2,
      menu: false,
      dialog: false,
      img: img,
      img2: img2,
      offset: true,
      drawer: false,
      group: null,
      sidebarItems: [
        {
          icon: "mdi-wifi",
          text: "Funcionarios"
        },
        {
          icon: "mdi-bluetooth",
          text: "Procesos"
        },
        {
          icon: "mdi-chart-donut",
          text: "Configuración"
        }
      ],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      },
      temperatura: {}
    };
  },
  created() {
    if (this.$session.getAll().typeUser === 'Alumno') {
      this.getUserStatus();
    }
  },
  watch: {
    group() {
      this.drawer = false;
    }
  },

  mounted() {
    const resize = localStorage.getItem('resize') || 0;
    changeFont(document.getElementsByClassName("v-main__wrap")[0], resize);
  },

  methods: {
    resizeUp,
    resizeDown,
    openContacto() {
      this.menu = true;
    },
    async getUserStatus() {
      // this.axios
      //   .get(
      //     process.env.VUE_APP_BASE_URL2 + "/temperatura/obtenerTemperatura",
      //     this.auth
      //   )
      //   .then(response => {
      //     this.temperatura = response.data;
      //     this.dialog = true;
      //   })
      //   .catch(error => {
      //     alert(error);
      //   });
      // const data = {
      //   status: 'finished',
      //   currentStep: 0
      // }
      // const currentRoute = this.$route.name;
      // console.log(currentRoute);
      // if (data.status === 'finished') {
      //   if (currentRoute === 'regAlumno') {
      //     this.$router.push({ name: "reserva" });
      //   }
      // }
      // else {
      //   if (currentRoute === 'reserva') {
      //     this.$router.push({ name: "regAlumno" });
      //   }
      // }
      return
    },
    obtenerTemperaturaActual() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL2 + "/temperatura/obtenerTemperatura",
          this.auth
        )
        .then(response => {
          this.temperatura = response.data;
          this.dialog = true;
        })
        .catch(error => {
          alert(error);
        });
    },
    actualizarTemperatura() {
      this.axios
        .put(
          process.env.VUE_APP_BASE_URL2 + "/temperatura/updateDataTemperatura",
          this.temperatura,
          this.auth
        )
        .then(response => {
          alert("Temperatura actualizada a: " + this.temperatura.umbral + "°C");
          this.dialog = false;
        })
        .catch(error => {
          alert(error);
        });
    },
    logout() {
      this.$session.destroy();
      this.$router.push("/");
    },
    // resizeUp() {
    //   var resize = this.$session.getAll().resize;
    //   this.$session.set("resize", resize + 1);
    //   function changeFont(element) {
    //     var style = window
    //       .getComputedStyle(element, null)
    //       .getPropertyValue("font-size");
    //     var fontSize = parseFloat(style);
    //     element.style.fontSize = fontSize + 2 + "px";
    //     for (var i = 0; i < element.children.length; i++) {
    //       changeFont(element.children[i]);
    //     }
    //   }
    //   changeFont(document.getElementsByTagName("body")[0]);
    // },
    colorChange() {
      function colorChange(element) {
        var style = window
          .getComputedStyle(element, null)
          .getPropertyValue("color");

        //console.log(style);
        if (
          element.style.backgroundColor == "rgb(235, 119, 4)" ||
          element.style.backgroundColor == "#071D49"
        ) {
          //console.log("entre");
          element.style.backgroundColor = "black";
        }

        for (var i = 0; i < element.children.length; i++) {
          colorChange(element.children[i]);
        }
      }
      colorChange(document.getElementsByTagName("body")[0]);
    },
    // resizeDown() {
    //   var resize = this.$session.getAll().resize;
    //   if (this.$session.getAll().resize > 0)
    //     this.$session.set("resize", resize - 1);
    //   function changeFont(element) {
    //     var style = window
    //       .getComputedStyle(element, null)
    //       .getPropertyValue("font-size");
    //     var fontSize = parseFloat(style);
    //     element.style.fontSize = fontSize - 2 + "px";
    //     for (var i = 0; i < element.children.length; i++) {
    //       changeFont(element.children[i]);
    //     }
    //   }
    //   changeFont(document.getElementsByTagName("body")[0]);
    // }
  }
};
</script>
<style>
.v-list-item-title {
  cursor: pointer;
}
.text-white {
  color: white;
}

.buttonsNavbar {
  display: flex;
}

@media only screen and (min-width: 600px) {
  .md-hidden {
    display: none !important;
  }
}
@media only screen and (max-width: 599px) {
  .sm-hidden {
    display: none !important;
  }
}
</style>
