<template>
  <v-card
  >
  <v-list-item two-line>
    <v-list-item-content>
      <v-list-item-title class="text-h5">
        Nuevo permiso
      </v-list-item-title>
      <!-- <v-list-item-subtitle>Mon, 12:30 PM, Mostly sunny</v-list-item-subtitle> -->
    </v-list-item-content>
  </v-list-item>

  <v-card-text>
    <v-row>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
            v-model="computedDateFormatted"
            label="Seleccionar fecha de "
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            @input="menu = false"
            @update:picker-date="pickerUpdate($event)"
            :allowed-dates="allowedDates"
            :max="maxDate"
            >
          </v-date-picker>
        </v-menu>

      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-btn
          depressed
          color="primary"
          @click="handlePermiso()"
        >
          Solicitar permiso
        </v-btn>
      </v-col>
    </v-row>
  </v-card-text>


  <v-list-item two-line>
    <v-list-item-content>
      <v-list-item-title class="text-h5">
        Permisos solicitados
      </v-list-item-title>
      <!-- <v-list-item-subtitle>Mon, 12:30 PM, Mostly sunny</v-list-item-subtitle> -->
    </v-list-item-content>
  </v-list-item>
  <v-card-text>
    <v-data-table
      :headers="headers"
      :items="permissions"
      elevation="0"
    >
      <template v-slot:item.actions="{ item }">
        <div>
            <v-icon
                v-if="!item.loading"
            @click="downloadPermission(item)"
            >
              mdi-file-pdf-box
            </v-icon>
          <div >
            <v-progress-circular
                v-if="item.loading"
                :size="25"
                indeterminate
                color="primary"
            ></v-progress-circular>
          </div>
        </div>

      </template>

    </v-data-table>

  </v-card-text>

    <!-- <v-card-text>
      <v-row align="center">
        <v-col
          class="text-h2"
          cols="6"
        >
          23&deg;C
        </v-col>
        <v-col cols="6">
          <v-img
            src="https://cdn.vuetifyjs.com/images/cards/sun.png"
            alt="Sunny image"
            width="92"
          ></v-img>
        </v-col>
      </v-row>
    </v-card-text> -->


  </v-card>
</template>

<script>

import alertModal from '../../utils/alertModal';

import moment from 'moment';

export default {
  name: "PermissionsTable",
  components: {

  },
  props: {

  },
  data() {
    return {
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
      headers: [
        { text: "Código", align: "start", value: "validationCode" },
        { text: "Fecha solicitud", value: "createdAt" },
        { text: "Fecha permiso", value: "accessDateAt" },
        { text: "Acciones", value: "actions" },
      ],
      permissions: [],
      menu: false,
      date: moment(),
      maxDate: '2022-01-29',
    };
  },
  created() {
    const availableDate = this.checkTomorrow();
    this.getPermisos();
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date);
    },
  },
  watch: {

  },
  updated() {

  },
  mounted() {

  },
  methods: {
    checkTomorrow() {
      const today = moment().add(1, 'days').subtract(12, 'hours');
      const day = today.day();
      do {
        today.add(1, 'days');
      } while (today.day() === 6 || today.day() === 0);
      this.date = today.format("YYYY-MM-DD");
      return today._d;
    },
    allowedDates(a) {
      return this.availableDates.includes(a);
    },
    pickerUpdate(val) {
      let totalDay = moment(val, "YYYY-MM").daysInMonth();

      let availableDates = []

      let monthNow = moment().format('M');
      let monthSelected = moment(val).format('M');
      let day;

      const startTomorrow = moment().hours() >= 12 ? 1 : 0;

      if(monthNow == monthSelected) {
        day = moment().add(0, 'days').format('D');
      }
      else {
        day = 1;
      }

      console.log(val);
      for (let i = day; i <= totalDay ; i++) {
        let date = moment().month(val.split('-')[1]-1).year(val.split('-')[0]).date(i).format("YYYY-MM-DD");
        if (moment(date).day() !== 0) {
          availableDates.push(date);
        }
      }
      this.availableDates = availableDates;
      this.allowedDates();
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`
    },
    handlePermiso() {
      const permiso = () => this.newPermission();
      const alertData = {
        title: `¿Esta seguro/a de solictar permiso para la fecha ${this.computedDateFormatted}?`,
        text: 'Se agregará este permiso a su listado',
        acceptFunction: permiso,
        showCancelButton: true
      }
      alertModal(alertData);
    },
    async newPermission() {
      try {
        const dataBody = {
          date: this.date
        }
        const {data} = await this.axios.post(`${process.env.VUE_APP_BASE_URL}/permiso-acceso`, dataBody, this.auth);
        const alertData = {
          title: `Permiso Alumno`,
          text: 'Permiso creado de forma exitosa',
          icon: 'success',
          showCancelButton: false
        }
        alertModal(alertData);
        this.getPermisos();
      } catch (e) {
        console.log(e);
        const alertData = {
          title: `Permiso Alumno`,
          text: e.response.data.message,
          icon: 'error',
          showCancelButton: false
        }
        alertModal(alertData);
      }
    },
    async getPermisos() {
      try {
        const {data} = await this.axios.get(`${process.env.VUE_APP_BASE_URL}/alumno/${this.$session.get("id")}/permiso-acceso`, this.auth);
        if(data.length > 0)
          this.permissions = [];
        data.forEach((item, index) => {
          item.loading = false;
          this.permissions.push(item);
        });
      } catch (e) {
        console.log(e);
        const alertData = {
          title: `Listado Permisos Alumno`,
          text: e.response.data.message,
          icon: 'error',
          showCancelButton: false
        }
        alertModal(alertData);
      }
    },
    downloadPermission(item) {
      console.log(item);
      item.loading = true;
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/permiso-acceso/" + item.id + "/" + item.validationCode + "/" + item.hashValidationCode + "/download",
          this.auth
        )
        .then(response => {
          var fecha = new Date();
          var a = document.createElement("a"); //Create <a>
          a.href = "data:application/pdf;base64," + response.data; //Image Base64 Goes here
          a.download =
            "permiso_" +
            item.accessDateAt +
            ".pdf"; //File name Here
          a.click(); //Downloaded file
          item.loading = false;
        })
        .catch(error => {
          item.loading = false;
          console.log(error);
          const alertData = {
            title: `Descarga Permisos Alumno`,
            text: error.response.data.message,
            icon: 'error',
            showCancelButton: false
          }
          alertModal(alertData);
        });
    }
  }
}
</script>

<style scoped>



</style>
