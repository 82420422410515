<template>
  <v-container>
    <v-tabs
      v-model="tab"
      centered
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        Reservas
      </v-tab>

      <v-tab href="#tab-2">
        QRs
      </v-tab>

    </v-tabs>

    <v-tabs-items v-model="tab">

      <v-tab-item
        key="1"
        value="tab-1"
      >
        <ReserveListAdmin />
      </v-tab-item>

      <v-tab-item
        key="2"
        value="tab-2"
      >
        <AlumnosQR />
      </v-tab-item>

    </v-tabs-items>
  </v-container>
</template>

<script>

import AlumnosQR from './AlumnosQR/AlumnosQR';
import ReserveListAdmin from './ReserveListAdmin/ReserveListAdmin';

export default {
  components: {
    AlumnosQR,
    ReserveListAdmin,
  },
  data(){
    return {
      tab: null,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }
  },

  created() {

  },

  methods: {


  }
}

</script>
