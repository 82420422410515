<template>
  <v-dialog
    v-model="healthDialog"
    persistent
    width="900"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card
    :style="{ fontSize: fontSize + 'em' }"
    >
      <v-card-title
        class="text-h5"
      >
        Declaración de Salud
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              readonly
              persistent-hint
              label="Nombre"
              outlined
              dense
              v-model="usuario.nombre"
              key="nombreUser"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              readonly
              persistent-hint
              label="Rut"
              outlined
              dense
              v-model="usuario.rut"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-container>
        <h3>
          Declaración de salud
        </h3>
      </v-container>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            1. ¿Ha estado en contacto estrecho (menos de un metro de distancia y sin mascarilla) con alguna persona con diagnóstico conocido
            de COVID-19 en los últimos 11 días? (Excluye personal de salud con uso de EPP en contexto de atención de paciente/excluye ser contacto
            de un contacto)
            <v-radio-group
              v-model="form.contactoConfirmado"
              row
              :disabled="!edit"
            >
              <v-radio
                label="Sí"
                :value="1"
              ></v-radio>
              <v-radio
                label="No"
                :value="0"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            2. ¿Ha estado en contacto estrecho (menos de un metro de distancia y sin mascarilla) con alguna persona sospechosa de COVID-19
            en espera de resultado del examen de COVID-19 en la última semana? (Excluye personal de salud con uso de EPP en contexto de atención
            de paciente)
            <v-radio-group
              v-model="form.contactoSospechoso"
              row
              :disabled="!edit"
            >
              <v-radio
                label="Sí"
                :value="1"
              ></v-radio>
              <v-radio
                label="No"
                :value="0"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>

          <v-col cols="12" sm="12" md="12">
            3. ¿Ha presentado alguno de los siguientes síntomas agudos asociados a COVID-19 en la última semana? Puede marcar más de uno:
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  Temperatura de 37,5°C o más
                  <v-radio-group
                    v-model="form.fiebre"
                    row
                    :disabled="!edit"
                  >
                    <v-radio
                      label="Sí"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      label="No"
                      :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  Pérdida brusca del olfato (anosmia)
                  <v-radio-group
                    v-model="form.anosmia"
                    row
                    :disabled="!edit"
                  >
                    <v-radio
                      label="Sí"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      label="No"
                      :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  Pérdida brusca del gusto (ageusia)
                  <v-radio-group
                    v-model="form.ageusia"
                    row
                    :disabled="!edit"
                  >
                    <v-radio
                      label="Sí"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      label="No"
                      :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

            </v-container>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            4. Marque si actualmente presenta alguno de los siguientes síntomas agudos asociados a COVID-19, puede marcar más de uno:
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  Tos
                  <v-radio-group
                    v-model="form.tos"
                    row
                    :disabled="!edit"
                  >
                    <v-radio
                      label="Sí"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      label="No"
                      :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  Escalofríos
                  <v-radio-group
                    v-model="form.escalofrios"
                    row
                    :disabled="!edit"
                  >
                    <v-radio
                      label="Sí"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      label="No"
                      :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  Cefalea o dolor de cabeza
                  <v-radio-group
                    v-model="form.cefalea"
                    row
                    :disabled="!edit"
                  >
                    <v-radio
                      label="Sí"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      label="No"
                      :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  Odinofagia (dolor de garganta al comer o tragar)
                  <v-radio-group
                    v-model="form.odinofagia"
                    row
                    :disabled="!edit"
                  >
                    <v-radio
                      label="Sí"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      label="No"
                      :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  Mialgias o dolores musculares
                  <v-radio-group
                    v-model="form.mialgias"
                    row
                    :disabled="!edit"
                  >
                    <v-radio
                      label="Sí"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      label="No"
                      :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  Diarrea o dolor abdominal
                  <v-radio-group
                    v-model="form.diarrea"
                    row
                    :disabled="!edit"
                  >
                    <v-radio
                      label="Sí"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      label="No"
                      :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  Dolor torácico (dolor al pecho)
                  <v-radio-group
                    v-model="form.dolorToracico"
                    row
                    :disabled="!edit"
                  >
                    <v-radio
                      label="Sí"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      label="No"
                      :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  Disnea o dificultad respiratoria
                  <v-radio-group
                    v-model="form.disnea"
                    row
                    :disabled="!edit"
                  >
                    <v-radio
                      label="Sí"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      label="No"
                      :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

              </v-row>


            </v-container>
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions v-if="edit">
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          depressed
          @click="closeDialog"
        >
          Cerrar
        </v-btn>
          <v-btn
            depressed
            @click="save"
            color="primary"
          >
            Emitir declaración de salud
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import alertModal from '../../utils/alertModal';
import {changeFont, } from '../../utils/resizeFontFunctions';

import moment from 'moment';

export default {
  name: "HealthDeclarationDialog",
  props: {
    openHealthDialog: Function,
    getMyReservas: Function,
    healthDialog: Boolean,
    currentItem: Object,
    edit: Boolean,
    firstMountHealth: Boolean,
    changeFirstMountHealth: Function,
  },
  data(){
    return {
      me: true,
      data: {
        sintomas: '',
        texto: ''
      },
      usuario: {},
      form: {
        contactoConfirmado: 0,
        contactoSospechoso: 0,
        fiebre: 0,
        anosmia: 0,
        ageusia: 0,
        tos: 0,
        escalofrios: 0,
        cefalea: 0,
        odinofagia: 0,
        mialgias: 0,
        diarrea: 0,
        dolorToracico: 0,
        disnea: 0,
      },
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
      fontSize: 1,
    }
  },
  created() {
    // this.getData();
    // this.me = false;
  },
  mounted() {
    // console.log('cambiandoooo');
    // this.fontSize = 2;
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
    })
  },
  updated() {
    if (this.firstMountHealth) {
      const resize = localStorage.getItem('resize') || 0;
      changeFont(document.getElementsByTagName("body")[0], resize);
      this.changeFirstMountHealth();
    }
  },
  watch: {

    imMounted() {
    },

    healthDialog() {
      // console.log('wiii');
      // this.me = this.healthDialog;
      if (this.healthDialog) {
        // Flag edit: Indica si el formulario se puede editar o no.
        // Caso 1: Alumno ingresa su declaración de salud
        // const resize = localStorage.getItem('resize') || 0;
        // changeFont(document.getElementsByTagName("body")[0], resize);
        if (this.edit) {
          //Se obtiene la data del alumno para mostrarlo en el modal (se puede obtener rut desde el token, pero nombre y apellidos no,
          // por lo que se usa la api para obtener estos datos)
          this.getData();
        }
        else {
          //Caso 2: Admin está revisando las declaraciones de salud de los alumnos
          // El usuario se obtiene de la data que se setea en currentItem (que corresponde a toda la reserva) al momento de abrir el modal, y esta
          // se muestra en los campos de nombre y rut
          this.usuario = {...this.currentItem};
          // Se obtiene la data del formulario del alumno en base a currentItem
          this.getFormData();
        }
      }
    },
  },
  computed: {
    height () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 220
        case 'sm': return 400
        case 'md': return 500
        case 'lg': return 600
        case 'xl': return 800
      }
    },
  },
  methods: {
    getData() {
      this.axios
      .get(
        `${process.env.VUE_APP_BASE_URL}/alumno/${this.$session.getAll().id}`,
        this.auth
      )
      .then(({data}) => {
        this.usuario = {...data, nombre: `${data.name} ${data.firstSurname} ${data.secondSurname}`};
      })
      .catch(error => {
        alert(error);
      });
    },

    getFormData() {

      // this.axios
      // .get(
      //   `${process.env.VUE_APP_BASE_URL}/alumno/${this.$session.getAll().id}`,
      //   this.auth
      // )
      // .then(({data}) => {
      //   this.usuario = {...data, nombre: `${data.name} ${data.firstSurname} ${data.secondSurname}`};
      // })
      // .catch(error => {
      //   alert(error);
      // });

      this.form = {
        contactoConfirmado: 0,
        contactoSospechoso: 0,
        fiebre: 1,
        anosmia: 0,
        ageusia: 1,
        tos: 1,
        escalofrios: 1,
        cefalea: 0,
        odinofagia: 1,
        mialgias: 1,
        diarrea: 1,
        dolorToracico: 1,
        disnea: 1,
      }
    },

    async save() {
      try {
        const data = {
          ...this.form,
          historialReservaId: this.currentItem.id
        }
        const response = await this.axios.post(`${process.env.VUE_APP_BASE_URL}/declaracion-salud`, data, this.auth);
        this.getMyReservas();
        const alertData = {
          title: `Declaración de Salud`,
          text: 'Declaración de salud registrada con éxito',
          icon: 'success',
          showCancelButton: false
        }
        alertModal(alertData);
        this.closeDialog();
      } catch (e) {
        console.log(e);
        const alertData = {
          title: `Declaración de Salud`,
          text: e.response.data.message,
          icon: 'error',
          showCancelButton: false
        }
        alertModal(alertData);
        }
    },
    closeDialog() {
      this.edit && this.getMyReservas();
      this.openHealthDialog(false);
    }
  }
}
</script>

<style scoped>

.textNotes {
  margin-right: 20px;
}

</style>
