<template>
  <v-dialog
    :value="healthSituationDialog"
    persistent
    width="900"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title
        class="text-h5"
      >
        Declaración de síntomas
      </v-card-title>
      <v-container class="margin">
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              readonly
              persistent-hint
              label="Nombres"
              outlined
              dense
              v-model="usuario.nombres"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              readonly
              persistent-hint
              label="Apellido Paterno"
              outlined
              dense
              v-model="usuario.apellidoP"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              readonly
              persistent-hint
              label="Apellido Materno"
              outlined
              dense
              v-model="usuario.apellidoM"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              readonly
              persistent-hint
              label="Rut"
              outlined
              dense
              v-model="usuario.rut"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              readonly
              persistent-hint
              label="Correo Institucional"
              outlined
              dense
              v-model="usuario.correoLdap"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              outlined
              name="input-7-4"
              label="Descripción de los síntomas"
              v-model="usuario.symptom"
              counter
              :rules="rules"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-left">
            <v-btn
                outlined
                depressed
                @click="$router.push('/reserva')"
                color="primary">Volver</v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
                outlined
                depressed
                @click="save"
                color="primary">Notificar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import alertModal from '../../utils/alertModal';
import {changeFont, } from '../../utils/resizeFontFunctions';

import moment from 'moment';

export default {
  name: "HealthSituationDialog",
  props: {
    openHealthSituationDialog: Function,
    getMyReservas: Function,
    healthSituationDialog: Boolean,
    currentItem: Object,
    firstMountHealthSituation: Boolean,
    changeFirstMountHealthSituation: Function,
  },
  data() {
    return {
      rules: [v => v.length <= 999 || 'Max 999 characters'],
      usuario: {
        nombres: "",
        apellidoP: "",
        apellidoM: "",
        rut: "",
        correoLdap: "",
        symptom: '',
      },
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
    }
  },
  created() {
    this.getData();
  },
  watch: {

  },
  updated() {
    if (this.firstMountHealthSituation) {
      const resize = localStorage.getItem('resize') || 0;
      changeFont(document.getElementsByTagName("body")[0], resize);
      this.changeFirstMountHealthSituation();
    }
  },
  methods: {
    getData() {
      this.axios
        .get(
          `${process.env.VUE_APP_BASE_URL}/alumno/${this.$session.getAll().id}`,
          this.auth
        )
        .then(response => {
          this.usuario.nombres = response.data.name;
          this.usuario.apellidoP = response.data.firstSurname;
          this.usuario.apellidoM = response.data.secondSurname;
          this.usuario.rut = response.data.rut;
          this.usuario.correoLdap = response.data.email
        })
        .catch(error => {
          alert(error);
        });
    },
    save(){
      this.axios
          .post(
              `${process.env.VUE_APP_BASE_URL}/symptom`,
              this.usuario, this.auth
          )
          .then(response => {
            alert("Notificacion de Síntomas enviada con éxito");
          })
          .catch(error => {
            console.log(error);
            alert(error);
          });
    },
    closeDialog() {
      this.getMyReservas();
      this.openHealthSituationDialog(false);
    }
  }
}
</script>

<style scoped>

.textNotes {
  margin-right: 20px;
}

.v-dialog {
  font-size: 1.2em;
}

</style>
