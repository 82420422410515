<template>
  <v-container>
    <DatosAlumno
      :changeStep="changeStep"
      :alumno="alumno"
      :salir="salir"
      :auth="auth"
      :changeAuth="changeAuth"
    />
    <!-- <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          step="1"
        >
          Datos
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 2"
          step="2"
        >
          Terminos y condiciones
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step
          :complete="step > 3"
          step="3"
        >
          Pase de movilidad
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step
          step="4"
          color="primary"
        >
          Resumen
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <DatosAlumno
            :changeStep="changeStep"
            :alumno="alumno"
            :salir="salir"
            :auth="auth"
            :changeAuth="changeAuth"
          />
        </v-stepper-content>

        <v-stepper-content step="2">
          <TerminosYCondiciones
            :changeStep="changeStep"
            :alumno="alumno"
            :salir="salir"
            :auth="auth"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <PaseMovilidad
            :changeStep="changeStep"
            :alumno="alumno"
            :getValidaciones="getValidaciones"
            :salir="salir"
            :auth="auth"
          />
        </v-stepper-content>
        <v-stepper-content step="4">
          <Resumen
            :changeStep="changeStep"
            :validaciones="validaciones"
            :estadoCapatacitacion="estadoCapatacitacion"
            :salir="salir"
            :userStep="userStep"
            :auth="auth"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-dialog v-model="regAlumno" persistent max-width="700px">

    </v-dialog> -->
  </v-container>
</template>

<script>
import img from "../../assets/campus.png";

import DatosAlumno from './DatosAlumno';
import TerminosYCondiciones from './TerminosYCondiciones/TerminosYCondiciones';
import PaseMovilidad from './PaseMovilidad';
import Resumen from './Resumen';

import { mapActions, mapGetters } from 'vuex';
import alertModal from "@/utils/alertModal";

export default {
  components: {
    DatosAlumno,
    TerminosYCondiciones,
    PaseMovilidad,
    Resumen,
  },
  data() {

    return {
      alumno: {},
      regAlumno: false,
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      },
      validaciones: [],
      estadoCapatacitacion: 0,
      step: 1,
      userStep: 1,
    };
  },

  computed: {
    ...mapGetters({
      currentStep: ["stepper/currentStep"],
    }),
  },

  created() {
    this.getData();
  },
  watch: {

  },
  methods: {
    ...mapActions('stepper', [
      'setCurrentStep',
    ]),
    async getData() {
      const idUser = this.$session.getAll().id;
      if (Number.isInteger(idUser) && idUser > 0) {
        await this.getCurrentStep(idUser);
      //  await this.getEstadoCapacitacion();
      }
      else {
        await this.getDataAlumno();
        this.step = 1;
        this.userStep = 1;
      }
      // console.log(alumnoData);
    },
    async getCurrentStep(idUser) {
      try {
        const {data} = await this.axios.get(`${process.env.VUE_APP_BASE_URL}/alumno/${idUser}/etapa-registro`, this.auth);
        if (data === 6) {
          const userFinished = () => {
            this.salir()
          }
          const alertData = {
            title: `Alumno autorizado`,
            text: 'Ha completado todos los pasos para ingresar al sistema, por favor vuelva a ingresar al sistema.',
            showCancelButton: false,
            icon: 'success',
            onClose: userFinished
          }
          alertModal(alertData);
        }
        else {
          this.step = data > 3 ? 4 : data;
          this.userStep = data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getDataAlumno() {
      try {
        const {data} = await this.axios.get(`${process.env.VUE_APP_BASE_URL}/alumno/api/informacion/show`, this.auth);
        this.alumno = {...data, name: data.nombres, firstSurname: data.apellidoPaterno,
          secondSurname: data.apellidoMaterno, career: data.nombreCarrera, phone: data.telefono, email: data.correoUsach,
          alternateEmail: data.correoPersonal, address: data.direccion,
          rut: `${data.rut}-${data.dv}`
        };
        return data;
      } catch (e) {
        console.log(e);
        const alertData = {
          title: `Información de alumno`,
          text: 'Su información de Alumno no ha sido encontrada.',
          showCancelButton: false,
          icon: 'error',
        }
        alertModal(alertData);
        this.$router.push("/reserva/login");
      }
    },
    changeStep() {
      this.step = this.step + 1;
      this.userStep = this.userStep + 1;
    },

    salir() {
      this.$session.destroy();
      this.$router.push("/reserva/login");
    },

    getValidaciones(){
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL + "/alumno/getValidaciones?id="+
          this.$session.get("id"),
          this.auth
        )
        .then(response => {
          this.validaciones = response.data;
        })
        .catch(error => {
          alert(error);
        });
    },
    getEstadoCapacitacion(){
      this.axios
          .get(
              process.env.VUE_APP_BASE_URL + "/alumno/training-status",
              this.auth
          )
          .then(response => {
            this.estadoCapatacitacion = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    changeAuth(token) {
      this.auth = {
        headers: { Authorization: "Bearer " + token }
      }
    }
  }
};
</script>

<style scoped>
.parallax {
  z-index: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.botton-final {
  margin-bottom: 50px;
}

@media only screen and (min-width: 900px) {
  .hidden1 {
    display: none !important;
  }
}
@media only screen and (max-width: 899px) {
  .hidden2 {
    display: none !important;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}
</style>
