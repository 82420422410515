<template>
  <div>
    <v-row>
      <v-col cols="6">
        <h1>Listado de usuarios</h1>
      </v-col>
    </v-row>
    <v-spacer />
    <TableFuncionarios
      :cargando = "cargando"
      @send-data = "receiveData"
      :headers = "headers"
      :nameTitle="'Listado de usuarios registrados'"
      :items = "items"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import TableFuncionarios from "@/components/TableCrud.vue";
export default {
  name: "Funcionarios",
  components: {
    TableFuncionarios
  },
  data() {
    return {
      cargando: true,
      loading: "cargando",
      itemType: "Proceso",
      headers: [
        { text: "Nombre", value: "nombres" },
        { text: "Paterno", value: "apellidoP" },
        { text: "Materno", value: "apellidoM" },
        { text: "Rut", value: "rut" },
        { text: "Correo contacto", value: "correoLdap" },
        { text: "Fecha último PCR", value: "updatedDatePcr"},
        { text: "Acciones", value: "acciones", sortable: false }
      ],
      items: [],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      }
    };
  },

  created() {
    this.getUsers()
  },
  methods: {
    receiveData(event) {
      //console.log(event);
      this.getUsers()
    },
    getUsers(){
      this.axios
        .get(process.env.VUE_APP_BASE_URL + "/usuario/getUsuarios", this.auth)
        .then(response => {
          this.items = response.data;
          this.cargando = false;
        })
        .catch(error => {
          alert(error);
          this.cargando = false;
        });
    },
    cambiarBoton() {
      this.vrae = !this.vrae;
      this.vrae
        ? (this.nombreBoton = "Ver Listado Inquietudes Protocolo Académico")
        : (this.nombreBoton = "Ver Listado Inquietudes VRAE");
    }
  }
};
</script>

<style>
.c-orange {
  color: rgb(255, 153, 0);
}
</style>