<template>
  <div>
    <v-row>
      <v-col cols="6">
        <h1>Salvoconductos Excepcionales</h1>
      </v-col>
    </v-row>
    <v-spacer />
    <TableFuncionarios
      @send-data="receiveData"
      :headers="headersGral"
      :nameTitle="nameTitle"
      :items="items"
      :activos="activo"
      :cargando="cargando"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import TableFuncionarios from "../components/ListaSalvoEx";
export default {
  name: "Funcionarios",
  components: {
    TableFuncionarios,
  },
  data() {
    return {
      activo: false,
      cargando: true,
      nameTitle: "",
      loading: "cargando",
      itemType: "Proceso",
      headersGral: "",
      headers: [
        { text: "Nombre", value: "nombres" },
        { text: "Paterno", value: "apellidoP" },
        { text: "Materno", value: "apellidoM" },
        { text: "Rut", value: "rut" },
        { text: "Fecha de Creación", value: "createdAt" },
        { text: "Fecha petición Ingreso", value: "fechaSolicitud" },
        { text: "Acciones", value: "acciones", sortable: false },
      ],
      headers2: [
        { text: "Nombre", value: "nombres" },
        { text: "Paterno", value: "apellidoP" },
        { text: "Materno", value: "apellidoM" },
        { text: "Rut", value: "rut" },
        { text: "Fecha de Creación", value: "createdAt" },
        { text: "Fecha petición Ingreso", value: "fechaSolicitud" },
      ],

      items: [],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
    };
  },

  created() {
    this.getSalvosEx();
  },
  methods: {
    receiveData(event) {
      event == 1 ? this.getSalvosEx() : this.getSalvosExAceptado();
    },
    getSalvosEx() {
      this.nameTitle = "Listado salvoconductos para aprobar";
      this.headersGral = this.headers;
      this.activo = false;
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/salvoconductoexcepcional/getAllByEstado?estado=0",
          this.auth
        )
        .then((response) => {
          this.items = response.data;
          this.cargando = false;
        })
        .catch((error) => {
          alert(error);
          this.cargando = false;
        });
    },

    getSalvosExAceptado() {
      this.nameTitle = "Listado salvoconductos activos";
      this.headersGral = this.headers2;
      this.activo = true;
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/salvoconductoexcepcional/getAllActivos",
          this.auth
        )
        .then((response) => {
          this.items = response.data;
          this.cargando = false;
        })
        .catch((error) => {
          alert(error);
          this.cargando = false;
        });
    },
  },
};
</script>

<style>
.c-orange {
  color: rgb(255, 153, 0);
}
</style>