<template>
  <v-container>
    <ReserveIndividualDialog
      :dialog = "dialog"
      :openReserveDialog="openReserveDialog"
      :getMyReservas="getMyReservas"
      :zonas="zonas"
      :firstMountReserve="firstMountReserve"
      :changeFirstMountReserve="changeFirstMountReserve"
    />
<!--    <ReserveGroupDialog-->
<!--      :groupDialog = "groupDialog"-->
<!--      :openReserveGroupDialog="openReserveGroupDialog"-->
<!--      :getMyReservas="getMyReservas"-->
<!--      :zonas="zonas"-->
<!--      :firstMountReserveGroup="firstMountReserveGroup"-->
<!--      :changeFirstMountReserveGroup="changeFirstMountReserveGroup"-->
<!--      -->
<!--    />-->
    <ReserveTable
      :openReserveDialog="openReserveDialog"
      :openHealthDialog="openHealthDialog"
      :openHealthSituationDialog="openHealthSituationDialog"
      :reservas="reservas"
      :getMyReservas="getMyReservas"
      :openReserveGroupDialog="openReserveGroupDialog"
    />
    <UsachMapDialog
      :openUsachMapDialog="openUsachMapDialog"
      :dialogMap="dialogMap"
      :firstMountMap="firstMountMap"
      :changeFirstMountMap="changeFirstMountMap"
    />
    <HealthDeclarationDialog
      :openHealthDialog="openHealthDialog"
      :healthDialog="healthDialog"
      :getMyReservas="getMyReservas"
      :currentItem="currentItem"
      :edit="true"
      :firstMountHealth="firstMountHealth"
      :changeFirstMountHealth="changeFirstMountHealth"
    />
    <HealthSituationDialog
      :openHealthSituationDialog="openHealthSituationDialog"
      :healthSituationDialog="healthSituationDialog"
      :getMyReservas="getMyReservas"
      :currentItem="currentItem"
      :firstMountHealthSituation="firstMountHealthSituation"
      :changeFirstMountHealthSituation="changeFirstMountHealthSituation"
    />
    <TerminosYCondicionesDialog
      :tycDialog="tycDialog"
      :openTycDialog="openTycDialog"
      :firstMountTyc="firstMountTyc"
      :changeFirstMountTyc="changeFirstMountTyc"
    />
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="12" align="center">
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="openUsachMapDialog(true)"
          >
            Ver Sectores
          </v-btn>
        </v-col>
        <!-- <v-col cols="12" sm="6" md="6" align="center">
          <v-btn
            color="red"
            dark
            class="mb-2"
            @click="goNotificarSintomas"
          >
            Informar situación sanitaria
          </v-btn>
        </v-col> -->
      </v-row>
    </v-container>
    <v-container>
      IMPORTANTE: El no realizar la declaración de salud inhabilitará su reserva.
      <br/>
      Información necesaria:
      <v-card-text>
        Tipos de sala:
        <ul>
          <li type="disc">Silencio: En estas salas debes permanecer en silencio.</li>
          <li type="disc">Conexión: En estas salas puedes conversar con las demás personas presentes.</li>
        </ul>
        Horario de uso de las salas: De lunes a viernes de 7:30 a 19:30 hrs.
      </v-card-text>
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="openTycDialog(true)"
      >
        Ver términos y condiciones
      </v-btn>
    </v-container>

  </v-container>
</template>
<script>
import ReserveTable from './ReserveTable'
import ReserveIndividualDialog from "./ReserveIndividualDialog";
import ReserveGroupDialog from './ReserveGroupDialog';
import UsachMapDialog from './UsachMapDialog';
import HealthDeclarationDialog from './HealthDeclarationDialog';
import TerminosYCondicionesDialog from './TerminosYCondicionesDialog';
import HealthSituationDialog from './HealthSituationDialog';

import {changeFont, } from '../../utils/resizeFontFunctions';

export default {
  name: "Reserve",
  components: {
    ReserveTable,
    ReserveIndividualDialog,
    UsachMapDialog,
    HealthDeclarationDialog,
    TerminosYCondicionesDialog,
    HealthSituationDialog,
    ReserveGroupDialog,
  },
  data() {
    return {
      dialog: false,
      dialogMap: false,
      healthDialog: false,
      tycDialog: false,
      healthSituationDialog: false,
      groupDialog: false,
      firstMountHealth: true,
      firstMountHealthSituation: true,
      firstMountReserve: true,
      firstMountTyc: true,
      firstMountMap: true,
      firstMountReserveGroup: true,
      reservas: [],
      zonas: [
        {
          id: -1,
          descripcion: 'Ver todos',
          edificios: []
        }
      ],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
      currentItem: {},
      changedReservas: 0,
      prevChangedReservas: 0,
    };
  },
  created(){
    // this.dialog = true
    // this.dialogMap = true;
    // this.healthDialog = true;
    // this.tycDialog = true;
    // this.healthSituationDialog = true;
    this.getZonasAndEdificios();
    this.getMyReservas();
  },
  computed: {

  },
  watch: {
    // dialogMap() {
    //   console.log(this.dialogMap);
    // }
  },
  updated() {
    if (this.changedReservas !== this.prevChangedReservas) {
      const resize = localStorage.getItem('resize') || 0;
      changeFont(document.getElementsByTagName("body")[0], resize);
      this.prevChangedReservas = this.prevChangedReservas + 1;
    }
  },
  mounted() {
    // this.dialog = false;
    // this.dialogMap = false;
    // this.healthDialog = false;
    // this.tycDialog = false;
    // this.healthSituationDialog = false;
  },
  methods: {
    async getZonasAndEdificios() {
      try {
        // const response = await this.axios.get(process.env.VUE_APP_BASE_URL+`/cupos/salas?fecha=${this.date.replaceAll('-', '/')}`, this.auth);
        // // console.log(data);
        // console.log(response);
        // this.items = response.data;
        const defaultZona = [{
          id: -1,
          descripcion: 'Ver todos',
          edificios: []
        }];
        this.zonas = [
          ...defaultZona,
        ];

      } catch (e) {
        console.log(e);
      }
    },
    async getMyReservas() {
      try {
        //Descomentar cuando este lista la api
        const response = await this.axios.get(`${process.env.VUE_APP_BASE_URL}/reserva/sala/individual`, this.auth);
        this.reservas = response.data;
        this.changedReservas = this.changedReservas + 1;
        // console.log('get reservas');
        // this.reservas = [
        //   {
        //     id: 1,
        //     zona: `zona ${this.reservas.length}`,
        //     edificio: `edificio ${this.reservas.length}`,
        //     sala: `sala ${this.reservas.length}`,
        //     declarationStatus: 'created'
        //   },
        //   {
        //     id: 3,
        //     zona: `zona ${this.reservas.length}`,
        //     edificio: `edificio ${this.reservas.length}`,
        //     sala: `sala ${this.reservas.length}`,
        //     declarationStatus: 'validated'
        //   },
        //   {
        //     id: 2,
        //     zona: `zona ${this.reservas.length}`,
        //     edificio: `edificio ${this.reservas.length}`,
        //     sala: `sala ${this.reservas.length}`,
        //     declarationStatus: 'canceled'
        //   },
        //   {
        //     id: 4,
        //     zona: `zona ${this.reservas.length}`,
        //     edificio: `edificio ${this.reservas.length}`,
        //     sala: `sala ${this.reservas.length}`,
        //     declarationStatus: 'completed'
        //   },
        //   {
        //     id: 5,
        //     zona: `zona ${this.reservas.length}`,
        //     edificio: `edificio ${this.reservas.length}`,
        //     sala: `sala ${this.reservas.length}`,
        //     declarationStatus: 'inattentive'
        //   },
        //   {
        //     id: 6,
        //     zona: `zona ${this.reservas.length}`,
        //     edificio: `edificio ${this.reservas.length}`,
        //     sala: `sala ${this.reservas.length}`,
        //     declarationStatus: 'pending'
        //   },
        //   {
        //     id: 7,
        //     zona: `zona ${this.reservas.length}`,
        //     edificio: `edificio ${this.reservas.length}`,
        //     sala: `sala ${this.reservas.length}`,
        //     declarationStatus: 'available'
        //   },
        // ];

      } catch (e) {
        console.log(e);
      }
    },
    openReserveDialog(state) {
      this.dialog = state;
    },
    openReserveGroupDialog(state) {
      this.groupDialog = state;
    },
    openUsachMapDialog(state) {
      this.dialogMap = state;
    },
    openHealthDialog(state, item) {
      this.healthDialog = state;
      this.currentItem = item;
    },
    openTycDialog(state) {
      this.tycDialog = state;
    },
    openHealthSituationDialog(state, item) {
      this.healthSituationDialog = state;
      this.currentItem = item;
    },
    changeFirstMountHealth() {
      this.firstMountHealth = false;
    },
    changeFirstMountHealthSituation() {
      this.firstMountHealthSituation = false;
    },
    changeFirstMountReserve() {
      this.firstMountReserve = false;
    },
    changeFirstMountTyc() {
      this.firstMountTyc = false;
    },
    changeFirstMountMap() {
      this.firstMountMap = false;
    },
    changeFirstMountReserveGroup() {
      this.firstMountReserveGroup = false;
    },
    goNotificarSintomas() {
      this.$router.push({path: '/notificacionSintomas'});
    }
  },
};
</script>
<style scoped>

</style>
