<template>
  <div>
    <h3>
      Estoy de acuerdo con las siguientes condiciones:
    </h3>
    <v-card-text>
      <ol>
        <li value=1>
          Realizar el Curso 1 – Capacitación Campus Seguro en
          <a
            href="http://campusseguro.e-ciiet.cl/moodle/"
            class="text-decoration-none"
            target="_blank"
          >
            http://campusseguro.e-ciiet.cl/moodle/
          </a>
          con mi correo USACH.
        </li>
        <li value=2>
          Cumplir con los protocolos para funcionamiento en el campus y las recomendaciones específicas.
        </li>
        <li value=3>
          Usar exclusivamente el permiso de acceso para las labores a las que se me ha citado y moverme por los lugares asignados.
        </li>
        <li value=4>
          Las suplantaciones de identidad y otras actitudes reñidas con el uso inadecuado del salvoconducto pueden conllevar procedimientos administrativos.
        </li>
        <li value=5>
          Es responsabilidad de cada usuario revisar y cautelar el buen uso del documento entregado.
        </li>
        <li value=6>
          No se deben realizar cambios a la ventilación prevista de los edificios (no se deben cerrar puertas ni ventanas).
        </li>
        <li value=7>
          Toda persona debe portar su cédula de identidad y credencial universitaria. Estos, en conjunto con el salvoconducto, serán los únicos
          documentos empleados para reconocer la identidad de las personas.
        </li>
        <li value=8>
          Toda persona que ingrese al campus debe identificarse cuantas veces sea necesario, no pudiéndose negar a mostrar la
          cédula/credencial/salvoconducto a los/as funcionarios/as de la universidad.
        </li>
        <li value=9>
          Antes de venir al campus y/o salir de la casa, seguiré las recomendaciones contenidas en las directrices generales, que
          han sido informadas oportunamente y se encuentran a disposición de toda la comunidad en
          <a
              href="https://campus.usach.cl"
              class="text-decoration-none"
              target="_blank"
          >
            https://campus.usach.cl
          </a>
          y Redes Sociales.
        </li>
        <li value=10>
          Estoy de acuerdo en permitir la consulta de la información de mi pase de movilidad al personal autorizado de la Universidad.
        </li>
      </ol>
    </v-card-text>
    <v-card-text>
        <h3>También estoy en conocimiento que dentro del campus debo:</h3>
    </v-card-text>
    <v-card-text>
      <ul>
        <li type="disc">Usar mascarilla en todo momento y llevar al menos una mascarilla de recambio.</li>
        <li type="disc">Seguir las indicaciones de acceso (Protocolo de acceso).</li>
        <li type="disc">Registrar mi ingreso en los tótems de control de identidad, temperatura y acceso.</li>
        <li type="disc">Portar en todo momento mi cédula de identidad, credencial universitaria y salvoconducto.</li>
        <li type="disc">Mantener una distancia permanente de al menos 1 mt. (aproximadamente un brazo de distancia) con otras personas.</li>
        <li type="disc">Saludar a distancia.</li>
        <li type="disc">Lavar las manos frecuentemente con agua y jabón (en lo posible, cada dos horas y siempre cuando llegue al campus).
          En caso de no poder hacerlo, usar alcohol gel, el cual se dispondrá en diversos lugares del campus.
        </li>
        <li type="disc">Seguir las indicaciones específicas para el desarrollo de las distintas actividades.</li>
        <li type="disc">
          Respetar el aforo máximo estipulado para los distintos espacios comunes (baños públicos, oficinas, salas de reuniones, etc.).
        </li>
        <li type="disc">Seguir las indicaciones de los monitores.</li>
      </ul>
      <br>
      <h3>
        Ante cualquier duda, estoy en conocimiento de que puedo dirigir mis consultas al e-mail destinado para tales efectos covid19@usach.cl
      </h3>
    </v-card-text>
  </div>
</template>

<script>

export default {
  data() {

    return {

    };
  },

  computed: {},

  created() {

  },
  watch: {

  },
  methods: {

  }
};
</script>
