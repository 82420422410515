import axios from 'axios';

const state = {
  currentStep: 0,
}

const getters = {
  currentStep: state => state.currentStep,
}

const actions = {
  setCurrentStep: async (context, newStep) => {
    context.commit('GET_CENTROS_COSTO', newStep);
  },
}

const mutations = {
  GET_CENTROS_COSTO: (state, step) => {
    state.currentStep = step;
  },
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
}
