<template>
  <v-container>
    <h1>Visualizador de logs</h1>
    <LogViewer
    />
  </v-container>
</template>

<script>
import LogViewer from '../components/LogViewer/LogViewer'
// import AlumnosPermissionsAdmin from '../components/AlumnosPermissionsAdmin/AlumnosPermissionsAdmin'
export default {
  name: "LogViewerView",
  components: {
    LogViewer
  },
  data(){
    return {

    }
  }

}
</script>

<style scoped>

</style>
