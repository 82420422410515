<template>
  <v-container class="bodyLogin" fluid>
    <LoginNavbar
      :backgroundImage="'USACH_2'"
    />
    <v-card class="topCard">
      <v-row>
        <v-col lg="4" md="4" sm="8" class="bannerLogo">
          <img width="90%" height="90%" :src="logo_usach2" />
        </v-col>
        <v-col lg="8" md="8" sm="12">
          <v-list>
            <v-list-item>

              <v-list-item-content>
                <v-btn
                  color="warning"
                  @click="changeLogin()"
                  :x-small="buttonSize"
                >
                  Ir a permiso ingreso estudiante
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>

        </v-col>
      </v-row>

    </v-card>
    <v-row align="center" justify="center" class="loginSector">
      <v-col cols="12" sm="8" md="5">
        <v-card class="elevation-12">
          <v-toolbar color="#071D49" dark flat>
            <v-toolbar-title>
              Acceso a Campus Seguro
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form id="formVrae" v-model="valid" :lazy-validation="lazy">
              <div v-if="loginType == 1">
                <v-select
                  return-object
                  prepend-icon="mdi-library"
                  outlined
                  dense
                  item-text="name"
                  :items="opcionesTipo"
                  persistent-hint
                  label="Tipo de usuario"
                  v-model="tipo"
                  :rules="requiredRules"
                  v-on:keyup.enter="login"
                ></v-select>
              </div>
              <v-text-field
                dense
                outlined
                label="Nombre de usuario (correo Usach sin extensión)"
                name="login"
                prepend-icon="mdi-account"
                type="text"
                v-model="user"
                :rules="requiredRules"
                v-on:keyup.enter="login"
              ></v-text-field>
              <div v-if="loginType == 1">
                <v-select
                  return-object
                  prepend-icon="mdi-mail"
                  outlined
                  dense
                  :items="opcionesDominio"
                  persistent-hint
                  label="Dominio de correo"
                  v-model="dominio"
                  :rules="requiredRules"
                  v-on:keyup.enter="login"
                ></v-select>
              </div>
              <v-text-field
                outlined
                dense
                id="password"
                label="Contraseña de correo"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                v-model="pass"
                :rules="requiredRules"
                v-on:keyup.enter="login"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              :disabled="!valid"
              :loading="loading"
              @click="login()"
            >Ingresar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import img from "../../assets/USACH_2.png";
import img1 from "../../assets/LOGO USACH.png";
import img2 from "../../assets/CampusSeguro Icon-2.png";
import logo_usach2 from "../../assets/logo_usach2.jpg";

import LoginNavbar from './LoginNavbar/LoginNavbar';

import alertModal from '../../utils/alertModal';

import jwtDecode from "jwt-decode";

export default {
  name: 'Login',
  components: {
    LoginNavbar,
  },
  data() {
    return {
      valid: false,
      requiredRules: [v => !!v || "Este campo es requerido"],
      opcionesDominio: ["@segic.usach.cl", "@usach.cl"],
      logo_usach2: logo_usach2,
      dominio: "@usach.cl",
      lazy: false,
      menu: false,
      loading: false,
      img1: img1,
      img2: img2,
      img: img,
      tipo: {
        name: "Funcionario/Académico/Estudiante",
        value: 1
      },
      opcionesTipo: [
        {
          name: "Funcionario/Académico/Estudiante",
          value: 1
        },
        {
          name: "Administrador",
          value: 2
        }
      ],
      user: "",
      pass: "",
      nameTitle: "Reserva sala de estudio",
      loginType: 1,
    };
  },

  computed: {
    buttonSize() {
      return this.$vuetify.breakpoint.xs;
    }
  },

  methods: {
    login() {
      var sha1 = require("sha1");
      //console.log(this.mail)
      this.loading = true;
      const user = {
        usuario: this.user,
        tipo: this.tipo.value,
        dominio: this.dominio,
        password: sha1(this.pass)
      };
      //console.log(user);
      //console.log(user);

      this.axios
        .post(process.env.VUE_APP_BASE_URL + "/login", user)
        .then(response => {
          //console.log(response.data);
          //console.log('haciendo la mutación')
          this.$session.start();
          this.$session.set("jwt", "Bearer " + response.data);
          //console.log(response.data);
          var Decode = jwtDecode(response.data);
          this.$session.set("id", Decode.identificador);
          this.$session.set("typeUser", Decode.authorities[0]);
          this.$session.set("email", Decode.email);
          if (Decode.authorities[0] == "Admin") {
            this.$router.push({ name: "menu" });
          } else if (Decode.authorities[0] == "Funcionario") {
            this.$router.push({ name: "formulario" });
          } else if (Decode.authorities[0] == "Alumno") {
            this.$router.push({ name: "regAlumno" });
          }
          /*
          TODO:Por alguna razón no funca
          this.showAlert("success", "ingreso correcto");
          this.$session.set("userType", Decode.userType); */

          //console.log(Decode.userType);

          this.loading = false;
        })
        .catch(error => {
          //console.error(error.response.data);
          this.loading = false;
          const title = `${error.response.data.statusCode}: ${error.response.data.title}`;
          const alertData = {
            title: title,
            text: error.response?.data?.message,
            showCancelButton: false,
            icon: 'error',
          }
          alertModal(alertData);
          // alert(error.response.data);
        });
    },
    async changeLogin(){
      try {
        await this.$router.push({path: '/reserva/login'});

      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
<style scoped>
.text-center {
  margin-left: 3px !important;
}

.bannerLogo {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.topCard {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 3%;
  width: auto;
  max-width: 40%;
  height: 20%;
  margin-top: 7%;
  margin-bottom: 5%;
}

@media (max-width: 768px) {
  .topCard {
    padding: 0;
    margin-bottom: 15%;
  }
}

@media only screen and (max-width: 600px) {
  .topCard {
    margin-bottom: 15%;
    margin-top: 20%;
    font-size: 10px;
    max-width: 100%;
  }
}

.bodyLogin {
  flex-direction: column;
  justify-content: space-evenly;
}

.parallax {
  position: fixed;
  z-index: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  filter: blur(0.3rem);
}
</style>
