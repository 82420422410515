<template>
  <v-data-table
    :loading="cargando"
    id="tableInquitudes"
    item-key="nombre"
    :fixed-header="fixed"
    :height="height"
    :headers="headers"
    :items="items"
    :items-per-page="10"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{ nameTitle }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-row>
          <v-col cols="3">
            <v-btn
              :loading="waitDownload"
              color="warning"
              @click="downloadExcel()"
              light
              >Exportar DB</v-btn>
          </v-col>
          <v-col cols="9">
            <v-text-field
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              hide-details
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="850px">
          <v-card>
            <v-card-title>
              <span class="headline">Editar Sectores</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      :value="
                        editedItem.nombres +
                        ' ' +
                        editedItem.apellidoP +
                        ' ' +
                        editedItem.apellidoM
                      "
                      label="Nombre del funcionario"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-expansion-panels v-model="panel" multiple>
                      <v-expansion-panel>
                        <v-expansion-panel-header color="blue"
                          >Agregar Sector</v-expansion-panel-header
                        >
                        <v-expansion-panel-content>
                          <v-col cols="12">
                            <v-select
                              return-object
                              outlined
                              dense
                              item-text="name"
                              :items="opcionesTipo"
                              label="Sectores del Campus"
                              v-model="tipo"
                              @change="buscaEdificios"
                            ></v-select>
                          </v-col>
                          <v-col cols="12">
                            <v-select
                              return-object
                              outlined
                              dense
                              item-text="edificio"
                              :items="edificios"
                              label="Edificios por Sector"
                              v-model="edificio"
                            ></v-select>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              outlined
                              dense
                              v-model="oficina"
                              label="Oficina o departamento"
                            ></v-text-field>
                          </v-col>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="saveZonas(editedItem.id)"
                            >Agregar Sector</v-btn
                          >
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel>
                        <v-expansion-panel-header color="orange"
                          >Ver sectores de usuario</v-expansion-panel-header
                        >
                        <v-expansion-panel-content>
                          <v-simple-table dense fixed-header height="300px">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Sector</th>
                                  <th class="text-left">Edificio</th>
                                  <th class="text-left">
                                    Dependencia/Laboratorio
                                  </th>
                                  <th class="text-left">Acción</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, index) in zonas" :key="index">
                                  <td>{{ item.sector }}</td>
                                  <td>{{ item.edificio }}</td>
                                  <td>{{ item.oficina }}</td>
                                  <td>
                                    <v-icon
                                      color="warning"
                                      class="mr-2"
                                      title="Editar Zonas"
                                      @click="deleteZona(item.id)"
                                      >mdi-delete</v-icon
                                    >
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close()">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog2" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline">Editar permisos de usuario</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      label="Nombre del funcionario"
                      :value="
                        editedItem.nombres +
                        ' ' +
                        editedItem.apellidoP +
                        ' ' +
                        editedItem.apellidoM
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-switch
                      v-model="editedItem.habilitadoComite"
                      :label="`Habilitado por comité`"
                      @change="updateUsuario(editedItem.id, 1)"
                    ></v-switch>
                  </v-col>
                  <!--
                  <v-col cols="6">
                    <v-switch
                      v-model="editedItem.habilitadoCapacitacion"
                      :label="`Habilitado por capacitación`"
                      @change="updateUsuario(editedItem.id, 3)"
                    ></v-switch>
                  </v-col>*/
-->
                  <v-col cols="6">
                    <v-switch
                      v-model="editedItem.cuarentena"
                      :label="`Persona sin cuarentena`"
                      @change="updateUsuario(editedItem.id, 2)"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog2 = false"
                >Cerrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog3" width="500">
          <v-card>
            <v-card-title class="headline grey lighten-2"
              >Eliminar Usuario</v-card-title
            >

            <v-card-text class="text-justify"
              >¿Está seguro que desea eliminar al usuario
              {{ editedItem.nombres }} {{ editedItem.apellidoP }}
              {{ editedItem.apellidoM }} con RUN
              {{ editedItem.rut }}?</v-card-text
            >

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn color="red" text @click="dialog3 = false">Cerrar</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="green" text @click="deleteUsuario(editedItem.id)"
                >Aceptar y Enviar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog4" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline">Actualizar PCR</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      label="Nombre del funcionario"
                      :value="
                        editedItem.nombres +
                        ' ' +
                        editedItem.apellidoP +
                        ' ' +
                        editedItem.apellidoM
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      readonly
                      outlined
                      dense
                      persistent-hint
                      label="Fecha último PCR"
                      :value="cambiaPcr(editedItem)"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" sm="12" md="6">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="desde"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          v-model="desdeFormatted"
                          label="Selecciona nueva fecha de PCR"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="desde"
                        min="2016-06-15"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false"
                          >Cancelar</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(desde)"
                          >Seleccionar</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog4 = false"
                >Cerrar</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="actualizaPcr(editedItem)"
                >Actualizar fecha</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>

      <v-toolbar>
        <v-col>
          <v-btn
            :loading="isSelecting"
            color="warning"
            @click="onButtonClick(1)"
            light
            >Carga comité</v-btn
          >
        </v-col>
        <!--<v-col>
          <v-btn
            :loading="isSelecting3"
            color="warning"
            @click="onButtonClick(3)"
            light
            >Carga capacitación</v-btn
          >
        </v-col>-->

        <v-col>
          <v-btn
            :loading="isSelecting4"
            color="warning"
            @click="onButtonClick(4)"
            light
            >Carga Personal</v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            :loading="isSelecting5"
            color="warning"
            @click="onButtonClick(5)"
            light
            >Carga Pcr</v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            :loading="isSelecting2"
            color="warning"
            @click="onButtonClick(2)"
            light
            >Carga Extranjeros</v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            :loading="isSelecting6"
            color="warning"
            @click="onButtonClick(6)"
            light
            >Desact. masiva</v-btn
          >
        </v-col>
        <v-row>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            @change="submitFile"
          />
          <input
            ref="uploader2"
            class="d-none"
            type="file"
            @change="submitFile"
          />
          <input
            ref="uploader3"
            class="d-none"
            type="file"
            @change="submitFile"
          />
          <input
            ref="uploader4"
            class="d-none"
            type="file"
            @change="submitFile"
          />
          <input
            ref="uploader5"
            class="d-none"
            type="file"
            @change="submitFile"
          />
          <input
            ref="uploader6"
            class="d-none"
            type="file"
            @change="submitFile"
          />
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:item.correoLdap="{ item }">{{ sinInfo(item) }}</template>
    <template v-slot:item.updatedDatePcr="{ item }">{{
      cambiaPcr(item)
    }}</template>

    <template v-slot:item.acciones="{ item }">
      <v-icon
        color="#071D49"
        class="mr-2"
        title="Editar Sectores"
        @click="showDetailItem(item)"
        >mdi-eye</v-icon
      >
      <v-icon
        color="#071D49"
        class="mr-2"
        title="Editar Permisos"
        @click="showDetailItemGeneral(item, 2)"
        >mdi-book</v-icon
      >
      <v-icon
        color="#071D49"
        class="mr-2"
        title="Editar Permisos"
        @click="showDetailItemGeneral(item, 4)"
        >mdi-calendar</v-icon
      >
      <v-icon
        color="#071D49"
        class="mr-2"
        title="Eliminar Usuario"
        @click="showDetailItemGeneral(item, 3)"
        >mdi-delete</v-icon
      >
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    cargando: Boolean,
    headers: Array,
    items: Array,
    nameTitle: String,
  },

  data() {
    return {
      desdeFormatted: "",
      modal: false,
      desde: "",
      dialog3: false,
      dialog4: false,
      waitDownload: false,
      isSelecting4: false,
      isSelecting3: false,
      isSelecting: false,
      isSelecting2: false,
      isSelecting5: false,
      isSelecting6: false,
      option: "",
      zonas: [],
      panel: [1],
      tipo: "",
      opcionesTipo: [
        {
          name: "Sector 1 - S1",
          value: "S-1",
        },
        {
          name: "Sector 2 - S2",
          value: "S-2",
        },
        {
          name: "Sector 3 - S3",
          value: "S-3",
        },
        {
          name: "Sector 4 - S4",
          value: "S-4",
        },
        {
          name: "Sector 5 - S5",
          value: "S-5",
        },
        {
          name: "Sector 6 - S6",
          value: "S-6",
        },
        {
          name: "Sector 7 - S7",
          value: "S-7",
        },
        {
          name: "Sector 8 - S8",
          value: "S-8",
        },
      ],
      oficina: "",
      edificios: [],
      edificio: "",
      capacitacion: "",
      cuarentena: "",
      comite: "",
      selected: [],
      dialog: false,
      dialog2: false,
      mailDialog: false,
      deleteSelected: false,
      search: "",
      height: 400,
      fixed: true,
      editedIndex: -1,
      editedItem: {
        nombre: "",
        puesto: "",
        unidad: "",
        planta: "",
        grado: "",
      },
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
      defaultItem: {
        nombre: "",
        puesto: "",
        unidad: "",
        planta: "",
        grado: "",
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Funcionario"
        : "Editar Funcionario";
    },
  },

  watch: {
    desde() {
      //console.log("en el desde");
      this.desdeFormatted = this.formatDate(this.desde);
    },
  },

  methods: {
    actualizaPcr(item) {
      const object = {
        id: item.id,
        date: this.desdeFormatted,
      };
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL + "/usuario/actualizarPcrManual",
          object,
          this.auth
        )
        .then((response) => {
          alert("Fecha actualizada");
          this.sendData();
          this.dialog4 = false;
        })
        .catch((error) => {
          alert(error);
        });
    },
    sendData() {
      this.$emit("send-data", "OK");
    },

    sinInfo(item) {
      if (item.correoLdap == null) {
        return "Usuario no ha ingresado";
      } else {
        return item.correoLdap;
      }
    },

    cambiaPcr(item) {
      if (item.updatedDatePcr == null) {
        return "No se ha tomado muestra";
      } else if (item.pcr == true) {
        return item.updatedDatePcr;
      } else " ";
    },
    forceFileDownload(response, nameFile) {
      //console.log("Aqui va el header");
      //console.log(response.headers);
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nameFile); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    onButtonClick(option) {
      if (option == 1) {
        this.option = 1;
        this.isSelecting = true;
      } else if (option == 2) {
        this.option = 2;
        this.isSelecting2 = true;
      } else if (option == 4) {
        this.option = 4;
        this.isSelecting4 = true;
      } else if (option == 5) {
        this.option = 5;
        this.isSelecting5 = true;
      } else if (option == 6) {
        this.option = 6;
        this.isSelecting6 = true;
      } else {
        this.option = 3;
        this.isSelecting3 = true;
      }
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
          this.isSelecting2 = false;
          this.isSelecting3 = false;
          this.isSelecting4 = false;
          this.isSelecting5 = false;
          this.isSelecting6 = false;
        },
        { once: true }
      );
      if (option == 1) {
        this.$refs.uploader.click();
      } else if (option == 2) {
        this.$refs.uploader2.click();
      } else if (option == 4) {
        this.$refs.uploader4.click();
      } else if (option == 5) {
        this.$refs.uploader5.click();
      } else if (option == 6) {
        this.$refs.uploader6.click();
      } else {
        this.$refs.uploader3.click();
      }
    },
    submitFile(e) {
      let formData = new FormData();
      var fileToUpload = e.target.files[0];
      //console.log(this.$refs["myFiles"].files);
      //console.log(this.filess);
      var fileName = e.target.files[0].name;
      formData.append("file", fileToUpload);
      var endpoint = "";
      var name = "";
      var fecha = new Date();
      if (this.option == 1) {
        //TODO: VERIFICAR INDICE HOJA
        endpoint =
          "/usuario/activarNuevoComiteZonasExcel?columnas=19&indiceHoja=3";
        name =
          "Usuarios no cambiados - Comite - " +
          this.formatDate(fecha.toISOString().substr(0, 10)) +
          ".xlsx";
      } else if (this.option == 2) {
        //TODO: VERIFICAR INDICE HOJA
        endpoint =
          "/usuario/crearDesdeExcelSinValidarRut?columnas=8&indiceHoja=0";
        name =
          "Usuarios no creados - Personal sin validar rut - " +
          this.formatDate(fecha.toISOString().substr(0, 10)) +
          ".xlsx";
      } else if (this.option == 4) {
        //TODO: VERIFICAR INDICE HOJA
        endpoint = "/usuario/crearDesdeExcel?columnas=8&indiceHoja=0";
        name =
          "Usuarios no creados - Personal - " +
          this.formatDate(fecha.toISOString().substr(0, 10)) +
          ".xlsx";
      } else if (this.option == 5) {
        //TODO: VERIFICAR INDICE HOJA
        endpoint = "/usuario/actualizarPcr?columnas=2&indiceHoja=0";
        name =
          "Usuarios no creados - PCR - " +
          this.formatDate(fecha.toISOString().substr(0, 10)) +
          ".xlsx";
      } else if (this.option == 6) {
        //TODO: VERIFICAR INDICE HOJA
        endpoint = "/usuario/desactivarMasivo?columnas=2&indiceHoja=0";
        name =
          "Usuarios no cambiados - Desactivacion - " +
          this.formatDate(fecha.toISOString().substr(0, 10)) +
          ".xlsx";
      }
      /* else {
        endpoint =
          "/usuario/activarDesdeExcel?columnas=1&indiceHoja=0&tipoSolicitud=" +
          this.option;
        name =
          "Usuarios no cambiados - Capacitacion - " +
          this.formatDate(fecha.toISOString().substr(0, 10)) +
          ".xlsx";
      }*/
      this.axios
        .post(process.env.VUE_APP_BASE_URL + endpoint, formData, {
          responseType: "arraybuffer",
          headers: { Authorization: "" + this.$session.get("jwt") },
        })
        .then((response) => {
          alert("Archivo: " + fileName + " fue cargado exitosamente.");
          if (this.option == 1) {
            this.isSelecting = false;
          } else if (this.option == 2) {
            this.isSelecting2 = false;
          } else if (this.option == 4) {
            this.isSelecting4 = false;
          } else if (this.option == 5) {
            this.isSelecting5 = false;
          } else if (this.option == 6) {
            this.isSelecting6 = false;
          } else {
            this.isSelecting3 = false;
          }

          this.forceFileDownload(response, name);
          this.sendData();
        })
        .catch((error) => {
          alert(error.response);
          if (this.option == 1) {
            this.isSelecting = false;
          } else if (this.option == 2) {
            this.isSelecting2 = false;
          } else if (this.option == 4) {
            this.isSelecting4 = false;
          } else if (this.option == 5) {
            this.isSelecting5 = false;
          } else if (this.option == 6) {
            this.isSelecting6 = false;
          } else {
            this.isSelecting3 = false;
          }
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    buscaEdificios() {
      //console.log(this.tipo);
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/zona/getAllByCodigo?codigo=" +
            this.tipo.value,
          this.auth
        )
        .then((response) => {
          this.edificios = response.data;
          this.edificio = this.edificios[0];
        })
        .catch((error) => {
          alert(error);
        });
    },
    getZonas(id) {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL + "/usuario/getNombreZonas?id=" + id,
          this.auth
        )
        .then((response) => {
          this.zonas = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },

    deleteUsuario(id) {
      this.axios
        .delete(
          process.env.VUE_APP_BASE_URL + "/usuario/eliminarUsuario?id=" + id,
          this.auth
        )
        .then((response) => {
          alert(response.data);
          this.dialog3 = false;
          this.sendData();
        })
        .catch((error) => {
          alert(error);
        });
    },

    deleteZona(id) {
      this.axios
        .delete(
          process.env.VUE_APP_BASE_URL + "/usuarioZona/delete?id=" + id,
          this.auth
        )
        .then((response) => {
          alert(response.data);
          this.getZonas(this.editedItem.id);
        })
        .catch((error) => {
          alert(error);
        });
    },

    saveZonas(id) {
      const objeto = {
        zonaId: this.edificio.id,
        oficina: this.oficina,
      };
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL + "/usuario/saveZonas?id=" + id,
          objeto,
          this.auth
        )
        .then((response) => {
          alert("Acción realizada correctamente");
          (this.tipo = ""), (this.edificios = []);
          this.edificio = "";
          this.oficina = "";
          this.getZonas(id);
        })
        .catch((error) => {
          alert(error);
        });
    },

    showDetailItem(item) {
      //TODO: Cambiar busqueda por algo unico
      this.editedItem = item;
      this.getZonas(item.id);
      this.dialog = true;
    },

    showDetailItemGeneral(item, index) {
      //TODO: Cambiar busqueda por algo unico
      this.editedItem = item;
      this["dialog" + index] = true;
    },

    updateUsuario(id, index) {
      var objeto = {
        bool: "",
        tiposolicitud: "",
      };
      if (index == 1) {
        objeto = {
          bool: this.editedItem.habilitadoComite,
          tiposolicitud: 1,
        };
      }
/*
      if (index == 3) {
        objeto = {
          bool: this.editedItem.habilitadoCapacitacion,
          tiposolicitud: 3,
        };
      }*/

      if (index == 2) {
        objeto = {
          bool: this.editedItem.cuarentena,
          tiposolicitud: 2,
        };
      }

      this.axios
        .post(
          process.env.VUE_APP_BASE_URL +
            "/usuario/updateParametrosUsuario?id=" +
            id,
          objeto,
          this.auth
        )
        .then((response) => {
          alert("Actualización realizada correctamente");
          this.sendData();
        })
        .catch((error) => {
          alert(error);
          this.sendData();
        });
    },

    downloadExcel() {
      //console.log(this.data);
      this.waitDownload = true;
      this.axios
        .get(process.env.VUE_APP_BASE_URL + "/usuario/exportarDB", {
          responseType: "arraybuffer",
          headers: { Authorization: "" + this.$session.get("jwt") },
        })
        .then((response) => {
          var fecha = new Date();
          this.forceFileDownload(
            response,
            "Consolidado Usuarios " +
              this.formatDate(fecha.toISOString().substr(0, 10)) +
              ".xlsx"
          );
          this.waitDownload = false;
        })
        .catch((error) => {
          alert(error.response);
          this.waitDownload = false;
        });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
  },
};
</script>