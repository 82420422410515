<template>
  <v-card>
    <v-card-title>
      <span class="headline">Pase de movilidad</span>
    </v-card-title>
    <v-card-text>
      Para realizar reservas en el sistema, es requisito esencial tener el esquema de vacunación completo.
      Por esta razón debes subir el documento PDF correspondiente a tu pase de movilidad, el cual obtienes desde la página
      <a
      href="https://mevacuno.gob.cl/"
      class="text-decoration-none"
      target="_blank"
    >https://mevacuno.gob.cl/</a>.
    </v-card-text>
    <v-card-text>* Sólo se admite la carga de archivos en formato PDF</v-card-text>
    <v-card-menu>
      <v-form id="formUpload" v-model="valid" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" sm="6" md="6">
              <v-file-input
                id="pdfentryfile"
                placeholder="Cargar archivo"
                prepend-icon="mdi-file-document"
                label="Cargar archivo"
                v-model="qrImage"
                @change="onImageUpload()"
                accept=".pdf"
              ></v-file-input>
              <!-- <input id="pdfentryfile" type="file" @change="checkQR" accept="application/pdf"> -->
          </v-col>
        </v-row>
      </v-form>
    </v-card-menu>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :loading="loading" color="success" :disabled="!qrButton" outlined @click="uploadQr()"><v-icon left>mdi-cloud-upload</v-icon>Subir archivo</v-btn>
      <v-btn :loading="loading" color="error" outlined @click="salir()"><v-icon left>mdi-logout</v-icon>Salir</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { PDF_QR_JS }  from 'pdf-qr';

import { PDF_JS } from 'pdf-qr';
PDF_JS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDF_JS.version}/pdf.worker.min.js`;

import alertModal from '../../utils/alertModal';

export default {
  props: {
    changeStep: Function,
    alumno: Object,
    getValidaciones: Function,
    salir: Function,
    auth: Object,
  },
  data() {

    return {
      valid: false,
      lazy: false,
      qrImage: null,
      qrButton: false,
      formData: null,
      loading: false,
      qrUrl: '',
    };
  },

  computed: {},

  created() {

  },
  watch: {
    qrImage() {
      if (!this.qrImage) {
        this.qrButton = false;
      }
      else {
        var input_file = document.getElementById('pdfentryfile');
        var configs = {
          "scale": {
            "once": true,
            "value": 1,
            "start": 0.2,
            "step": 0.2,
            "stop": 2
          },
          "resultOpts": {
            "singleCodeInPage": true,
            "multiCodesInPage": false,
            "maxCodesInPage": 1
          },
          "improve": true,
          "jsQR": {}
        };
        const self = this;
        var callback =  function(result) {
          if(result.success){
            if (result.codes.length > 0) {
              self.qrButton = true;
              self.qrUrl = result.codes[0];
            }
            else {
              const alertData = {
                title: `El archivo subido no contiene un codigo QR`,
                text: 'Por favor revise el pdf subido',
                showCancelButton: false
              }
              alertModal(alertData);
              self.qrButton = false;
            }
          }
          else {
            const alertData = {
              title: `El archivo subido no contiene un codigo QR`,
              text: 'Por favor revise el pdf subido',
              showCancelButton: false
            }
            alertModal(alertData);
            self.qrButton = false;
          }
        }
        var pageNr = 1;
        PDF_QR_JS.decodeSinglePage(input_file, pageNr, configs, callback);
      }
    }
  },
  methods: {

    onImageUpload() {
      let file = this.qrImage;
      this.formData = new FormData();
      this.formData.append("file", file);
    },
    uploadQr(){
      this.loading = true;
      const data = {
        url: this.qrUrl
      }
      this.axios
          .post(
            process.env.VUE_APP_BASE_URL +
              "/pase-movilidad",
              data,
            this.auth
          )
          .then(response => {
            // this.getValidaciones();
            this.changeStep();
            this.loading = false;
            // alert("Archivo subido exitosamente");
            // if(response.data === true){
            // }
            // else {
            //   alert("QR no es válido. Favor verifica el archivo.");
            // }
          })
          .catch(error => {
            const alertData = {
              title: `Hubo un error al ingresar el pase de movilidad`,
              text: 'Por favor revise el archivo subido y vuelva a intentarlo',
              showCancelButton: false,
              icon: 'error',
            }
            alertModal(alertData);
            this.loading = false;
          });
    },
  }
};
</script>
