<template>
  <v-dialog
    v-model="historyDialog"
    persistent
    width="900"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title
        class="text-h5"
      >
        Historial de la reserva
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "ReserveHistory",
  props: {
    historyDialog: Boolean,
    openHistoryDialog: Function,
    currentItem: Object,
  },
  data() {
    return {

    };
  },
  computed: {

  },
  watch: {
    historyDialog() {
      if (this.historyDialog) {
        this.getItemHistory();
      }
    }
  },
  methods: {
    async getItemHistory() {

    },
    closeDialog() {
      this.openHistoryDialog(false);
    }
  },
};
</script>

<style scoped>

</style>
