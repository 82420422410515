<template>
  <v-container>
    <h1>Permiso de Estudiante</h1>
    <StudentPermission/>
  </v-container>
</template>

<script>
import StudentPermission from '../components/StudentPermission/StudentPermission';
export default {
  name: "StudentPermissionView",
  components: {
    StudentPermission
  },
  data(){
    return {

    }
  }

}
</script>

<style scoped>

</style>
