<template>
  <v-container class="fill-height" fluid>
    <v-app-bar fixed color="#071D49" class="nav mx-btn">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        color="white"
        class="md-hidden"
      ></v-app-bar-nav-icon>
      <v-navigation-drawer v-model="drawer" temporary app absolute>
        <v-list flat>
          <v-list-item-group v-model="group" color="deep-orange">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title"
                  >Hola Usuario</v-list-item-title
                >
                <v-list-item-subtitle>perfil</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <img
        class="img-fix"
        alt="Logo universidad de Santiago de Chile"
        :src="img1"
        width="100"
        height="50"
        style="margin-right: 20px"
      />

      <v-toolbar-title class="text-white"></v-toolbar-title>

      <img
        class="img-fix"
        alt="Logo Campus Seguro"
        :src="img2"
        height="50"
        style="margin-right: 20px"
      />

      <v-spacer></v-spacer>

      <div class="text-center">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="indigo" dark v-bind="attrs" v-on="on">Contacto</v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <img width="100" :src="logo_usach2" alt="John" />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>Prorrectoría</v-list-item-title>
                  <v-list-item-subtitle
                    >Contacto de Plataforma</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-icon right>mdi-email</v-icon>
                </v-list-item-action>
                <v-list-item-title>covid19@usach.cl</v-list-item-title>
              </v-list-item>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text @click="menu = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
      <div class="text-center">
        <v-btn color="white" :to="{ name: 'login' }">Salvoconducto</v-btn>
      </div>
    </v-app-bar>
    <img :src="img" class="parallax" />
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="5">
        <v-card class="elevation-12">
          <v-toolbar color="#071D49" dark flat>
            <v-toolbar-title>Acceso a Certificado Funcionario</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form id="formVrae" v-model="valid" :lazy-validation="lazy">
              <v-text-field
                dense
                outlined
                label="Nombre de usuario (correo Usach sin extensión)"
                name="login"
                prepend-icon="mdi-account"
                type="text"
                v-model="user"
                :rules="requiredRules"
              ></v-text-field>
              <v-select
                return-object
                prepend-icon="mdi-mail"
                outlined
                dense
                :items="opcionesDominio"
                persistent-hint
                label="Dominio de correo"
                v-model="dominio"
                :rules="requiredRules"
              ></v-select>

              <v-text-field
                outlined
                dense
                id="password"
                label="Contraseña de correo"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                v-model="pass"
                :rules="requiredRules"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              :disabled="!valid"
              :loading="loading"
              @click="login()"
              >Ingresar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import img from "../../assets/USACH_2.png";
import img1 from "../../assets/LOGO USACH.png";
import img2 from "../../assets/CampusSeguro Icon-2.png";
import logo_usach2 from "../../assets/logo_usach2.jpg";

import jwtDecode from "jwt-decode";

export default {
  data() {
    return {
      valid: false,
      requiredRules: [(v) => !!v || "Este campo es requerido"],
      opcionesDominio: ["@segic.usach.cl", "@usach.cl"],
      logo_usach2: logo_usach2,
      dominio: "",
      menu: false,
      loading: false,
      img1: img1,
      img2: img2,
      img: img,
      tipo: "",
      user: "",
      pass: "",
    };
  },

  methods: {
    login() {
      var sha1 = require("sha1");
      //console.log(this.mail)
      this.loading = true;
      const user = {
        usuario: this.user,
        dominio: this.dominio,
        tipo: 3,
        password: sha1(this.pass),
      };
      //console.log(user);
      //console.log(user);

      this.axios
        .post(process.env.VUE_APP_BASE_URL + "/login", user)
        .then((response) => {
          this.$session.start();
          this.$session.set("jwt", "Bearer " + response.data.token);
          this.$session.set("jwtStd", "Bearer " + response.data.token_std);
          this.$router.push({ name: "certificado" });
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          alert(error.response.data);
        });
    },
  },
};
</script>
<style scoped>
.text-center {
  margin-left: 3px !important;
}
.parallax {
  position: fixed;
  z-index: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  filter: blur(0.3rem);
}
</style>
