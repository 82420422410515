var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","v-height":_vm.height},on:{"click:outside":_vm.closeDialog,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeDialog.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Disponibilidad de Reserva para el día hábil siguiente ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Seleccionar fecha de ","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDates},on:{"input":function($event){_vm.menu = false},"update:picker-date":function($event){return _vm.pickerUpdate($event)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1)],1),_c('v-card-text',[_vm._v(" Seleccione por Sector o Edificio ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.zonas,"label":"Seleccione sector a buscar","name":"zonaSelected","return-object":"","item-text":"descripcion","dense":"","outlined":""},model:{value:(_vm.zonaSelected),callback:function ($$v) {_vm.zonaSelected=$$v},expression:"zonaSelected"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.edificios,"label":"Seleccione edificio a buscar","item-text":"nombre","item-value":"id","return-object":"","dense":"","outlined":""},model:{value:(_vm.edificioSelected),callback:function ($$v) {_vm.edificioSelected=$$v},expression:"edificioSelected"}})],1)],1)],1),_c('v-card-menu',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.itemsFiltered,"items-per-page":-1,"search":_vm.search},scopedSlots:_vm._u([{key:"item.acceso",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-map-marker ")])]}}],null,true),model:{value:(_vm.locationLabel[index]),callback:function ($$v) {_vm.$set(_vm.locationLabel, index, $$v)},expression:"locationLabel[index]"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_vm._v(" "+_vm._s(item.acceso)+" ")])],1)],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":function($event){return _vm.handleReservarSala(item)}}},[_vm._v(" Reservar ")])]}}])}),_c('div',{staticClass:"text--secondary mr-5"},[_c('card',[_c('v-card-text',[_vm._v(" Tipos de sala: "),_c('ul',[_c('li',{attrs:{"type":"disc"}},[_vm._v("Silencio: En estas salas debes permanecer en silencio.")]),_c('li',{attrs:{"type":"disc"}},[_vm._v("Conexión: En estas salas puedes conversar con las demás personas presentes.")])]),_vm._v(" Horario de uso de las salas: De lunes a viernes de 7:30 a 19:30 hrs. ")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","outlined":""},on:{"click":_vm.closeDialog}},[_vm._v("Cerrar")]),_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }