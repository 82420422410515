import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../components/Login/Login.vue';
import LoginReservaView from '../views/LoginReservaView';

import Login2 from '../components/layout component/Login2.vue'

import Layout from '../components/layout component/Layout.vue'
import Processes from '../views/Processes.vue'
import ListaPersonalExtra from '../views/ListaPersonalExtra.vue'
import ListaSalvo from '../views/ListaDeSalvo.vue'
import ListaIngreso from '../views/ListaIngreso.vue'
import Formulario from '../components/Formulario.vue'
import SalvoEx from '../components/FormularioEx.vue'
import ReserveView from '../views/ReserveView.vue'
import RegistroAlumno from '../components/RegistroAlumno/RegistroAlumno.vue'
import Certificado from '../components/Certificado.vue'

import NotificarSintomasView from '../views/NotificarSintomasView';

import StudentPermissionView from '../views/StudentPermissionView';

import LogViewerView from '../views/LogViewerView'
// import ReserveListAdminView from '../views/ReserveListAdminView';

// import AlumnosQRView from '../views/AlumnosQRView';

import AlumnosAdminView from '../views/AlumnosAdminView';

import MenuJF from '../components/MenuJF'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  ,
  {
    path: '/login',
    name: 'login',
    component: Login,
  },

  {
    path: '/reserva/login',
    name: 'loginReserva',
    component: LoginReservaView,
  },

  {
    path: '/loginCert',
    name: 'loginCert',
    component: Login2,
  },

  {
    path: '/layout',
    name: 'layout',
    component: Layout,
    redirect: '/lista-usuarios',
    children: [
      {
        path: '/lista-usuarios',
        name: 'Processes',
        component: Processes,
         beforeEnter: (to, from, next) => {
          if (router.app.$session.getAll().typeUser == 'Admin') next()
          else next({ name: 'formulario' })
        }
      },


      {
        path: '/lista-ingreso',
        name: 'Ingreso',
        component: ListaIngreso,
        beforeEnter: (to, from, next) => {
          if (router.app.$session.getAll().typeUser == 'Admin') next()
          else next({ name: 'formulario' })
        }
      },

      {
        path: '/lista-personal-extra',
        name: 'PersonalExtra',
        component: ListaPersonalExtra,
        beforeEnter: (to, from, next) => {
          if (router.app.$session.getAll().typeUser == 'Admin') next()
          else next({ name: 'formulario' })
        }
      },

      {
        path: '/lista-salvoconducto',
        name: 'SalvoList',
        component: ListaSalvo,
         beforeEnter: (to, from, next) => {
          if (router.app.$session.getAll().typeUser == 'Admin') next()
          else next({ name: 'formulario' })
        }
      },

      {
        path: '/lista-salvoconducto-activos',
        name: 'ActiveSalvoList',
        component: ListaSalvo,
         beforeEnter: (to, from, next) => {
          if (router.app.$session.getAll().typeUser == 'Admin') next()
          else next({ name: 'formulario' })
        }
      },

      {
        path: '/lista-ingreso-historico',
        name: 'Historico',
        component: ListaIngreso,
        beforeEnter: (to, from, next) => {
          if (router.app.$session.getAll().typeUser == 'Admin') next()
          else next({ name: 'formulario' })
        }
      },
      {
        path: '/formulario',
        name: 'formulario',
        component: Formulario,
      },
      {
        path: '/notificacionSintomas',
        name: 'notificacionSintomas',
        component: NotificarSintomasView,
        beforeEnter: (to, from, next) => {
          if (router.app.$session.getAll().typeUser == 'Alumno-Autorizado') next()
          else next({ name: 'regAlumno' })
        }
      },
      {
        path: '/reserva',
        name: 'reserva',
        component: ReserveView,
        beforeEnter: (to, from, next) => {
          if (router.app.$session.getAll().typeUser == 'Alumno-Autorizado') next()
          else next({ name: 'regAlumno' })
        }
      },
      // {
      //   path: '/reserva-admin',
      //   name: 'reservaAdmin',
      //   component: ReserveListAdminView,
      // },
      {
        path: '/registro/alumno',
        name: 'regAlumno',
        component: RegistroAlumno,
        beforeEnter: (to, from, next) => {
          if (router.app.$session.getAll().typeUser == 'Alumno-NoAutorizado') next()
          else next({ name: 'studentPermission' })
        }
      },
      {
        path: '/permiso',
        name: 'studentPermission',
        component: StudentPermissionView,
        beforeEnter: (to, from, next) => {
          if (router.app.$session.getAll().typeUser == 'Alumno-Autorizado') next()
          else next({ name: 'regAlumno' })
        }
      },
      {
        path: '/formularioEx',
        name: 'formularioEx',
        component: SalvoEx,
      },
      {
        path: '/certificado',
        name: 'certificado',
        component: Certificado,
      },
      // {
      //   path: '/alumnos-qr',
      //   name: 'alumnosQRView',
      //   component: AlumnosQRView,
      // },
      {
        path: '/alumnos',
        name: 'alumnos',
        component: AlumnosAdminView,
      },
      {
        path: '/menu',
        name: 'menu',
        component: MenuJF,
         beforeEnter: (to, from, next) => {
          if (router.app.$session.getAll().typeUser == 'Admin') next()
          else next({ name: 'formulario' })
        }
      },
      {
        path: '/log',
        name: 'log',
        component: LogViewerView,
         beforeEnter: (to, from, next) => {
          if (router.app.$session.getAll().typeUser == 'Admin') next()
          else next({ name: 'formulario' })
        }
      },
    ]
  }


]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !router.app.$session.exists()) {
    if (to.name === 'loginReserva') {
      next();
    }
    else {
      next({ name: 'login' })
    }
  }
  // else if (to.name !== 'loginReserva' && !router.app.$session.exists()) {
  //   next({ name: 'loginReserva' })
  // }
  else next()
})



export default router
