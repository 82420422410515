<template>
  <v-dialog
    v-model="dialogMap"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card class="dialogMapa">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="openUsachMapDialog(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Mapa de Sectores Campus</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-list three-line subheader class="imageContainer">
        <v-img
          :src="img"
          aspect-ratio="1.7"
          contain
          class="parallax"
        ></v-img>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import img from "../../assets/campus.png";

import {changeFont, } from '../../utils/resizeFontFunctions';

export default {
  name: "UsachMapDialog",
  props: {
    dialogMap: Boolean,
    openUsachMapDialog: Function,
    firstMountMap: Boolean,
    changeFirstMountMap: Function,
  },
  data() {
    return {
      img: img
    };
  },
  computed: {

  },
  watch: {
    dialogMap() {
      // console.log(this.dialogMap);
    }
  },
  updated() {
    if (this.firstMountMap) {
      const resize = localStorage.getItem('resize') || 0;
      changeFont(document.getElementsByTagName("body")[0], resize);
      this.changeFirstMountMap();
    }
  },
  methods: {
    closeDialog() {
      this.openUsachMapDialog(false);
    }
  },
};
</script>

<style scoped>

.dialogMapa {
  overflow: auto;
}

.parallax {
  z-index: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
}

.botton-final {
  margin-bottom: 50px;
}

.imageContainer {
  /* position: fixed; */

}

@media only screen and (max-width: 900px) {

  .parallax {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .imageContainer {
    margin-top: 26%;
  }
}

@media only screen and (min-width: 900px) {
  .hidden1 {
    display: none !important;
  }
}
@media only screen and (max-width: 899px) {
  .hidden2 {
    display: none !important;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}
</style>
