<template>
  <v-card
  >
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          Datos del estudiante
        </v-list-item-title>
        <!-- <v-list-item-subtitle>Mon, 12:30 PM, Mostly sunny</v-list-item-subtitle> -->
      </v-list-item-content>
    </v-list-item>

    <!-- <v-card-text>
      <v-row align="center">
        <v-col
          class="text-h2"
          cols="6"
        >
          23&deg;C
        </v-col>
        <v-col cols="6">
          <v-img
            src="https://cdn.vuetifyjs.com/images/cards/sun.png"
            alt="Sunny image"
            width="92"
          ></v-img>
        </v-col>
      </v-row>
    </v-card-text> -->

    <v-divider

    ></v-divider>
    <v-row>
      <v-col lg="5">


        <v-row>
          <v-col xl="12" lg="12" md="12" sm="12" class="columnsList">
            <v-list-item>
              <v-col cols="4" class="columnsList">
                <v-list-item-icon>
                  Nombre
                </v-list-item-icon>
              </v-col>
              <v-col cols="8" class="columnsList">
                <v-list-item-subtitle>{{alumno.name}}</v-list-item-subtitle>
              </v-col>
            </v-list-item>
          </v-col>

          <v-col xl="12" lg="12" md="12" sm="12" class="columnsList">
            <v-list-item>
              <v-col cols="4" class="columnsList">
                <v-list-item-icon>
                  Rut
                </v-list-item-icon>
              </v-col>
              <v-col cols="8" class="columnsList">
                <v-list-item-subtitle>{{alumno.rut}}</v-list-item-subtitle>
              </v-col>
            </v-list-item>

          </v-col>

          <v-col xl="12" lg="12" md="12" sm="12" class="columnsList">
            <v-list-item>
              <v-col cols="4" class="columnsList">
                <v-list-item-icon>
                  Correo
                </v-list-item-icon>
              </v-col>
              <v-col cols="8" class="columnsList">
                <v-list-item-subtitle>{{alumno.email}}</v-list-item-subtitle>
              </v-col>
            </v-list-item>

          </v-col>

          <v-col xl="12" lg="12" md="12" sm="12" class="columnsList">
            <v-list-item>
              <v-col cols="4" class="columnsList">
                <v-list-item-icon>
                  Telefono
                </v-list-item-icon>
              </v-col>
              <v-col cols="8" class="columnsList">
                <v-list-item-subtitle>{{alumno.phone}}</v-list-item-subtitle>
              </v-col>
            </v-list-item>

          </v-col>

          <v-col xl="12" lg="12" md="12" sm="12" class="columnsList">
            <v-list-item>
              <v-col cols="4" class="columnsList">
                <v-list-item-icon>
                  Direccion
                </v-list-item-icon>
              </v-col>
              <v-col cols="8" class="columnsList">
                <v-list-item-subtitle>{{alumno.address}}</v-list-item-subtitle>
              </v-col>
            </v-list-item>

          </v-col>



          <v-col xl="12" lg="12" md="12" sm="12" class="columnsList">
            <v-list-item>
              <v-col cols="4" class="columnsList">
                <v-list-item-icon>
                  Carrera
                </v-list-item-icon>
              </v-col>
              <v-col cols="8" class="columnsList">
                <v-list-item-subtitle>{{alumno.career}}</v-list-item-subtitle>
              </v-col>
            </v-list-item>

          </v-col>
        </v-row>
            <!-- <v-data-table
              dense
              :headers="headers"
              :items="desserts"
              item-key="name"
              class="elevation-1"
            ></v-data-table> -->

      </v-col>
      <v-divider
        vertical
      ></v-divider>
      <v-col lg="7" class="columnsList">
        <v-list class="transparent">
          <v-list-item two-line>
            <v-list-item-subtitle>Asignaturas cursadas durante el semestre actual</v-list-item-subtitle>
          </v-list-item>
          <div class="column_wrapper">
            <div class="text-center">
              <v-progress-circular
                  v-if="loading"
                  :size="100"
                  indeterminate
                  color="primary"
                  class="mt-5 ml-5"
              ></v-progress-circular>
              </div>
            <v-list-item
              v-for="asignatura in asignaturas"
              :key="asignatura.asignatura"
              class="listAsignatura"
            >
              <v-col
                class="itemAsignatura"
              >
                <v-list-item
                  class="listAsignatura"
                >
                  {{ `${asignatura.codigo} - ${asignatura.asignatura}` }}
                </v-list-item>
              </v-col>

              <!-- <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon> -->

              <!-- <v-list-item-subtitle class="text-right">
                {{ asignatura.temp }}
              </v-list-item-subtitle> -->
            </v-list-item>
          </div>
        </v-list>
      </v-col>
    </v-row>




<!--
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-cloud-download</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>48%</v-list-item-subtitle>
    </v-list-item>

    <v-slider
      v-model="time"
      :max="6"
      :tick-labels="labels"
      class="mx-4"
      ticks
    ></v-slider> -->



  </v-card>
</template>

<script>
export default {
  name: "StudentDetails",
  components: {

  },
  props: {
    alumno: Object,
    asignaturas: Array,
    loading: Boolean
  },
  data() {
    return {

    };
  },
  created() {

  },
  computed: {

  },
  watch: {
    asignaturas() {

    }
  },
  updated() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped>
.columnsList {
  padding-top: 0px;
  padding-bottom: 0px;
}

.column_wrapper {
  column-count: 2;
}

.studentInfo {
  max-width: 95px;
}

@media only screen and (max-width: 600px) {
  .column_wrapper {
    column-count: 1;
  }
}

.listAsignatura {
  padding: 0px;
}

.itemAsignatura {
  width: 100%;
}

</style>
