<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="logs"
      :search="search"
    ></v-data-table>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  props: {

  },
  data() {
    return {
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      },
      search: '',
      headers: [
        {
          text: 'Correo',
          align: 'start',
          filterable: false,
          value: 'email',
        },
        { text: 'Rut', value: 'rut' },
        { text: 'Mensaje', value: 'message' },
        { text: 'Dia', value: 'date' },
        { text: 'Hora', value: 'hour' },
      ],
      logs: []
    };
  },

  computed: {

  },

  created() {
    this.getLogs();
  },

  methods: {
    async getLogs() {
      try {
        console.log(this.auth);
        const {data} = await this.axios.get(process.env.VUE_APP_BASE_URL + "/logger", this.auth);

        const addHourAndDate = data.map(log => {
          const logDate = moment(log.createdAt);
          let date = `${logDate.date()}/${logDate.month()+1}/${logDate.year()}`;
          let time = logDate.hour() + ':' + logDate.minutes() + ':' + logDate.seconds();
          time = time + ((logDate.hour()) >= 12 ? ' PM' : ' AM');
          return {
            ...log,
            date: date,
            hour: time
          }
        });
        this.logs = addHourAndDate;
      } catch (e) {

      }
    }
  }
};
</script>
