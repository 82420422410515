<template>
  <v-container class="margin">
    <div class="text-center">
      <v-dialog v-model="dialog" hide-overlay persistent width="450">
        <v-card color="#071d49" dark>
          <v-card-text>
            {{ msg }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              small
              outlined
              v-if="buttonRetry"
              color="white"
              @click="cerrarDialog()"
              light
              >Cancelar</v-btn
            >
            <v-btn
              small
              outlined
              v-if="buttonRetry"
              color="warning"
              @click="retryCertificado()"
              light
              >Firmar nuevamente</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <h1>Obtención de Certificado para vacunación</h1>

    <v-form id="formVrae" v-model="valid" :lazy-validation="lazy">
      <p>
        A continuación, se expone sus información con el fin de que esta sea
        validada
      </p>

      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            readonly
            persistent-hint
            label="Nombres"
            outlined
            dense
            v-model="usuario.nombres"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
            readonly
            persistent-hint
            label="Apellido Paterno"
            outlined
            dense
            v-model="usuario.apellidoP"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
            readonly
            persistent-hint
            label="Apellido Materno"
            outlined
            dense
            v-model="usuario.apellidoM"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            readonly
            persistent-hint
            label="Rut"
            outlined
            dense
            v-model="usuario.rut"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            readonly
            persistent-hint
            label="Correo Institucional"
            outlined
            dense
            v-model="usuario.correoLdap"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            readonly
            persistent-hint
            label="Planta de funcionario"
            outlined
            dense
            v-model="usuario.planta"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            readonly
            persistent-hint
            label="Unidad encargada"
            outlined
            dense
            v-model="usuario.centroCosto"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-row justify="end">
            <v-btn
              class="botton-final"
              :loading="loading"
              :disabled="!valid"
              color="warning"
              @click="getCertificado()"
              light
              >Generar Certificado</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import img from "../assets/campus.png";
import jwtDecode from "jwt-decode";

export default {
  data() {
    return {
      msg: "",
      pdf: "",
      dialog: false,
      buttonRetry: false,
      bodyStd: {},
      idFuncionario: "",
      usuario: "",
      lazy: false,
      valid: false,
      loading: false,
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },

      auth2: {
        headers: {
          Authorization: "" + this.$session.get("jwtStd"),
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      },
      dialog: false,
    };
  },

  created() {
    var Decode = jwtDecode(this.$session.get("jwt"));
    this.idFuncionario = Decode.identificador;
    this.axios
      .get(
        process.env.VUE_APP_BASE_URL +
          "/funcionario/getFuncionario?id=" +
          this.idFuncionario,
        this.auth
      )
      .then((response) => {
        this.usuario = response.data;
      })
      .catch((error) => {
        alert(error);
      });
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    downloadFile(pdf) {
      var fecha = new Date();
      var a = document.createElement("a"); //Create <a>
      a.href = "data:application/pdf;base64," + pdf; //Image Base64 Goes here
      a.download =
        "certificado_" +
        this.usuario.rut +
        "_" +
        this.formatDate(fecha.toISOString().substr(0, 10)) +
        ".pdf"; //File name Here
      a.click(); //Downloaded file
      this.dialog = false;
    },
    cerrarDialog() {
      this.buttonRetry = false;
      this.dialog = false;
      this.bodyStd = {};
    },

    retryCertificado() {
      this.buttonRetry = false;
      this.msg = "Reintentando...";
      this.firmarCertificado();
    },

    firmarCertificado() {
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL3 + "/simple/signature/pdf",
          this.bodyStd,
          this.auth2
        )
        .then((response) => {
          this.msg = "Descargando archivo";
          this.downloadFile(response.data);
        })
        .catch((error) => {
          this.msg =
            "Ha ocurrido un error, porfavor reintente firmar el documento";
          this.buttonRetry = true;
          alert(error);
        });
    },

    getCertificado() {
      this.dialog = true;
      this.msg = "Obteniendo certificado";
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/funcionario/obtenerCertificado?rut=" +
            this.usuario.rut,
          this.auth
        )
        .then((response) => {
          this.pdf = response.data;
          this.msg = "Certificado obtenido, firmando certificado ...";
        })
        .then(() => {
          this.bodyStd = {
            type: 2,
            signatureId: "489",
            page: 1,
            XII: 314,
            XSD: 459,
            YII: 308,
            YSD: 236,
            pdf: this.pdf,
            footerText: false
          };
          this.firmarCertificado();
        })
        .catch((error) => {
          this.dialog = false;
          alert(error);
        });
    },
  },
};
</script>

<style scoped>
.margin {
  margin-top: 5% !important;
}
.parallax {
  z-index: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.botton-final {
  margin-bottom: 50px;
}

@media only screen and (min-width: 900px) {
  .hidden1 {
    display: none !important;
  }
}
@media only screen and (max-width: 899px) {
  .hidden2 {
    display: none !important;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}
</style>
