<template>
  <v-dialog
    v-model="tycDialog"
    persistent
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Términos y condiciones</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <TerminosYCondicionesText />
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import TerminosYCondicionesText from '../RegistroAlumno/TerminosYCondiciones/TerminosYCondicionesText';

import {changeFont, } from '../../utils/resizeFontFunctions';

export default {
  components: {
    TerminosYCondicionesText
  },
  props: {
    tycDialog: Boolean,
    openTycDialog: Function,
    firstMountTyc: Boolean,
    changeFirstMountTyc: Function,
  },
  data() {
    return {

    }
  },

  computed: {
  },

  created() {

  },
  watch: {

  },
  updated() {
    if (this.firstMountTyc) {
      const resize = localStorage.getItem('resize') || 0;
      changeFont(document.getElementsByTagName("body")[0], resize);
      this.changeFirstMountTyc();
    }
  },
  methods: {
    closeDialog() {
      this.openTycDialog(false);
    },
  }
};
</script>
