<template>
  <v-dialog
    v-model="qrDialog"
    persistent
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
    width="400px"
  >
    <v-card class="cardQr">
      <v-card-title
        class="text-h5"
      >
        Código QR
      </v-card-title>
      <v-container>
        <!-- <qr-code
          :text="activeItem.url || ''"
          class="imageQR"
          error-level="H"
          >
        </qr-code> -->
        <qr-canvas :options="options"></qr-canvas>
        <p class="text--secondary">
          URL: {{activeItem.url}}
        </p>
      </v-container>
      <v-col
        cols="12"
        class="py-2"
      >
        <v-btn
          tile
          color="success"
          class="button"
          @click="handleAcceptQR()"
        >
          Aceptar QR
        </v-btn>
        <v-btn
          tile
          color="error"
          class="button"
          @click="handleRejectQR()"
        >
          Rechazar QR
        </v-btn>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import alertModal from '../../../utils/alertModal';

import Swal from 'sweetalert2';

const { QRCanvas } = require('qrcanvas-vue');

export default {
  name: "QRDialog",
  props: {
    qrDialog: Boolean,
    openQRDialog: Function,
    activeItem: Object,
    getAlumnosQR: Function,
  },
  components: {
    QrCanvas: QRCanvas,
  },
  data() {
    return {
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      },
    };
  },
  computed: {
    options() {
      return {
        cellSize: 8,
        data: this.activeItem.url,
      };
    },
  },
  watch: {
    qrDialog() {
      // console.log(this.dialogMap);
    }
  },
  methods: {
    async acceptQR(id) {
      try {
        const {data} = await this.axios.put(`${process.env.VUE_APP_BASE_URL}/pase-movilidad`, {id: id}, this.auth);
        // this.alumno = data;
        this.openQRDialog(false);
        this.getAlumnosQR();
      } catch (e) {
        console.log(e);
      }
    },
    handleAcceptQR() {
      const aceptar = () => this.acceptQR(this.activeItem.id);
      const alertData = {
        title: `¿Esta seguro/a de querer aceptar el pase de movilidad de ${this.activeItem.name}?`,
        text: 'El alumno podrá reservar salas',
        acceptFunction: aceptar,
        showCancelButton: true
      }
      alertModal(alertData);
    },
    async rejectQR(id, reason) {
      try {
        const headers = {
          ...this.auth,
          data: {
            id: id,
            reason: reason
          }
        }
        const itemData = {
          id: id
        };
        const {data} = await this.axios.delete(`${process.env.VUE_APP_BASE_URL}/pase-movilidad`, headers);
        // // this.alumno = data;
        // // console.log(data);
        this.openQRDialog(false);
        this.getAlumnosQR();
      } catch (e) {
        console.log(e);
      }
    },
    async handleRejectQR() {
      let itemCopia = {...this.activeItem};
      const rechazar = () => this.rejectQR(this.activeItem.id);
      this.openQRDialog(false);
      let inputValue = '';
      const { value } = await Swal.fire({
        title: `¿Esta seguro/a de querer rechazar el pase de movilidad de ${this.activeItem.name}?`,
        text: 'El alumno no podrá reservar salas',
        input: 'textarea',
        inputLabel: 'Razón para rechazar',
        inputValue: inputValue,
        showCancelButton: true,
        inputAttributes: {
          maxlength: 500,
          autocapitalize: 'off',
          autocorrect: 'off'
        },
        inputValidator: (value) => {
          if (!value) {
            return 'Debe ingresar una razón de rechazo'
          }
          else {
            if (value.length < 10) {
              return 'La razón debe poseer al menos 10 caracteres'
            }
            else if (value.length > 500) {
              return 'La razón debe poseer máximo 500 caracteres'
            }
          }
        }
      })
      if (value) {
        this.rejectQR(itemCopia.id, value);
      }
      else {
        this.openQRDialog(true, itemCopia);
      }
      // const alertData = {
      //   title: `¿Esta seguro/a de querer rechazar el pase de movilidad de ${this.activeItem.name}?`,
      //   text: 'El alumno no podrá reservar salas',
      //   input: 'text',
      //   acceptFunction: rechazar,
      //   showCancelButton: true
      // }
      // alertModal(alertData);
    },
    closeDialog() {
      this.openQRDialog(false);
    },
  },
};
</script>

<style scoped>

.cardQr {
  text-align: center;
}

.button {
  margin-right: 10px;
}

.imageQR {
  max-width: 100%;
  max-height: 100%;
}

</style>
