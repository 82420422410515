<template>
  <div>
    <v-row>
      <v-col cols="6">
        <h1>Generar Salvoconducto Semanal</h1>
      </v-col>
    </v-row>
    <v-spacer />
    <TableFuncionarios
      :cargando = "cargando"
      @send-data = "receiveData"
      :headers = "headers"
      :nameTitle="'Listado de personal extra'"
      :items = "items"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import TableFuncionarios from "@/components/TablePersonalExtra.vue";
export default {
  name: "Funcionarios",
  components: {
    TableFuncionarios
  },
  data() {
    return {
      cargando: true,
      loading: "cargando",
      itemType: "Proceso",
      headers: [
        { text: "Nombre", value: "nombres" },
        { text: "Paterno", value: "apellidoP" },
        { text: "Materno", value: "apellidoM" },
        { text: "Rut", value: "rut" },
        { text: "Correo contacto", value: "correo" },
        { text: "Estamento", value: "estamento" },
        { text: "Activo", value: "salvoActivo" },
        { text: "Acciones", value: "acciones", sortable: false }
      ],
      items: [],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      }
    };
  },

  created() {
    this.getUsers()
  },
  methods: {
    receiveData(event) {
      //console.log(event);
      this.getUsers()
    },
    getUsers(){
      this.axios
        .get(process.env.VUE_APP_BASE_URL + "/personalExtra/getUsuarios", this.auth)
        .then(response => {
          this.items = response.data;
          this.cargando = false;
        })
        .catch(error => {
          alert(error);
          this.cargando = false;
        });
    }
  }
};
</script>

<style>
.c-orange {
  color: rgb(255, 153, 0);
}
</style>