<template>
  <v-container>
    <h1>Formulario de ingreso excepcional a Universidad</h1>

    <v-dialog v-model="dialog3" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Declaración personal</v-card-title
        >

        <v-card-text class="text-justify"
          >Al realizar esta solicitud de salvoconducto excepcional, declaro que
          no he presentado síntomas de COVID-19 y que tampoco me he relacionado
          con personas que hayan sido confirmadas con casos positivos de
          COVID-19 durante los últimos 14 días.</v-card-text
        >

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="red" text @click="cerrarDialog3()">Cerrar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            text
            :disabled="
              !(bolmaximo && bolactualEx && bolactualRegular && bolpendientes)
            "
            @click="enviarInquietud()"
            >Aceptar y Enviar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Mapa de Zonas Campus</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-list three-line subheader>
          <img :src="img" class="hidden1 parallax" />
          <img :src="img" class="hidden2 parallax" />
        </v-list>
      </v-card>
    </v-dialog>

    <v-form id="formVrae" v-model="valid" :lazy-validation="lazy">
      <p>Los campos marcados con asterisco (*) son de carácter obligatorio</p>

      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            readonly
            persistent-hint
            label="Nombres*"
            outlined
            dense
            v-model="usuario.nombres"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
            readonly
            persistent-hint
            label="Apellido Paterno*"
            outlined
            dense
            v-model="usuario.apellidoP"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-text-field
            readonly
            persistent-hint
            label="Apellido Materno*"
            outlined
            dense
            v-model="usuario.apellidoM"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            readonly
            persistent-hint
            label="Rut*"
            outlined
            dense
            v-model="usuario.rut"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            readonly
            persistent-hint
            label="Correo Institucional*"
            outlined
            dense
            v-model="usuario.correoLdap"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="12" md="6">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="desde"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                dense
                :rules="requiredRules"
                v-model="desdeFormatted"
                label="Fecha de ingreso*"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="desde"
              :allowed-dates="allowedDates"
              :max="max"
              :min="date"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false"
                >Cancelar</v-btn
              >
              <v-btn text color="primary" @click="$refs.dialog.save(desde)"
                >Seleccionar</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="6" sm="12" md="6">
          <v-select
            return-object
            outlined
            dense
            :items="horarios"
            label="Ingrese horario*"
            :rules="requiredRules"
            v-model="horario"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="12">
          <p>Elije un sector para su ingreso:</p>
          <v-row>
            <v-col cols="10">
              <v-select
                return-object
                :rules="requiredRules"
                outlined
                dense
                item-text="name"
                :items="opcionesTipo"
                label="Sectores del Campus*"
                v-model="sector"
                @change="buscaEdificios"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="2" md="2">
              <v-btn color="primary" dark v-bind="attrs" @click="dialog = true"
                >Ver Sectores</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                return-object
                outlined
                dense
                item-text="edificio"
                :items="edificios"
                label="Edificios por Sector*"
                v-model="edificio"
                :rules="requiredRules"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :rules="requiredRules"
                outlined
                dense
                v-model="oficina"
                label="Oficina o departamento*"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            hint="Motivo por el cúal necesita ingresar a la universidad"
            persistent-hint
            outlined
            counter
            no-resize
            :rules="requiredRules"
            label="Motivo de ingreso*"
            v-model="motivo"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row justify="end">
            <v-btn
              class="botton-final"
              :loading="loading"
              :disabled="!valid"
              color="warning"
              @click="openDialog3()"
              light
              >Enviar</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import img from "../assets/campus.png";

import moment from 'moment';

export default {
  data() {
    return {
      maximo: 0,
      actualEx: 0,
      actualRegular: 0,
      pendientes: 0,
      bolmaximo: false,
      bolactualEx: false,
      bolactualRegular: false,
      bolpendientes: false,
      dialog3: false,
      countSalvo: "",
      countSalvoActivo: "",
      horarios: [
        "8:00 hrs a 11:00 hrs",
        "9:00 hrs a 12:00 hrs",
        "10:00 hrs a 13:00 hrs",
        "11:00 hrs a 14:00 hrs",
        "12:00 hrs a 15:00 hrs",
        "13:00 hrs a 16:00 hrs",
        "14:00 hrs a 17:00 hrs",
        "15:00 hrs a 18:00 hrs",
      ],
      horario: "",
      sector: "",
      modal: false,
      desde: "",
      desdeFormatted: "",

      headers: [
        { text: "Sector", value: "sector" },
        { text: "Edificio", value: "edificio" },
        { text: "Oficina", value: "oficina" },
      ],
      usuario: "",
      attrs: "",
      opcionesTipo: [
        {
          name: "Sector 1 - S1",
          nameValores: "Sector 1",
          value: "S-1",
        },
        {
          name: "Sector 2 - S2",
          nameValores: "Sector 2",
          value: "S-2",
        },
        {
          name: "Sector 3 - S3",
          nameValores: "Sector 3",
          value: "S-3",
        },
        {
          name: "Sector 4 - S4",
          nameValores: "Sector 4",
          value: "S-4",
        },
        {
          name: "Sector 5 - S5",
          nameValores: "Sector 5",
          value: "S-5",
        },
        {
          name: "Sector 6 - S6",
          nameValores: "Sector 6",
          value: "S-6",
        },
        {
          name: "Sector 7 - S7",
          nameValores: "Sector 7",
          value: "S-7",
        },
        {
          name: "Sector 8 - S8",
          nameValores: "Sector 8",
          value: "S-8",
        },
      ],
      oficina: "",
      edificios: [],
      edificio: "",
      zona: "",
      motivo: "",
      img: img,
      inquietudes: [
        "Problema de conectividad - Computador",
        "Problema de conectividad - Internet",
        "Problemas Económicos",
      ],
      panel: false,
      requiredRules: [(v) => !!v || "Este campo es requerido"],
      files: [],
      alumno: {
        carrera: {},
      },
      inquietud: "",
      detalle: "",
      deshabilitado: true,
      carrera: {},
      lazy: false,
      valid: false,
      loading: false,
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
      dialog: false,
    };
  },

  created() {
    this.axios
      .get(
        process.env.VUE_APP_BASE_URL +
          "/usuario/getUsuario?id=" +
          this.$session.get("id"),
        this.auth
      )
      .then((response) => {
        this.usuario = response.data;
        //if (!this.usuario.habilitadoCapacitacion || !this.usuario.cuarentena) {
          if ( !this.usuario.cuarentena) {
          alert(
            "Usted no está habilitado para solicitar un salvoconducto de tipo excepcional. El estado de sus permisos es: \n\n" +
              /*" - Habilitado por capacitaciones: " +
              this.habilitacion(this.usuario.habilitadoCapacitacion) +
              "\n*/
               "- Cuarentena preventiva: " +
              this.habilitacion(!this.usuario.cuarentena) +
              "\n" +
              "\n A continuación, su sesión finalizará ya que no cumple con los requisitos mínimos para entrar al Campus."
          );
          this.$session.destroy();
          this.$router.push("/");
        }
        this.validarSalvosSemanales();
        this.validarUnSalvoActivo();
      })
      .catch((error) => {
        alert(error);
      });
  },
  computed: {
    max: function () {
      var moment = require('moment')
      var now = moment().add(1, 'days').format('YYYY-MM-DD')
      return now
    },

    date: function () {
      var moment = require('moment')
      var now = moment().format('YYYY-MM-DD')
      return now
    },
  },
  watch: {
    desde() {
      //console.log("en el desde");
      this.desdeFormatted = this.formatDate(this.desde);
    },
  },

  methods: {
    allowedDates: (val) => ![5, 6].includes(new Date(val).getDay()),

    cerrarDialog3() {
      this.bolmaximo = false;
      this.bolactualEx = false;
      this.bolactualRegular = false;
      this.bolpendientes = false;
      this.dialog3 = false;
    },
    openDialog3() {
      this.getMaximo();
      this.getActualRegular();
      this.getActualExcepcional();
      this.getPendientes();
      this.dialog3 = true;
    },

    getMaximo() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/zona/obtenerAfueroMaximo?sector=" +
            this.sector.nameValores,
          this.auth
        )
        .then((response) => {
          this.maximo = response.data.cantidad;
          this.bolmaximo = true;
        })
        .catch((error) => {
          alert(error);
        });
    },

    getActualExcepcional() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/salvoconductoexcepcional/contarSalvosActivosPorDiaSector?sector=" +
            this.sector.nameValores +
            "&fecha=" +
            this.desdeFormatted,
          this.auth
        )
        .then((response) => {
          this.actualEx = response.data;
          this.bolactualEx = true;
        })
        .catch((error) => {
          alert(error);
        });
    },

    getPendientes() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/salvoconductoexcepcional/contarSalvosPendientesPorDiaSector?sector=" +
            this.sector.nameValores +
            "&fecha=" +
            this.desdeFormatted,
          this.auth
        )
        .then((response) => {
          this.pendientes = response.data;
          this.bolpendientes = true;
        })
        .catch((error) => {
          alert(error);
        });
    },

    getActualRegular() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/salvoconducto/contarSalvosPorSector?sector=" +
            this.sector.nameValores,
          this.auth
        )
        .then((response) => {
          this.actualRegular = response.data;
          this.bolactualRegular = true;
        })
        .catch((error) => {
          alert(error);
        });
    },

    validarSalvosSemanales() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/salvoconductoexcepcional/countSalvoconductosExcepcionales?usuarioId=" +
            this.usuario.id,
          this.auth
        )
        .then((response) => {
          this.countSalvo = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },
    validarUnSalvoActivo() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/salvoconductoexcepcional/countSalvoconductosExActivos?usuarioId=" +
            this.usuario.id,
          this.auth
        )
        .then((response) => {
          this.countSalvoActivo = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },
    buscaEdificios() {
      //console.log(this.tipo);
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL +
            "/zona/getAllByCodigo?codigo=" +
            this.sector.value,
          this.auth
        )
        .then((response) => {
          this.edificios = response.data;
          this.edificio = this.edificios[0];
        })
        .catch((error) => {
          alert(error);
        });
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    formatDateMinimo(date) {


      if (!date) return null;
      if (date.includes("/")) {
        const [day, month, year] = date.split("/");
        return `${year}-${month}-${day}`;
      } else {
        const [day, month, year] = date.split("-");
        return `${year}-${month}-${day}`;
      }
    },

    habilitacion(item) {
      if (item) {
        return "Sí";
      } else {
        return "No";
      }
    },

    enviarInquietud() {
      this.bolmaximo = false;
      this.bolactualEx = false;
      this.bolactualRegular = false;
      this.bolpendientes = false;

      if (this.countSalvoActivo > 0) {
        alert(
          "No puede solicitar un nuevo salvoconducto excepcional, ya que tiene uno activo o pendiente."
        );
        this.$session.destroy();
        this.$router.push("/");
      } else if (this.countSalvo >= 2) {
        alert(
          "No puede solicitar un nuevo salvoconducto excepcional, ha superado el número máximo permitido semanalmente."
        );
        this.$session.destroy();
        this.$router.push("/");
      } else if (
        this.actualEx + this.actualRegular + this.pendientes >=
        this.maximo
      ) {
        //Cortar
        alert(
          "Estimado(a),\n\nLamentamos informarle que para el día y sector por usted seleccionados no hay cupos disponibles para permisos excepcionales. Le invitamos cordialmente a reintentar en una fecha distinta."
        );
        this.cerrarDialog3();
      } else {
        this.loading = true;
        const salvoconducto = {
          motivo: this.motivo,
          zona: this.edificio.nombre,
          edificio: this.edificio.edificio,
          dependencia: this.oficina,
          fechaSolicitud: moment(this.desde)._d,
          bloqueHorario: this.horario,
        };

        this.axios
          .post(
            process.env.VUE_APP_BASE_URL +
              "/salvoconductoexcepcional",
            salvoconducto,
            this.auth
          )
          .then((response) => {
            alert("Su solicitud de ingreso ha sido recepcionada");
            this.$router.push("/");
            //abre el PDF en nueva ventana, ver como hacer esto bonito
          })
          .catch((error) => {
            console.log(error);
            alert(error);
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.parallax {
  z-index: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.botton-final {
  margin-bottom: 50px;
}

@media only screen and (min-width: 900px) {
  .hidden1 {
    display: none !important;
  }
}
@media only screen and (max-width: 899px) {
  .hidden2 {
    display: none !important;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}
</style>
