<template>
  <v-card>
    <v-card-title>
      Reservas de alumnos
      <v-divider
          class="mx-4"
          inset
          vertical
      ></v-divider>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="reservas"
        elevation="0"
        :search="search"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Buscar alumnos"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="actionsIcons">
            <v-icon
              center
              class="mr-2"
              @click="handleDeclaracionSalud(item)"
            >
              mdi-book-outline
            </v-icon>

            <v-icon
              center
              class="mr-2"
              @click="handleHistory(item)"
            >
              mdi-calendar
            </v-icon>

          </div>

        </template>

      </v-data-table>

    </v-card-text>

  </v-card>

</template>

<script>
import alertModal from '../../../utils/alertModal';

export default {
  name: "ReserveTable",
  props:{
    getAllReservas: Function,
    openHealthDialog: Function,
    openHistoryDialog: Function,
    reservas: Array,
  },
  data() {
    return {
      headers: [
        { text: "Alumno", align: "start", value: "name" },
        { text: "Rut", value: "rut" },
        {
          text: "Sector",
          value: "sector",
        },
        { text: "Edificio", value: "building" },
        { text: "Sala/Laboratorio", value: "room" },
        { text: "Tipo de sala", value: "roomType" },
        { text: "Fecha reserva", value: "reserveDate" },
        { text: "Tipo reserva", value: "reserveType" },
        { text: "Estado reserva", value: "reserveStatus" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      reserveLabel: [],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
      labels: {
        'pendient': 'El '
      },
      locationLabel: [],
      search: '',
    }
  },
  watch: {
    reservas() {
      this.reserveLabel = new Array(this.reservas.length).fill(false);
      this.locationLabel = new Array(this.reservas.length).fill(false);
    }
  },
  methods: {
    handleDeclaracionSalud(item) {
      this.openHealthDialog(true, item);
    },
    handleHistory(item) {
      this.openHistoryDialog(true, item);
    },
  }
}
</script>

<style scoped>
.actionsIcons {
  display: flex;
}

</style>
