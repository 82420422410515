<template>
  <v-container>
    <QRDialog
      :qrDialog="qrDialog"
      :openQRDialog="openQRDialog"
      :activeItem="activeItem"
      :getAlumnosQR="getAlumnosQR"
    />
    <v-card-title>
      Validar QRs de alumnos
      <v-divider
        class="mx-4"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="alumnos"
      elevation="0"
      :search="search"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Buscar alumnos"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.qr="{ item }">
        <v-btn tile
          color="primary"
          @click="openQRDialog(true, item)"
        >
          Ver QR
        </v-btn>
      </template>
      <!-- <template v-slot:item.actions="{ item }">
        <v-col
          cols="12"
          class="py-2"
        >
            <v-btn tile
              color="success"
              class="button"
              @click="handleAcceptQR(item)"
            >
              Aceptar QR
            </v-btn>
            <v-btn tile
              color="error"
              class="button"
              @click="handleAcceptQR(item)"
            >
              Rechazar QR
            </v-btn>
        </v-col>
      </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
import alertModal from '../../../utils/alertModal';

import QRDialog from './QRDialog';

export default {
  components: {
    QRDialog,
  },
  data(){
    return {
      alumnos: [],
      headers: [
        {
          text: "Alumno",
          align: "start",
          value: "name",
        },
        {
          text: "Rut",
          value: "rut",
        },
        {
          text: "Correo",
          value: "email",
        },
        { text: "Ver QR", value: "qr" },
      ],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") }
      },
      qrDialog: false,
      activeItem: {},
      search: '',
    }
  },

  created() {
    this.getAlumnosQR();
  },

  methods: {
    async getAlumnosQR() {
      try {
        const {data} = await this.axios.get(`${process.env.VUE_APP_BASE_URL}/pase-movilidad`, this.auth);
        // this.alumno = data;
        this.alumnos = data;

      } catch (e) {
        console.log(e);
      }
    },
    openQRDialog(state, item) {
      this.activeItem = item || {};
      this.qrDialog = state;
    }

  }
}

</script>

<style scoped>

.buttonsQr {
  display: flex;
}

.button {
  margin-right: 10px;
}

</style>
