<template>
  <v-card>
    <v-card-title>
      <span class="headline">Resumen</span>
    </v-card-title>
    <v-card-text>A continuación se presenta el resumen de los pasos que has pasado.</v-card-text>
    <!-- <v-card-text>En caso de estar todo en orden, aprieta el boton "Acceder a las reservas". Si hay algun inconveniente, pasa el cursor sobre el resultado para obtener mayor informacion.</v-card-text> -->
    <v-card-menu>
      <v-row>
        <v-col cols="12" sm="6" md="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Validación
                  </th>
                  <th class="text-left">
                    Resultado
                  </th>
                  <th class="text-left">
                    Información
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr

                >
                  <td>
                    Registro Alumno
                  </td>
                  <td>
                    <v-icon color="green darken-2" center >mdi-check-circle-outline</v-icon>
                  </td>
                  <td>
                    -
                  </td>
                </tr>
                <tr

                >
                  <td>
                    Acepta términos y condiciones
                  </td>
                  <td>
                    <v-icon color="green darken-2" center >mdi-check-circle-outline</v-icon>

                  </td>
                  <td>
                    -
                  </td>
                </tr>
                <tr

                >
                  <td>
                    Pase de movilidad
                  </td>
                  <td>
                    <v-icon color="green darken-2" center>mdi-check-circle-outline</v-icon>
                  </td>
                  <td>
                    -
                  </td>
                </tr>

                <tr

                >
                  <td>
                    Aprobación pase de movilidad
                  </td>
                  <td>
                    <v-icon v-if="userStep <= 4" color="red darken-2" center >mdi-close-circle-outline</v-icon>
                    <v-icon v-if="userStep > 4" color="green darken-2" center >mdi-check-circle-outline</v-icon>
                  </td>
                  <td>
                    <div
                      v-if="userStep <= 4"
                    >
                      Su pase de movilidad está pendiente de revisión por el personal de Campus Seguro USACH
                    </div>
                    <div
                      v-if="userStep > 4"
                    >
                      -
                    </div>
                  </td>
                </tr>

                <tr

                >
                  <td>
                    Capacitación Salvoconducto
                  </td>
                  <td>

                    <v-icon v-if="estadoCapatacitacion === 0" color="red darken-2" center >mdi-close-circle-outline</v-icon>
                    <v-icon v-if="estadoCapatacitacion === 1" color="green darken-2" center >mdi-check-circle-outline</v-icon>

                  </td>
                  <td>
                    <div v-if="estadoCapatacitacion === 0"
                    >
                      Le recordamos que para poder ingresar al Campus USACH usted debe tener finalizado el
                      Curso 1 - Capacitación Campus Seguro, el cual puede ser realizado en
                      <a
                        href="http://campusseguro.e-ciiet.cl/moodle/"
                        class="text-decoration-none"
                        target="_blank"
                      >
                        http://campusseguro.e-ciiet.cl/moodle/
                      </a>
                      con su correo institucional
                    </div>
                    <div
                        v-if="estadoCapatacitacion === 1"
                    >
                      -
                    </div>
                  </td>
                </tr>

              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-menu>
    <v-card-actions>
      <v-spacer></v-spacer>
      <!-- <v-btn :loading="loading" color="success" outlined @click="goReservas()"><v-icon left>mdi-calendar-multiselect</v-icon>Acceder a las reservas</v-btn> -->
      <v-btn :loading="loading" color="error" outlined @click="salir()"><v-icon left>mdi-logout</v-icon>Salir</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

export default {
  props: {
    validaciones: Array,
    estadoCapatacitacion: Number,
    changeStep: Function,
    salir: Function,
    userStep: Number,
    auth: Object,
  },
  data() {

    return {
      loading: false,
    };
  },

  computed: {},

  created() {

  },
  watch: {

  },
  methods: {
    goReservas(){
      this.$router.push({ name: "reserva" });
    },
    // getValidaciones(){
    //   this.axios
    //     .get(
    //       process.env.VUE_APP_BASE_URL + "/alumno/getValidaciones?id="+
    //       this.$session.get("id"),
    //       this.auth
    //     )
    //     .then(response => {
    //       this.validaciones = response.data;
    //     })
    //     .catch(error => {
    //       alert(error);
    //     });
    // },
  }
};
</script>
