<template>
  <v-container class="margin">
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          readonly
          persistent-hint
          label="Nombres"
          outlined
          dense
          v-model="usuario.nombres"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <v-text-field
          readonly
          persistent-hint
          label="Apellido Paterno"
          outlined
          dense
          v-model="usuario.apellidoP"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <v-text-field
          readonly
          persistent-hint
          label="Apellido Materno"
          outlined
          dense
          v-model="usuario.apellidoM"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          readonly
          persistent-hint
          label="Rut"
          outlined
          dense
          v-model="usuario.rut"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          readonly
          persistent-hint
          label="Correo Institucional"
          outlined
          dense
          v-model="usuario.correoLdap"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-textarea
        outlined
        name="input-7-4"
        label="Descripción de los síntomas"
        v-model="usuario.symptom"
        counter
        :rules="rules"
      >
      </v-textarea>
    </v-row>
    <v-row>
      <v-col class="text-left">
        <v-btn
            outlined
            depressed
            @click="$router.push('/reserva')"
            color="primary">Volver</v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
            outlined
            depressed
            @click="save"
            color="primary">Notificar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "NotificarSintomas",
  data() {
    return {
      rules: [v => v.length <= 999 || 'Max 999 characters'],
      usuario: {
        nombres: "",
        apellidoP: "",
        apellidoM: "",
        rut: "",
        correoLdap: "",
        symptom: '',
      },
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
    }
  },
  created() {
    this.getData();
  },
  watch: {

  },
  methods: {
    getData() {
      this.axios
        .get(
          `${process.env.VUE_APP_BASE_URL}/alumno/${this.$session.getAll().id}`,
          this.auth
        )
        .then(response => {
          this.usuario.nombres = response.data.name;
          this.usuario.apellidoP = response.data.firstSurname;
          this.usuario.apellidoM = response.data.secondSurname;
          this.usuario.rut = response.data.rut;
          this.usuario.correoLdap = response.data.email
        })
        .catch(error => {
          alert(error);
        });
    },
    save(){
      this.axios
          .post(
              `${process.env.VUE_APP_BASE_URL}/symptom`,
              this.usuario, this.auth
          )
          .then(response => {
              alert("Notificacion de Síntomas enviada con éxito");
          })
          .catch(error => {
            alert(error);
          });
    }
  }

}
</script>

<style scoped>

</style>
