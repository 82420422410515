<template>
  <div>
    <v-row>
      <v-col cols="6" sm="12" md="6">
        <h1>{{ titulo }}</h1>
      </v-col>
      <v-col v-if="oculto" cols="6" sm="12" md="6">
        <v-select
          v-model="totem"  
          :items="totems"        
          item-text="sector"        
          return-object
          outlined
          dense
          item-key="sector"
          label="Eliga un totem "
          @change="getUsers"
        ></v-select>
      </v-col>
      <v-col v-if="!oculto" cols="2" sm="2" md="2">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="desde"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="desdeFormatted"
              label="Desde"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              v-if="!oculto"
            ></v-text-field>
          </template>
          <v-date-picker v-model="desde" min="2016-06-15" :max="max" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">Cancelar</v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(desde)"
              >Seleccionar</v-btn
            >
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col v-if="!oculto" cols="2" sm="2" md="2">
        <v-dialog
          ref="dialog2"
          v-model="modal2"
          :return-value.sync="hasta"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="hastaFormatted"
              label="Hasta"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              v-if="!oculto"
            ></v-text-field>
          </template>
          <v-date-picker v-model="hasta" min="2016-06-15" :max="max" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal2 = false">Cancelar</v-btn>
            <v-btn text color="primary" @click="$refs.dialog2.save(hasta)"
              >Seleccionar</v-btn
            >
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col v-if="!oculto" cols="1" sm="1" md="1">
        <v-btn
          color="warning"
          class="mr-2 search"
          title="Buscar Info"
          @click="getUsersFiebreHistorico()"
          v-if="!oculto"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="!oculto" cols="1" sm="1" md="1">
        <v-btn
          color="warning"
          class="mr-2 search"
          title="Descargar Info"
          @click="downloadExcel()"
          v-if="!oculto"
          :disabled="!enableBtn"
          :loading="waitDownload"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-spacer />
    <TableFuncionarios
      @send-data="receiveData"
      @change-data="changeData"
      @change="changeDataHistory"
      :headers="headers"
      :nameTitle="nameTitle"
      :items="items"
      :oculto="oculto"
      :cambio="cambio"
      :cargando="cargando"
      :textoCargando="textoCargando"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import TableFuncionarios from "@/components/ListaSoap.vue";
export default {
  name: "Funcionarios",
  components: {
    TableFuncionarios,
  },
  data() {
    return {
      totems: [/*
        { name: "Acceso Peatonal - Puesto 7 Norte (Puesto 7) N°203", id: "869825040934747" },
        { name: "Acceso Vehicular - Puesto 7 Norte calle derecha (Peatonal) N°204", id: "869825040929804" },
        { name: "Acceso Vehicular - Puesto 7 Sur N°210", id: "869825040932121" },
        { name: "Centro de Salud Puesto 14 N°211", id: "869825040926388" },
        { name: "Facultad Tecnológica Puesto 20 N°206", id: "869825040921629" },
        { name: "Frontis Puesto 1 N°205", id: "869825040937328" },
        { name: "Frontis Puesto 2 N°209", id: "869825040934044" },
        { name: "Periodismo Puesto 19 N°207", id: "869825040932204" },
        { name: "Punto Umaña USACH Puesto 17 N°208", id: "869825040939084" },
        { name: "Totem Administración Campus (Puesto 10) N°201", id: "869825040939142" },
        { name: "Ruiz Tagle 140 (Puesto 22) N°202", id: "869825040930869" },
        { name: "Puesto 1 N°222", id: "869825040351744" },
        { name: "Puesto 2 N°213", id: "869825040357246" },
        { name: "Puesto 6 N°216", id: "869825040933020" },
        { name: "Puesto 7 218", id: "869825040346264" },
        { name: "Puesto 9 217", id: "869825040923161" },
        { name: "Puesto 18 219", id: "869825040941007" },
        { name: "Ruiz-Tagle N°221", id: "869825040932568" },
        { name: "Puesto 4 N°220", id: "869825040347346" },
        { name: "Puesto 5 N°215", id: "869825040933624" },*/
      ],
      totem: {},
      textoCargando: "",
      cargando: true,
      waitDownload: false,
      enableBtn: false,
      cambio: false,
      data: "",
      oculto: "",
      titulo: "",
      desde: "",
      hasta: "",
      desdeFormatted: "",
      hastaFormatted: "",
      modal: false,
      modal2: false,
      date: new Date().toISOString().substr(0, 10),
      loading: "cargando",
      itemType: "Proceso",
      nameTitle: "Ingresos del día",
      headers: [],
      headersConFiebre: [
        { text: "Rut", value: "Rut" },
        { text: "Fecha de Ingreso", value: "FechaMarca" },
        { text: "Temperatura", value: "Temperatura" },
        { text: "Notificado en sistema", value: "Cuarentena" },
      ],
      headersSinFiebre: [
        { text: "Rut", value: "Rut" },
        { text: "Fecha de Ingreso", value: "FechaMarca" },
        { text: "Temperatura", value: "Temperatura" },
      ],

      headersConFiebreHistorico: [
        { text: "Rut", value: "Rut" },
        { text: "Fecha de Ingreso", value: "FechaMarca" },
        { text: "Temperatura", value: "Temperatura" },
        { text: "Zona Marcaje", value: "Nombre Totem" },
        { text: "Notificado en sistema", value: "Cuarentena" },
      ],
      headersSinFiebreHistorico: [
        { text: "Rut", value: "Rut" },
        { text: "Fecha de Ingreso", value: "FechaMarca" },
        { text: "Temperatura", value: "Temperatura" },
        { text: "Zona Marcaje", value: "Nombre Totem" },
      ],
      items: [],
      auth: {
        headers: { Authorization: "" + this.$session.get("jwt") },
      },
    };
  },

  computed: {
    max: function () {
      var fecha = new Date();
      fecha.setDate(fecha.getDate() - 1);
      return fecha.toISOString().substr(0, 10);
    },
  },
  watch: {
    desde() {
      //console.log("en el desde");
      this.desdeFormatted = this.formatDate(this.desde);
    },
    hasta() {
      this.hastaFormatted = this.formatDate(this.hasta);
    },
  },
  created() {
    if (this.$route.fullPath == "/lista-ingreso") {
      this.textoCargando = "Cargando...";
      this.getUsers();
      this.getTotems();
      this.totem = this.totems[0];     
      this.titulo = "Ingreso de personal al Campus";
      this.oculto = true;
      this.nameTitle = "Ingresos del día";
      this.headers = this.headersSinFiebre;
    }
    if (this.$route.fullPath == "/lista-ingreso-historico") {
      this.textoCargando = "Busque entre fechas para obtener información";
      this.loading = "Busque entre fecha para obtener información";
      this.titulo = "Ingreso histórico de personal al Campus ";
      this.oculto = false;
      this.nameTitle = "Busque por fechas para obtener información";
      this.headers = this.headersSinFiebreHistorico;
    }
  },
  methods: {
    getTotems() {
      this.axios
        .get(process.env.VUE_APP_BASE_URL2 + "/totem/getAllTotem", this.auth)
        .then((response) => {
          this.totems = response.data;
        })
        .catch((error) => {
          this.setError('Hubo un error al obtener los Totems');
        });
    },
    forceFileDownload(response, nameFile) {
      //console.log("Aqui va el header");
      //console.log(response.headers);
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nameFile); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    downloadExcel() {
      //console.log(this.data);
      this.waitDownload = true;
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL + "/usuario/exportarInfoCruzada",
          this.data,
          {
            responseType: "arraybuffer",
            headers: { Authorization: "" + this.$session.get("jwt") },
          }
        )
        .then((response) => {
          var fecha = new Date();
          this.forceFileDownload(
            response,
            "Historico " +
              this.formatDate(fecha.toISOString().substr(0, 10)) +
              ".xlsx"
          );
          this.waitDownload = false;
        })
        .catch((error) => {
          alert(error.response);
          this.waitDownload = false;
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    receiveData(event) {
      //console.log(event);
      this.getUsers();
    },

    changeData(event) {
      //console.log(event);
      this.getUsersFiebre();
    },

    changeDataHistory(event) {
      //console.log(event);
      if (event == 1) {
        this.items = this.data.conFiebre;
        this.nameTitle = "Ingresos detectados con fiebre";
      }
      if (event == 2) {
        this.items = this.data.sinFiebre;
        this.nameTitle = "Usuarios detectados sin fiebre";
      }
    },
    getUsers() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL2 + "/totem/getData?SN=" + this.totem.id,
          this.auth
        )
        .then((response) => {
          this.items = response.data;
          this.nameTitle = "Ingresos del día";
          this.headers = this.headersSinFiebre;
          this.cambio = false;
          this.cargando = false;

          //console.log(this.items);
        })
        .catch((error) => {
          alert(error);
          this.cargando = false;
        });
    },

    getUsersFiebre() {
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL2 +
            "/historialTotem/filtroUsuarioDia?SN=" +
            this.totem.id,
          this.auth
        )
        .then((response) => {
          this.getUsersFiebreConNotificacion(response.data.conFiebre);
          this.nameTitle = "Ingresos detectados con fiebre hoy";
          this.headers = this.headersConFiebre;
          this.cambio = true;
        })
        .catch((error) => {
          alert(error);
        });
    },

    getUsersFiebreConNotificacion(list) {
      this.axios
        .post(
          process.env.VUE_APP_BASE_URL + "/usuario/getCuarentenaTotem",
          list,
          this.auth
        )
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          alert(error);
        });
    },

    getUsersFiebreHistorico() {
      this.axios
        .get(
          process.env.VUE_APP_BASE_URL2 +
            "/historialTotem/obtenerData?desde=" +
            this.desdeFormatted +
            "&hasta=" +
            this.hastaFormatted,
          this.auth
        )
        .then((response) => {
          this.data = response.data;
          this.items = response.data.conFiebre;
          this.nameTitle = "Ingresos detectados con fiebre";
          this.enableBtn = true;
          this.cargando = false;
          //console.log(this.items);
        })
        .catch((error) => {
          alert(error);
          this.cargando = false;
        });
    },
  },
};
</script>

<style>
.c-orange {
  color: rgb(255, 153, 0);
}
.search {
  margin-top: 5% !important;
}
</style>